import { combineReducers } from "redux";
import auth from "./auth.reducer";
import setting from "./setting.reducer";
import main from "./main.reducer";
import mainSub from "./mainn.reducer";

const rootReducer = combineReducers({
  auth,
  setting,
  main,
  mainSub,
});

export default rootReducer;
