import styled from 'styled-components';

export const Container = styled.div`
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: 60%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonLink = styled.a`
  margin: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 80%;
`;

export const Content = styled.p`
  text-align: center;
  margin-top: 10px;
`;
