import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import Actions from "../actions";
import { types } from "../actions/main.action";
import Api from "../../services/api.service";
import contants from "../../../store/contants";

function* getStories({ payload }: any) {
  yield put(Actions.main.setHomeStoriesError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setHomeStoriesLoader(true));
  } else {
    yield put(Actions.main.setHomeLoadMoreStories(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getStories, page);
    yield put(
      Actions.main.setHomeStoriesSuccess([...payload.stories, ...data.data])
    );
    yield put(Actions.main.setHomeStoriesLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHomeHasMoreStories(true));
    } else {
      yield put(Actions.main.setHomeHasMoreStories(false));
    }
  } catch (e) {
    yield put(Actions.main.setHomeStoriesError(true));
    yield put(
      Actions.main.setHomeMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setHomeStoriesLoader(false));
    yield put(Actions.main.setHomeStoriesLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setHomeLoadMoreStories(false));
  }
}

function* getFeaturedMentors({ payload }: any) {
  yield put(Actions.main.setHomeFeatureMentorsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setHomeFeatureMentorsLoader(true));
  } else {
    yield put(Actions.main.setHomeLoadMoreFeatureMentors(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getFeaturedMentors, page);
    yield put(
      Actions.main.setHomeFeatureMentorsSuccess([
        ...payload.featuredMentors,
        ...data.data,
      ])
    );
    yield put(Actions.main.setHomeFeatureMentorsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHomeHasMoreFeatureMentors(true));
    } else {
      yield put(Actions.main.setHomeHasMoreFeatureMentors(false));
    }
  } catch (e) {
    yield put(Actions.main.setHomeFeatureMentorsError(true));
    yield put(
      Actions.main.setHomeMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setHomeFeatureMentorsLoader(false));
    yield put(Actions.main.setHomeFeatureMentorsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setHomeLoadMoreFeatureMentors(false));
  }
}

function* getFriendRequests({ payload }: any) {
  yield put(Actions.main.setHomeFriendRequestsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setHomeFriendRequestsLoader(true));
  } else {
    yield put(Actions.main.setHomeLoadMoreFriendRequests(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getRequests, page);
    yield put(
      Actions.main.setHomeFriendRequestsSuccess([
        ...payload.friendRequests,
        ...data.data,
      ])
    );
    yield put(Actions.main.setHomeFriendRequestsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHomeHasMoreFriendRequests(true));
    } else {
      yield put(Actions.main.setHomeHasMoreFriendRequests(false));
    }
  } catch (e) {
    yield put(Actions.main.setHomeFriendRequestsError(true));
    yield put(
      Actions.main.setHomeMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setHomeFriendRequestsLoader(false));
    yield put(Actions.main.setHomeFriendRequestsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setHomeLoadMoreFriendRequests(false));
  }
}

function* getFriends({ payload }: any) {
  yield put(Actions.main.setFriendsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setFriendsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreFriends(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getFriends, page);
    yield put(
      Actions.main.setFriendsSuccess([...payload.friends, ...data.data])
    );
    yield put(Actions.main.setFriendsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreFriends(true));
    } else {
      yield put(Actions.main.setHasMoreFriends(false));
    }
  } catch (e) {
    yield put(Actions.main.setFriendsError(true));
    yield put(
      Actions.main.setFriendsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setFriendsLoader(false));
    yield put(Actions.main.setFriendsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreFriends(false));
  }
}

function* getHomeSuggestedFriends({ payload }: any) {
  yield put(Actions.main.setHomeSuggestedFriendsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setHomeSuggestedFriendsLoader(true));
  } else {
    yield put(Actions.main.setHomeLoadMoreSuggestedFriends(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getSuggestions, page);
    yield put(
      Actions.main.setHomeSuggestedFriendsSuccess([
        ...payload.suggestedFriends,
        ...data.data,
      ])
    );
    yield put(Actions.main.setHomeSuggestedFriendsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHomeHasMoreSuggestedFriends(true));
    } else {
      yield put(Actions.main.setHomeHasMoreSuggestedFriends(false));
    }
  } catch (e) {
    yield put(Actions.main.setHomeSuggestedFriendsError(true));
    yield put(
      Actions.main.setHomeMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setHomeSuggestedFriendsLoader(false));
    yield put(Actions.main.setHomeSuggestedFriendsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setHomeLoadMoreSuggestedFriends(false));
  }
}

function* getPublicJournal({ payload }: any) {
  yield put(Actions.main.setJournalPublicError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setJournalPublicLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreJournalPublic(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getUserStories, payload.userID, page, 1);
    yield put(
      Actions.main.setJournalPublicSuccess([...payload.stories, ...data.data])
    );
    yield put(Actions.main.setJournalPublicLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreJournalPublic(true));
    } else {
      yield put(Actions.main.setHasMoreJournalPublic(false));
    }
  } catch (e) {
    yield put(Actions.main.setJournalPublicError(true));
    yield put(
      Actions.main.setJournalPublicMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setJournalPublicLoader(false));
    yield put(Actions.main.setJournalPublicLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreJournalPublic(false));
  }
}

function* getPrivateJournal({ payload }: any) {
  yield put(Actions.main.setJournalPrivateError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setJournalPrivateLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreJournalPrivate(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getUserStories, payload.userID, page, 0);
    yield put(
      Actions.main.setJournalPrivateSuccess([...payload.stories, ...data.data])
    );
    yield put(Actions.main.setJournalPrivateLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreJournalPrivate(true));
    } else {
      yield put(Actions.main.setHasMoreJournalPrivate(false));
    }
  } catch (e) {
    yield put(Actions.main.setJournalPrivateError(true));
    yield put(
      Actions.main.setJournalPrivateMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setJournalPrivateLoader(false));
    yield put(Actions.main.setJournalPrivateLoaded(true));
  } else {
    yield put(Actions.main.setLoadMoreJournalPrivate(false));
  }
}

function* getAnnouncements({ payload }: any) {
  yield put(Actions.main.setAnnouncementsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setAnnouncementsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreAnnouncements(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getAnnouncements, page);
    yield put(
      Actions.main.setAnnouncementsSuccess([
        ...payload.announcements,
        ...data.data,
      ])
    );
    yield put(Actions.main.setAnnouncementsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreAnnouncements(true));
    } else {
      yield put(Actions.main.setHasMoreAnnouncements(false));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.main.setAnnouncementsError(true));
    yield put(
      Actions.main.setAnnouncementsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setAnnouncementsLoader(false));
    yield put(Actions.main.setAnnouncementsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreAnnouncements(false));
  }
}

function* getResourceImages({ payload }: any) {
  yield put(Actions.main.setResourceImagesError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setResourceImagesLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreResourceImages(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getResources, "image", page);
    yield put(
      Actions.main.setResourceImagesSuccess([...payload.images, ...data.data])
    );
    yield put(Actions.main.setResourceImagesLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreResourceImages(true));
    } else {
      yield put(Actions.main.setHasMoreResourceImages(false));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.main.setResourceImagesError(true));
    yield put(
      Actions.main.setResourceImagesMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setResourceImagesLoader(false));
    yield put(Actions.main.setResourceImagesLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreResourceImages(false));
  }
}

function* getResourceAudios({ payload }: any) {
  yield put(Actions.main.setResourceAudiosError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setResourceAudiosLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreResourceAudios(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getResources, "audio", page);
    yield put(
      Actions.main.setResourceAudiosSuccess([...payload.audios, ...data.data])
    );
    yield put(Actions.main.setResourceAudiosLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreResourceAudios(true));
    } else {
      yield put(Actions.main.setHasMoreResourceAudios(false));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.main.setResourceAudiosError(true));
    yield put(
      Actions.main.setResourceAudiosMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setResourceAudiosLoader(false));
    yield put(Actions.main.setResourceAudiosLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreResourceAudios(false));
  }
}

function* getResourceVideos({ payload }: any) {
  yield put(Actions.main.setResourceVideosError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setResourceVideosLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreResourceVideos(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getResources, "video", page);
    yield put(
      Actions.main.setResourceVideosSuccess([...payload.videos, ...data.data])
    );
    yield put(Actions.main.setResourceVideosLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreResourceVideos(true));
    } else {
      yield put(Actions.main.setHasMoreResourceVideos(false));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.main.setResourceVideosError(true));
    yield put(
      Actions.main.setResourceVideosMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setResourceVideosLoader(false));
    yield put(Actions.main.setResourceVideosLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreResourceVideos(false));
  }
}

function* getResourceDocuments({ payload }: any) {
  yield put(Actions.main.setResourceDocumentsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setResourceDocumentsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreResourceDocuments(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getResources, "docs", page);
    yield put(
      Actions.main.setResourceDocumentsSuccess([
        ...payload.documents,
        ...data.data,
      ])
    );
    yield put(Actions.main.setResourceDocumentsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreResourceDocuments(true));
    } else {
      yield put(Actions.main.setHasMoreResourceDocuments(false));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.main.setResourceDocumentsError(true));
    yield put(
      Actions.main.setResourceDocumentsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setResourceDocumentsLoader(false));
    yield put(Actions.main.setResourceDocumentsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreResourceDocuments(false));
  }
}

function* getResourceUrls({ payload }: any) {
  yield put(Actions.main.setResourceUrlsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setResourceUrlsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreResourceUrls(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getResources, "url", page);
    yield put(
      Actions.main.setResourceUrlsSuccess([...payload.urls, ...data.data])
    );
    yield put(Actions.main.setResourceUrlsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreResourceUrls(true));
    } else {
      yield put(Actions.main.setHasMoreResourceUrls(false));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.main.setResourceUrlsError(true));
    yield put(
      Actions.main.setResourceUrlsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setResourceUrlsLoader(false));
    yield put(Actions.main.setResourceUrlsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreResourceUrls(false));
  }
}

function* getMentors({ payload }: any) {
  yield put(Actions.main.setMentorsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setMentorsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreMentors(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getConnectedMentors, page);
    yield put(
      Actions.main.setMentorsSuccess([...payload.mentors, ...data.data])
    );
    yield put(Actions.main.setMentorsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreMentors(true));
    } else {
      yield put(Actions.main.setHasMoreMentors(false));
    }
  } catch (e) {
    yield put(Actions.main.setMentorsError(true));
    yield put(
      Actions.main.setMentorsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setMentorsLoader(false));
    yield put(Actions.main.setMentorsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreMentors(false));
  }
}

function* getProfile({ payload }: any) {
  yield put(Actions.main.setProfileError(false));
  yield put(Actions.main.setProfileLoader(true));
  yield put(Actions.main.setProfileMessage(""));
  // console.log(payload)
  try {
    const { data } = yield call(Api.getProfile, payload.id);
    yield put(Actions.main.setProfileSuccess({ ...data.data }));
    if (payload.id === payload.user.id) {
      yield put(Actions.setting.setUser({ ...payload.user, ...data.data }));
    }
    yield put(Actions.main.setProfileLoaded(true));
  } catch (e) {
    yield put(Actions.main.setProfileError(true));
    yield put(
      Actions.main.setProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.main.setProfileLoader(false));
}

function* getProfilePublicJournal({ payload }: any) {
  yield put(Actions.main.setProfileJournalPublicError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setProfileJournalPublicLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreProfileJournalPublic(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getUserStories, payload.userID, page, 1);
    yield put(
      Actions.main.setProfileJournalPublicSuccess([
        ...payload.stories,
        ...data.data,
      ])
    );
    yield put(Actions.main.setProfileJournalPublicLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreProfileJournalPublic(true));
    } else {
      yield put(Actions.main.setHasMoreProfileJournalPublic(false));
    }
  } catch (e) {
    yield put(Actions.main.setProfileJournalPublicError(true));
    yield put(
      Actions.main.setProfileJournalPublicMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setProfileJournalPublicLoader(false));
    yield put(Actions.main.setProfileJournalPublicLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreProfileJournalPublic(false));
  }
}

function* getProfilePrivateJournal({ payload }: any) {
  yield put(Actions.main.setProfileJournalPrivateError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setProfileJournalPrivateLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreProfileJournalPrivate(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getUserStories, payload.userID, page, 0);
    yield put(
      Actions.main.setProfileJournalPrivateSuccess([
        ...payload.stories,
        ...data.data,
      ])
    );
    yield put(Actions.main.setProfileJournalPrivateLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreProfileJournalPrivate(true));
    } else {
      yield put(Actions.main.setHasMoreProfileJournalPrivate(false));
    }
  } catch (e) {
    yield put(Actions.main.setProfileJournalPrivateError(true));
    yield put(
      Actions.main.setProfileJournalPrivateMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setProfileJournalPrivateLoader(false));
    yield put(Actions.main.setProfileJournalPrivateLoaded(true));
  } else {
    yield put(Actions.main.setLoadMoreProfileJournalPrivate(false));
  }
}

function* getMentorProfile({ payload }: any) {
  yield put(Actions.main.setMentorProfileError(false));
  yield put(Actions.main.setMentorProfileLoader(true));
  // console.log(payload)
  try {
    const { data } = yield call(Api.getProfile, payload.id);
    yield put(Actions.main.setMentorProfileSuccess({ ...data.data }));
    if (payload.id === payload.user.id) {
      yield put(Actions.setting.setUser({ ...payload.user, ...data.data }));
    }
    yield put(Actions.main.setMentorProfileLoaded(true));
  } catch (e) {
    yield put(Actions.main.setMentorProfileError(true));
    yield put(
      Actions.main.setMentorProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.main.setMentorProfileLoader(false));
}

function* getCategories({ payload }: any) {
  yield put(Actions.main.setCategoriesError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setCategoriesLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreCategories(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getCategories);
    yield put(
      Actions.main.setCategoriesSuccess([...payload.categories, ...data.data])
    );
    yield put(Actions.main.setCategoriesLoadedPage(page));
    /*if(data.links.next){
            yield put(Actions.main.setHasMoreCategories(true))
        }else {
            yield put(Actions.main.setHasMoreCategories(false))
        }*/
  } catch (e) {
    yield put(Actions.main.setCategoriesError(true));
    yield put(
      Actions.main.setCategoriesMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setCategoriesLoader(false));
    yield put(Actions.main.setCategoriesLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreCategories(false));
  }
}

function* postMakeRequest({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload));
  // console.log(payload)
  try {
    yield call(Api.makeFriendRequest, payload);
    yield put(Actions.main.setHomeSuggestedFriendsAddRequest(payload));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload));
}

function* postAcceptRequest({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload));
  // console.log(payload)
  try {
    const { data } = yield call(Api.acceptFriendRequest, payload);
    yield put(
      Actions.main.getHomeFriendRequestsAcceptSuccess({ ...data.data })
    );
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload));
}

function* postDeclineRequest({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload));
  // console.log(payload)
  try {
    const { data } = yield call(Api.declineFriendRequest, payload);
    yield put(
      Actions.main.getHomeFriendRequestsDeclineSuccess({ ...data.data })
    );
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload));
}

function* postMentorConnection({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload));
  // console.log(payload)
  try {
    const { data } = yield call(Api.connectMentor, payload);
    yield put(Actions.main.getMentorConnectionSuccess({ ...data.data }));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload));
}

function* postEditProfile({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload.id));
  // console.log(payload)
  try {
    const { data } = yield call(Api.editProfile, payload.data);
    yield put(Actions.main.setProfileSuccess({ ...data.data }));
    yield put(Actions.setting.setUser({ ...data.data }));
    // window.location.replace(`/profile/${payload.id}`);
    yield put(Actions.setting.addLoaderId("updateProfileSaved"));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload.id));
}

function* postVerifyProfile({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload.user.id));
  // console.log(payload)
  try {
    yield call(Api.requestVerification, payload.data);
    yield put(
      Actions.main.setProfileSuccess({ ...payload.user.id, status: "Pending" })
    );
    yield put(Actions.setting.setUser({ ...payload.user, status: "Pending" }));
    // window.location.replace(`/profile/${payload.user.id}`);
    yield put(Actions.setting.addLoaderId("verifyProfileSaved"));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload.user.id));
}

function* postAddStory({ payload }: any) {
  yield put(Actions.setting.addLoaderId("addStory"));
  // console.log(payload)
  try {
    if (payload.file) {
      const { data } = yield call(Api.uploadFile, {
        file: payload.file,
        for: "story",
        type: "image",
      });
      payload = {
        ...payload,
        data: {
          ...payload.data,
          resources: [{ type: "image", url: data.url }],
        },
      };
    }
    const { data } = yield call(Api.addStory, payload.data);
    yield put(Actions.setting.addLoaderId("storySaved"));
    yield put(Actions.main.addStorySuccess({ ...data.data }));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId("addStory"));
  yield put(Actions.setting.removeLoaderId("storySaved"));
}

function* getStory({ payload }: any) {
  yield put(Actions.main.setStoryError(false));
  yield put(Actions.main.setStoryLoader(true));
  // console.log(payload)
  try {
    const { data } = yield call(Api.markStoryAsViewed, payload);
    // const {data} = yield call(Api.getStory, payload)
    yield put(Actions.main.setStorySuccess({ ...data.data }));
    yield put(Actions.main.setStoryLoaded(true));
  } catch (e) {
    yield put(Actions.main.setStoryError(true));
    yield put(
      Actions.main.setStoryMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.main.setStoryLoader(false));
}

function* postStoryUpdate({ payload }: any) {
  yield put(Actions.setting.addLoaderId("editStory"));
  // console.log(payload)
  try {
    if (payload.file) {
      const { data } = yield call(Api.uploadFile, {
        file: payload.file,
        for: "story",
        type: "image",
      });
      payload = {
        ...payload,
        data: {
          ...payload.data,
          resources: [{ type: "image", url: data.url }],
        },
      };
    }
    const { data } = yield call(Api.updateStory, payload.id, payload.data);
    yield put(Actions.main.editStorySuccess({ ...data.data }));
  } catch (e) {
    /*yield put(Actions.main.setStoryError(true))
        yield put(Actions.main.setStoryMessage(e.response?.data?.message ?? 'Something happened'))*/
  }
  yield put(Actions.setting.removeLoaderId("editStory"));
}

function* postStoryDelete({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`deleteStory|${payload}`));
  // console.log(payload)
  try {
    yield call(Api.deleteStory, payload);
    yield put(Actions.main.deleteStorySuccess(payload));
  } catch (e) {
    /*yield put(Actions.main.setStoryError(true))
        yield put(Actions.main.setStoryMessage(e.response?.data?.message ?? 'Something happened'))*/
  }
  yield put(Actions.setting.removeLoaderId(`deleteStory|${payload}`));
}

function* postStoryReaction({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`reactStory|${payload.id}`));
  // console.log(payload)
  try {
    const { data } = yield call(Api.reactStory, payload.id, {
      reaction: payload.reaction,
    });
    yield put(Actions.setting.addLoaderId(`storyReactSaved|${payload.id}`));
    yield put(Actions.main.reactStorySuccess({ ...data.data }));
  } catch (e) {
    /*yield put(Actions.main.setStoryError(true))
        yield put(Actions.main.setStoryMessage(e.response?.data?.message ?? 'Something happened'))*/
  }
  yield put(Actions.setting.removeLoaderId(`reactStory|${payload.id}`));
  yield put(Actions.setting.removeLoaderId(`storyReactSaved|${payload.id}`));
}

function* getGroups({ payload }: any) {
  yield put(Actions.main.setGroupsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setGroupsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreGroups(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getGroups, page);
    yield put(Actions.main.setGroupsSuccess([...payload.groups, ...data.data]));
    yield put(Actions.main.setGroupsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreGroups(true));
    } else {
      yield put(Actions.main.setHasMoreGroups(false));
    }
  } catch (e) {
    yield put(Actions.main.setGroupsError(true));
    yield put(
      Actions.main.setGroupsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setGroupsLoader(false));
    yield put(Actions.main.setGroupsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreGroups(false));
  }
}

function* postReportUser({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload.user_id));
  yield put(Actions.main.setProfileMessage(""));
  // console.log(payload)
  try {
    yield call(Api.report, payload);
    window.location.replace(`/profile/${payload.user_id}`);
  } catch (e) {
    yield put(
      Actions.main.setProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
}

function* postBlockUser({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`blockUser|${payload}`));
  yield put(Actions.main.setProfileMessage(""));
  // console.log(payload)
  try {
    yield call(Api.block, { user_id: payload });
    window.location.replace(`/friends`);
  } catch (e) {
    yield put(
      Actions.main.setProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.setting.removeLoaderId(`blockUser|${payload}`));
}

function* postUnblockUser({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`unblockUser|${payload}`));
  yield put(Actions.main.setProfileMessage(""));
  // console.log(payload)
  try {
    yield call(Api.unblock, { user_id: payload });
    yield put(Actions.main.unBlockUserSuccess(payload));
  } catch (e) {
    yield put(
      Actions.main.setProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.setting.removeLoaderId(`unblockUser|${payload}`));
}

function* postDeleteUser({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`deleteUser|${payload.userEmail}`));
  yield put(Actions.main.setProfileMessage(""));
  // console.log(payload)
  try {
    if (payload.userEmail === payload.email) {
      yield call(Api.delete);
      localStorage.removeItem(contants.TOKEN);
      localStorage.removeItem(contants.USER);
      localStorage.removeItem(contants.CHAT);
      yield delay(1000);
      window.location.replace("/register");
    } else {
      yield put(Actions.main.setProfileMessage("Email do not match"));
    }
  } catch (e) {
    yield put(
      Actions.main.setProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.setting.removeLoaderId(`deleteUser|${payload.userEmail}`));
}

function* getInterests({ payload }: any) {
  yield put(Actions.main.setInterestsError(false));
  yield put(Actions.main.setInterestsLoader(true));
  // console.log(payload)
  try {
    const { data } = yield call(Api.getInterest);
    yield put(Actions.main.setInterestsSuccess([...data.data]));
    yield put(Actions.main.setInterestsLoaded(true));
  } catch (e) {
    yield put(Actions.main.setInterestsError(true));
    yield put(
      Actions.main.setInterestsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  yield put(Actions.main.setInterestsLoader(false));
}

function* getUserInterests({ payload }: any) {
  yield put(Actions.main.setProfileInterestError(false));
  yield put(Actions.main.setProfileInterestLoader(true));
  // console.log(payload)
  try {
    const { data } = yield call(Api.getUserInterest, payload);
    yield put(Actions.main.setProfileInterestSuccess([...data.data]));
    yield put(Actions.main.setProfileInterestLoaded(true));
  } catch (e) {
    yield put(Actions.main.setProfileInterestError(true));
    yield put(
      Actions.main.setProfileInterestMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  yield put(Actions.main.setProfileInterestLoader(false));
}

function* postUpdateInterests({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`updateInterests`));
  yield put(Actions.main.setProfileMessage(""));
  // console.log(payload)
  try {
    if (payload.remove.length > 0) {
      const { data } = yield call(Api.deleteInterest, {
        interests: payload.remove,
      });
      yield put(Actions.main.updateProfileInterestSuccess([...data.data]));
    }
    if (payload.add.length > 0) {
      const { data } = yield call(Api.addInterest, { interests: payload.add });
      yield put(Actions.main.updateProfileInterestSuccess([...data.data]));
    }
    yield put(Actions.setting.addLoaderId(`interestsSaved`));
  } catch (e) {
    yield put(
      Actions.main.setProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.setting.removeLoaderId(`updateInterests`));
}

function* getMentorInterests({ payload }: any) {
  yield put(Actions.main.setMentorProfileInterestError(false));
  yield put(Actions.main.setMentorProfileInterestLoader(true));
  // console.log(payload)
  try {
    const { data } = yield call(Api.getUserInterest, payload);
    yield put(Actions.main.setMentorProfileInterestSuccess([...data.data]));
    yield put(Actions.main.setMentorProfileInterestLoaded(true));
  } catch (e) {
    yield put(Actions.main.setMentorProfileInterestError(true));
    yield put(
      Actions.main.setMentorProfileInterestMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  yield put(Actions.main.setMentorProfileInterestLoader(false));
}

function* postUpdateMentorInterests({ payload }: any) {
  yield put(Actions.setting.addLoaderId(`updateInterests`));
  yield put(Actions.main.setMentorProfileMessage(""));
  // console.log(payload)
  try {
    if (payload.remove.length > 0) {
      const { data } = yield call(Api.deleteInterest, {
        interests: payload.remove,
      });
      yield put(
        Actions.main.updateMentorProfileInterestSuccess([...data.data])
      );
    }
    if (payload.add.length > 0) {
      const { data } = yield call(Api.addInterest, { interests: payload.add });
      yield put(
        Actions.main.updateMentorProfileInterestSuccess([...data.data])
      );
    }
    yield put(Actions.setting.addLoaderId(`interestsSaved`));
  } catch (e) {
    yield put(
      Actions.main.setMentorProfileMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }
  yield put(Actions.setting.removeLoaderId(`updateInterests`));
}

function* getGirls({ payload }: any) {
  yield put(Actions.main.setGirlsError(false));
  if (payload.page === 0) {
    yield put(Actions.main.setGirlsLoader(true));
  } else {
    yield put(Actions.main.setLoadMoreGirls(true));
  }
  // console.log(payload)
  const page = Number(payload.page + 1);
  try {
    const { data } = yield call(Api.getConnectedMentors, page);
    yield put(Actions.main.setGirlsSuccess([...payload.friends, ...data.data]));
    yield put(Actions.main.setGirlsLoadedPage(page));
    if (data.links.next) {
      yield put(Actions.main.setHasMoreGirls(true));
    } else {
      yield put(Actions.main.setHasMoreGirls(false));
    }
  } catch (e) {
    yield put(Actions.main.setGirlsError(true));
    yield put(
      Actions.main.setGirlsMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  if (payload.page === 0) {
    yield put(Actions.main.setGirlsLoader(false));
    yield put(Actions.main.setGirlsLoaded(true));
    // yield put(Actions.main.setHomeError(false))
  } else {
    yield put(Actions.main.setLoadMoreGirls(false));
  }
}

function* postEditMentorProfile({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload.id));
  console.log(payload);
  try {
    const { data } = yield call(Api.editProfile, payload.data);
    yield put(Actions.main.setMentorProfileSuccess({ ...data.data }));
    yield put(Actions.setting.setUser({ ...data.data }));
    // window.location.replace(`/mentor/profile/${payload.id}`);
    yield put(Actions.setting.addLoaderId("updateMentorProfileSaved"));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload.id));
}

function* postVerifyMentorProfile({ payload }: any) {
  yield put(Actions.setting.addLoaderId(payload.user.id));
  // console.log(payload)
  try {
    yield call(Api.requestVerification, payload.data);
    yield put(
      Actions.main.setMentorProfileSuccess({
        ...payload.user,
        status: "Pending",
      })
    );
    yield put(Actions.setting.setUser({ ...payload.user, status: "Pending" }));
    // window.location.replace(`/mentor/profile/${payload.id}`);
    yield put(Actions.setting.addLoaderId("verifyMentorProfileSaved"));
  } catch (e) {
    // yield put(Actions.main.setMentorProfileMessage(e.response?.data?.message ??'Something happened'))
  }
  yield put(Actions.setting.removeLoaderId(payload.user.id));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield all([
    takeLatest(types.HOME_GET_STORIES, getStories),
    takeLatest(types.HOME_GET_FEATURED_MENTORS, getFeaturedMentors),
    takeLatest(types.HOME_GET_FRIEND_REQUESTS, getFriendRequests),
    takeLatest(types.GET_FRIENDS, getFriends),
    takeLatest(types.HOME_GET_SUGGESTED_FRIENDS, getHomeSuggestedFriends),
    takeLatest(types.GET_JOURNAL_PUBLIC, getPublicJournal),
    takeLatest(types.GET_JOURNAL_PRIVATE, getPrivateJournal),
    takeLatest(types.GET_ANNOUNCEMENTS, getAnnouncements),
    takeLatest(types.GET_RESOURCES_IMAGES, getResourceImages),
    takeLatest(types.GET_RESOURCES_AUDIOS, getResourceAudios),
    takeLatest(types.GET_RESOURCES_VIDEOS, getResourceVideos),
    takeLatest(types.GET_RESOURCES_DOCUMENTS, getResourceDocuments),
    takeLatest(types.GET_RESOURCES_URLS, getResourceUrls),
    takeLatest(types.GET_MENTORS, getMentors),
    takeLatest(types.GET_PROFILE, getProfile),
    takeLatest(types.GET_PROFILE_JOURNAL_PUBLIC, getProfilePublicJournal),
    takeLatest(types.GET_PROFILE_JOURNAL_PRIVATE, getProfilePrivateJournal),
    takeLatest(types.GET_MENTOR_PROFILE, getMentorProfile),
    takeLatest(types.GET_CATEGORIES, getCategories),
    takeLatest(types.HOME_SUGGESTED_FRIENDS_MAKE_REQUEST, postMakeRequest),
    takeLatest(types.HOME_FRIEND_REQUESTS_ACCEPT, postAcceptRequest),
    takeLatest(types.HOME_FRIEND_REQUESTS_DECLINE, postDeclineRequest),
    takeLatest(types.MENTOR_CONNECTION, postMentorConnection),
    takeLatest(types.EDIT_PROFILE, postEditProfile),
    takeLatest(types.VERIFY_PROFILE, postVerifyProfile),
    takeLatest(types.ADD_STORY, postAddStory),
    takeLatest(types.GET_STORY, getStory),
    takeLatest(types.EDIT_STORY, postStoryUpdate),
    takeLatest(types.DELETE_STORY, postStoryDelete),
    takeLatest(types.REACT_STORY, postStoryReaction),
    takeLatest(types.GET_GROUPS, getGroups),
    takeLatest(types.REPORT_USER, postReportUser),
    takeLatest(types.BLOCK_USER, postBlockUser),
    takeLatest(types.UNBLOCK_USER, postUnblockUser),
    takeLatest(types.DELETE_USER, postDeleteUser),
    takeLatest(types.GET_INTERESTS, getInterests),
    takeLatest(types.GET_PROFILE_INTEREST, getUserInterests),
    takeLatest(types.UPDATE_PROFILE_INTEREST, postUpdateInterests),
    takeLatest(types.GET_MENTOR_PROFILE_INTERESTS, getMentorInterests),
    takeLatest(
      types.UPDATE_MENTOR_PROFILE_INTERESTS,
      postUpdateMentorInterests
    ),
    takeLatest(types.GET_GIRLS, getGirls),
    takeLatest(types.EDIT_MENTOR_PROFILE, postEditMentorProfile),
    takeLatest(types.VERIFY_MENTOR_PROFILE, postVerifyMentorProfile),
  ]);
}
