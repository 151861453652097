import auth, { AuthState } from "./auth.state";
import setting, { SettingState } from "./setting.state";
import main, { MainState } from "./main.state";
import mainSub, { Mainn } from "./mainn.state";
export interface IRootState {
  auth: AuthState;
  setting: SettingState;
  main: MainState;
  mainSub: Mainn;
}

const rootState: IRootState = {
  auth,
  setting,
  main,
  mainSub,
};

export default rootState;
