import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers';
import rootState from './states';
import rootSaga from './sagas';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [rootSaga,
    // routerMiddleware(history)
]

const store = createStore(rootReducer,rootState,composeEnhancers(applyMiddleware(...middlewares)));

export default store