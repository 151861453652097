import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { RouteComponentProps } from "react-router";
import Load from "../../components/Load";
import { remoteConfig } from "../../config/firebase";
import { getKey, KEYS } from "../../config/keys";
import { AppState } from "../../services/app.service";
import { Countries, pronouns, races } from "../../utils/constants";

const Register: React.FC<RouteComponentProps> = (props) => {
  const tab = props.location.hash.replace("#", "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("Afghanistan");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [race, setRace] = useState("Black (African, Caribean, Afro Latina)");
  const [pronoun, setPronoun] = useState("She / Her");
  const [checkPasswordConfirm, setCheckPasswordConfirm] = useState(false);
  const [checkPasswordLength, setCheckPasswordLength] = useState(false);
  const [image, setImage] = useState<File | any>(null);
  const [about, setAbout] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [linkedInURL, setLinkedInURL] = useState("");
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { loader, error, message, errorInput, state } = useSelector(
    (state: IRootState) => state.auth.register
  );
  const cardRef = useRef<HTMLHeadingElement>(null);
  const recaptchaSiteKey =
    remoteConfig.getValue(getKey(KEYS.RECAPTCHA_SITE_KEY)).asString() ?? "";
  const [loadCaptcha, setLoadCaptcha] = useState(-3);
  const [retries, setRetries] = useState(3);

  /*useEffect(() => {
        try {
            if(!loadCaptcha && recaptchaSiteKey.length > 0){
                // @ts-ignore
                loadReCaptcha(recaptchaSiteKey, () => {
                    console.log('captcha ready')
                    setLoadCaptcha(true)
                })
            }
        } catch (e) {
            console.error('captcha', e)
            setLoadCaptcha(false)
        }
    }, [recaptchaSiteKey])*/

  function toTop() {
    // @ts-ignore
    cardRef.current.scrollTo(0, 0);
  }

  useEffect(() => {
    try {
      loadRC();
    } catch (e) {
      console.error("captcha", e);
      setLoadCaptcha(loadCaptcha + 1);
    }
  }, [recaptchaSiteKey, loadCaptcha]);

  useEffect(() => {
    if (state === AppState.RETRY && retries > 0) {
      // console.log(state,retries)
      setRetries((prevState) => prevState - 1);
      dispatch(
        Actions.auth.register({
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phoneNumber,
          country,
          city,
          date_of_birth: dateOfBirth,
          password,
          image,
          mentor: tab === "mentor",
          confirmPassword,
          linkedin_url: linkedInURL,
          token,
          pronoun,
          race,
        })
      );
      // console.log(state)
    }
  }, [state]);

  const loadRC = () => {
    if (loadCaptcha < 0 && recaptchaSiteKey.length > 0) {
      // @ts-ignore
      loadReCaptcha(recaptchaSiteKey, () => {
        console.log("captcha ready");
        setLoadCaptcha(1);
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    loadRC();
    e.preventDefault();
    dispatch(
      Actions.auth.register({
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        country,
        city,
        date_of_birth: dateOfBirth,
        password,
        image,
        mentor: tab === "mentor",
        confirmPassword,
        linkedin_url: linkedInURL,
        token,
        pronoun,
        race,
      })
    );
  };

  return (
    <>
      <div
        className="card shadow-none border-0 ms-auto me-auto login-card h-100 overflow-auto pt-md-5 pb-md-5"
        style={{ maxWidth: "100%" }}
        ref={cardRef}
      >
        <div className="card-body rounded-0 text-left">
          <h2 className="fw-700 display1-size display2-md-size mb-4">
            Create a<br />
            <span className={tab === "mentor" ? "text-cyan" : "text-success"}>
              {tab === "mentor" ? "Mentor" : "Girl"}
            </span>{" "}
            account
          </h2>
          {loader && <Load message={message} />}
          {(error || errorInput) && (
            <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3 bg-danger opacity-5">
              <span className="text-white">{message}</span>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-user text-grey-500 pe-0" />
                  <input
                    type="text"
                    className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${
                      errorInput && firstName.length < 1 && "border-danger"
                    }`}
                    required
                    placeholder="First Name"
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-user text-grey-500 pe-0" />
                  <input
                    type="text"
                    className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${
                      errorInput && lastName.length < 1 && "border-danger"
                    }`}
                    required
                    placeholder="Last Name"
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group icon-input mb-3">
              <i className="font-sm ti-email text-grey-500 pe-0" />
              <input
                type="email"
                className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${
                  errorInput && email.length < 1 && "border-danger"
                }`}
                required
                placeholder="Your Email Address"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-mobile text-grey-500 pe-0" />
                  <select
                    name="race"
                    id="race"
                    className={`style2-input ps-5 form-select text-grey-900 font-xsss fw-600 ${
                      errorInput && country.length < 1 && "border-danger"
                    }`}
                    style={{ lineHeight: 0 }}
                    onChange={(e) => setRace(e.target.value)}
                  >
                    {races.map((race: string) => (
                      <option value={race}>{race}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-mobile text-grey-500 pe-0" />
                  <select
                    name="race"
                    id="race"
                    className={`style2-input ps-5 form-select text-grey-900 font-xsss fw-600 ${
                      errorInput && country.length < 1 && "border-danger"
                    }`}
                    style={{ lineHeight: 0 }}
                    onChange={(e) => setPronoun(e.target.value)}
                    required
                  >
                    {pronouns.map((pronoun: string) => (
                      <option value={pronoun}>{pronoun}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group icon-input mb-3">
              <i className="font-sm ti-mobile text-grey-500 pe-0" />
              <input
                type="tel"
                className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${
                  errorInput && phoneNumber.length < 1 && "border-danger"
                }`}
                required
                placeholder="Phone number"
                defaultValue={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-world text-grey-500 pe-0" />

                  <select
                    name="country"
                    id="country"
                    className={`style2-input ps-5 form-select text-grey-900 font-xsss fw-600 ${
                      errorInput && country.length < 1 && "border-danger"
                    }`}
                    style={{ lineHeight: 0 }}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {Countries.map((country: string) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-location-pin text-grey-500 pe-0" />
                  <input
                    type="text"
                    className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${
                      errorInput && city.length < 1 && "border-danger"
                    }`}
                    required
                    placeholder="City"
                    defaultValue={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group icon-input mb-1">
              <input
                type="password"
                className={`style2-input ps-5 form-control text-grey-900 font-xsss ls-3 ${
                  !checkPasswordLength && "mb-3"
                } ${
                  (checkPasswordLength ||
                    (errorInput && password.length < 1)) &&
                  "border-danger"
                }`}
                placeholder="Password"
                defaultValue={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length < 8) {
                    setCheckPasswordLength(true);
                  } else {
                    setCheckPasswordLength(false);
                  }
                  if (e.target.value === confirmPassword) {
                    setCheckPasswordConfirm(false);
                  } else {
                    setCheckPasswordConfirm(true);
                  }
                }}
              />
              <i className="font-sm ti-lock text-grey-500 pe-0" />
            </div>
            {checkPasswordLength && (
              <p className="text-danger mb-3">must be 8 characters length</p>
            )}
            <div className="form-group icon-input mb-3">
              <input
                type="password"
                className={`style2-input ps-5 form-control text-grey-900 font-xsss ls-3 ${
                  (checkPasswordConfirm ||
                    (errorInput && confirmPassword.length < 1)) &&
                  "border-danger"
                }`}
                placeholder="Confirm Password"
                defaultValue={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (e.target.value === password) {
                    setCheckPasswordConfirm(false);
                  } else {
                    setCheckPasswordConfirm(true);
                  }
                }}
              />
              <i className="font-sm ti-lock text-grey-500 pe-0" />
            </div>
            {tab === "mentor" && (
              <>
                <label className="mont-font fw-600 font-xsss mb-1 text-dark">
                  Upload photo
                </label>
                <div className="form-group icon-input mb-3">
                  <input
                    type="file"
                    className={`style2 ps-5 form-control text-grey-900 font-xsss fw-600 ${
                      errorInput && image === null && "border-danger"
                    }`}
                    accept="image/*"
                    required
                    onChange={(e) =>
                      setImage(e.target.files ? e.target.files[0] : null)
                    }
                  />
                  <i className="font-sm ti-image text-grey-500 pe-0" />
                </div>
              </>
            )}

            <div className="row">
              <div className={`${tab === "mentor" ? "col-md-6" : "col-12"}`}>
                <label className="mont-font fw-600 font-xsss mb-1 text-dark">
                  Date of birth
                </label>
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-calendar text-grey-500 pe-0" />
                  <input
                    type="date"
                    className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600`}
                    required
                    max={tab === "mentor" ? "2005-01-01" : "2010-01-01"}
                    defaultValue={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>
              </div>

              <div className={`${tab !== "mentor" && "d-none"} col-md-6`}>
                <label className="mont-font fw-600 font-xsss mb-1 text-dark">
                  LinkedIn{" "}
                </label>
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-linkedin text-grey-500 pe-0" />
                  <input
                    type="text"
                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                    placeholder="LinkedIn URL"
                    defaultValue={linkedInURL}
                    onChange={(e) => setLinkedInURL(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group icon-input mb-3">
              <textarea
                className={`style2-textarea h100 ps-5 form-control text-grey-900 font-xsss fw-600`}
                placeholder="Tell us something about yourself"
                rows={5}
                onChange={(e) => setAbout(e.target.value)}
                defaultValue={about}
              />
              <i className="font-sm ti-write text-grey-500 pe-0" />
            </div>
            <div className="form-check text-left mb-3">
              <input
                type="checkbox"
                className="form-check-input mt-2"
                id="exampleCheck2"
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
              />
              <label className="form-check-label font-xsss text-grey-500">
                I agree to the Power to Girls "My Power App" user terms and
                agreement{" "}
              </label>
            </div>

            <div className="col-sm-12 p-0 text-left">
              <div className="form-group mb-1">
                <button
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                  type="submit"
                  disabled={checkPasswordConfirm || checkPasswordLength}
                  /*onClick={()=> dispatch(Actions.auth.register(
                          {
                            first_name: firstName,
                            last_name: lastName,
                            email,
                            phone_number: phoneNumber,
                            country,
                            city,
                            date_of_birth: dateOfBirth,
                            password,
                            image,
                            mentor: tab === "mentor",
                            confirmPassword,
                            token
                          }
                        ))}*/
                >
                  Register
                </button>
              </div>
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Already have account{" "}
                <Link to="/login" className="fw-700 ms-1">
                  Login
                </Link>
              </h6>
              {tab === "mentor" ? (
                <Link to="/register" onClick={toTop} className="fw-700 ms-1">
                  Register as Girl
                </Link>
              ) : (
                <Link
                  to="/register#mentor"
                  onClick={toTop}
                  className="fw-700 ms-1"
                >
                  Register as Mentor
                </Link>
              )}
            </div>
          </form>
        </div>
      </div>
      {loadCaptcha && (
        <ReCaptcha
          ref={recaptchaRef}
          action="submit"
          sitekey={recaptchaSiteKey}
          verifyCallback={(e) => setToken(e)}
        />
      )}
    </>
  );
};

export default Register;
