import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { AnnouncementResponse } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import moment from "moment";
import AnnouncementDetails from "../../components/AnnouncementDetails";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";

function Announcements() {
  const dispatch = useDispatch();
  // const {
  //     loadedPage,
  //     announcements,
  //     loaded,
  //     loader,
  //     hasMore,
  //     loadMore
  // } = useSelector(((state: IRootState) => state.main.announcements))
  // console.log(stories,featuredMentors)
  const state = useSelector((state: IRootState) => state.mainSub);

  const announcements: BaseState<Array<AnnouncementResponse>> =
    state[StateKeys.ANNOUNCEMENTS];

  const [activeId, setActiveId] = useState("");

  // useEffect(() => {
  //     if (loadedPage === 0 && !loaded) {
  //         dispatch(Actions.main.getAnnouncements(loadedPage, announcements))
  //     }
  // }, []);

  const { data, pageState, hasMore, currentPage } = announcements;

  useEffect(() => {
    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.ANNOUNCEMENTS,
          apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const handleClose = () => setActiveId("");

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <div className="mb-0 mt-0 d-flex align-items-center">
                  <h2 className="fw-700 font-md text-grey-900">
                    My Announcements
                  </h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                <div className="search-form-2 ms-2">
                                    <i className="ti-search font-xss"/>
                                    <input type="text" className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                                </div>
                            </form>*/}
                </div>
              </div>

              <div className="row ps-2 pe-1">
                {pageState === AppState.LOADED ||
                pageState === AppState.LOADING_MORE ? (
                  data.length > 0 ? (
                    <>
                      {(data as Array<AnnouncementResponse>).map(
                        (item) =>
                          item.is_active && (
                            <div
                              key={item.id}
                              className="col-lg-4 col-md-6 pe-2 ps-2"
                              style={{ cursor: "pointer" }}
                            >
                              {/*<Link to={'announcements'} >*/}
                              <div
                                className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden"
                                onClick={() => setActiveId(item.id)}
                              >
                                {/*<div className="card-image w-100">
                                                        <img src={`assets/images/${value.imageUrl}`} alt="event" className="w-100 rounded-3" />
                                                    </div>*/}
                                <div className="card-body d-flex ps-0 pe-0 pb-0">
                                  <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                                    <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                                      <span className="ls-3 d-block font-xsss text-grey-500 fw-500">
                                        {moment(item.date).format("MMM")}
                                      </span>
                                      {moment(item.date).date()}
                                    </h4>
                                  </div>
                                  <h2 className="fw-700 lh-3 font-xss">
                                    {item.title}
                                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                                      {/*<i className="ti-location-pin me-1"></i>{value.location}*/}
                                      {item.title.length > 20
                                        ? item.body.substring(0, 25)
                                        : item.body.substring(0, 50)}
                                      {((item.title.length > 20 &&
                                        item.body.length > 25) ||
                                        item.body.length > 50) &&
                                        "..."}
                                    </span>
                                  </h2>
                                </div>
                                {/*<div className="card-body p-0">
                                                        <ul className="memberlist mt-4 mb-2 ms-0 d-inline-block">
                                                            <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                            <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                            <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                            <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                            <li className="last-member"><a href="/defaultevent" className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+2</a></li>
                                                        </ul>
                                                        <a href="/defaultevent" className="font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-success d-inline-block text-white me-1">APPLY</a>
                                                    </div>*/}
                              </div>
                              {/*</Link>*/}
                              <AnnouncementDetails
                                key={item.id}
                                item={item}
                                handleClose={handleClose}
                                show={activeId === item.id}
                              />
                            </div>
                          )
                      )}
                      {hasMore && pageState === AppState.LOADED && (
                        <div
                          className="col-lg-12 mt-3 mb-5 text-center"
                          onClick={() =>
                            dispatch(
                              subMain.getPaginatedPageData({
                                stateKey: StateKeys.ANNOUNCEMENTS,
                                apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
                                previousPage: currentPage!,
                              })
                            )
                          }
                        >
                          <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                            Load More
                          </span>
                        </div>
                      )}
                      {pageState === AppState.LOADING_MORE && <Load />}
                    </>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <Load message="Loading" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcements;
