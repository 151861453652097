import React, {FormEvent, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { Link } from "react-router-dom";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { remoteConfig } from "../../config/firebase";
import { getKey, KEYS } from "../../config/keys";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const recaptchaRef = useRef(null);
  const [token, obisayuou] = useState("");
  const dispatch = useDispatch();
  const { loader, error, message } = useSelector(
    (state: IRootState) => state.auth.forgotPassword
  );
  const recaptchaSiteKey =
    remoteConfig.getValue(getKey(KEYS.RECAPTCHA_SITE_KEY)).asString() ?? "";
  const [loadCaptcha, setLoadCaptcha] = useState(-3);

  /*useEffect(() => {
    try {
      if (!loadCaptcha && recaptchaSiteKey.length > 0) {
        // @ts-ignore
        loadReCaptcha(recaptchaSiteKey, () => {
          console.log("captcha ready");
          setLoadCaptcha(true);
        });
      }
    } catch (e) {
      console.error("captcha", e);
      setLoadCaptcha(false);
    }
  }, [recaptchaSiteKey]);*/

  useEffect(() => {
    try {
      loadRC();
    } catch (e) {
      console.error("captcha", e);
      setLoadCaptcha(loadCaptcha + 1);
    }
  }, [recaptchaSiteKey, loadCaptcha]);

  const loadRC = () => {
    if (loadCaptcha < 0 && recaptchaSiteKey.length > 0) {
      // @ts-ignore
      loadReCaptcha(recaptchaSiteKey, () => {
        console.log("captcha ready");
        setLoadCaptcha(1);
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    loadRC();
    e.preventDefault();
    dispatch(Actions.auth.forgotPassword({ email, token }))
  };

  return (
    <>
      <div className="card shadow-none border-0 ms-auto me-auto login-card">
        <div className="card-body rounded-0 text-left">
          <h2 className="fw-700 display1-size display2-md-size mb-3">
            Change <br />
            your password
          </h2>
          {loader && <Load message="Please Wait" />}
          {error && <NoData message={message} error={true} />}
          {message.length > 0 && !error && (
            <NoData message={message} success={true} />
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group icon-input mb-3">
              <i className="font-sm ti-email text-grey-500 pe-0" />
              <input
                type="email"
                className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${
                  error && "border-danger"
                }`}
                placeholder="Your Email Address"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </form>

          <div className="col-sm-12 p-0 text-left">
            <div className="form-group mb-1">
              <button
                className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                onClick={() =>
                  dispatch(Actions.auth.forgotPassword({ email, token }))
                }
              >
                Submit
              </button>
            </div>
            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
              Already have account{" "}
              <Link to="/login" className="fw-700 ms-1">
                Login
              </Link>
            </h6>
          </div>
        </div>
      </div>
      {loadCaptcha && (
        <ReCaptcha
          ref={recaptchaRef}
          action="submit"
          sitekey={recaptchaSiteKey}
          verifyCallback={(e) => obisayuou(e)}
        />
      )}
    </>
  );
};

export default ForgotPassword;
