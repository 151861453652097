import axios from "axios";
import contants from "../stores/contants";
import {
  BlockRequest,
  ILoginRequest,
  ReactionRequest,
  RegisterRequest,
  ReportRequest,
  StoryRequest,
  UploadFileRequest,
  UserEditRequest,
  UserVerifyRequest,
} from "./api.request.service";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem(contants.TOKEN),
  },
});

api.interceptors.response.use(
  (response) => {
    process.env.NODE_ENV !== "production" && console.log("response", response);
    return response;
  },
  (error) => {
    process.env.NODE_ENV !== "production" &&
      console.log("error", error.response);
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem(contants.TOKEN);
      localStorage.removeItem(contants.USER);
      localStorage.setItem("to.path", window.location.pathname);
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

const Api = {
  login: (data: ILoginRequest) => api.post("login", data),
  passwordForgot: (email: string) =>
    api.post("password/forgot", { email: email }),
  register: (data: RegisterRequest) =>
    api.post(`${data.mentor ? "mentor/" : ""}register`, data),
  getProfile: (id: string) => api.get(`profile/${id}`),
  editProfile: (data: UserEditRequest) => api.post(`profile`, data),
  getInterest: () => api.get(`interests`),
  getUserInterest: (id: string) => api.get(`interests/${id}`),
  addInterest: (data: any) => api.post(`interests`, data),
  deleteInterest: (data: any) => api.delete(`interests`, { data }),
  getSuggestions: (page: number) =>
    api.get(`suggestions`, {
      params: {
        page,
      },
    }),
  getFriends: (page: number) =>
    api.get(`friends`, {
      params: {
        page,
      },
    }),
  makeFriendRequest: (user_id: string) => api.post(`friends`, { user_id }),
  getRequests: (page: number) =>
    api.get(`requests`, {
      params: {
        page,
      },
    }),
  acceptFriendRequest: (friend_id: string) =>
    api.post(`requests/${friend_id}/accept`),
  declineFriendRequest: (friend_id: string) =>
    api.post(`requests/${friend_id}/decline`),
  getStories: (page: number) =>
    api.get(`stories`, {
      params: {
        page,
      },
    }),
  getStory: (id: string) => api.get(`stories/${id}`),
  markStoryAsViewed: (id: string) => api.post(`story/${id}/view`),
  deleteStory: (id: string) => api.delete(`stories/${id}`),
  getUserStories: (userId: string, page: number, is_public: number) =>
    api.get(`${userId}/stories`, {
      params: {
        page,
        is_public,
      },
    }),
  uploadFile: (data: UploadFileRequest) => {
    const form = new FormData();
    form.append("file", data.file);
    form.append("for", data.for);
    form.append("type", data.type);
    return api.post(`/file/upload`, form);
  },

  addStory: (data: StoryRequest) => console.log(data),
  // api.post(`story`, data),
  updateStory: (id: string, data: StoryRequest) =>
    api.post(`story/${id}`, data),
  reactStory: (id: string, data: ReactionRequest) =>
    api.post(`story/${id}/view`, data),
  getAnnouncements: (page: number) =>
    api.get(`announcements`, {
      params: {
        page,
      },
    }),
  getResources: (type: string, page: number) =>
    api.get(`resources`, {
      params: {
        page,
        type,
      },
    }),

  getFeaturedMentors: (page: number) =>
    api.get(`mentors/featured`, {
      params: {
        page,
      },
    }),
  getMentors: (page: number) =>
    api.get(`mentors`, {
      params: {
        page,
      },
    }),
  getConnectedMentors: (page: number) =>
    api.get(`mentors/connection`, {
      params: {
        page,
      },
    }),
  connectMentor: (user_id: string) => api.post(`mentors/connect`, { user_id }),
  getCategories: () => api.get(`categories`),
  requestVerification: (data: UserVerifyRequest) =>
    api.post(`verification`, data),
  report: (data: ReportRequest) => api.post(`report`, data),
  block: (data: BlockRequest) => api.post(`report/block`, data),
  unblock: (data: BlockRequest) => api.post(`report/unblock`, data),
  delete: () => api.post(`report/delete`),
  getGroups: (page: number) =>
    api.get(`chat/groups`, {
      params: {
        page,
      },
    }),

  //other
  getPageData: (url: string, params: Object) =>
    api.get(url, { params: { ...params } }),
  postPageData: (url: string, data: any, params: Object) =>
    api.post(url, data, { params: { ...params } }),
  getPaginatedPageData: (url: string, params: Object) =>
    api.get(url, { params: { ...params } }),
  postPaginatedPageData: (url: string, data: any, params: Object) =>
    api.post(url, data, { params: { ...params } }),
  deletePaginatedPageData: (url: string, data: any, params: Object) =>
    api.post(url, data, { params: { ...params } }),
  deletePageData: (url: string, data: any, params: Object) =>
    api.delete(url, data),
};

export default Api;
