import { BaseDataResponse } from "../../services/api.response.service";
import { AppState } from "../../services/app.service";

export const types = {
  PAGE_STATE: "PAGE_STATE",
  PAGE_MESSAGE: "PAGE_MESSAGE",

  POST_PAGE_STATE: "POST_PAGE_STATE",
  POST_PAGE_STATE_MESSAGE: "POST_PAGE_STATE_MESSAGE",

  PAGE_ARRAY_DATA: "PAGE_ARRAY_DATA",
  PAGE_ARRAY_DATA_SUCCESS: "PAGE_ARRAY_DATA_SUCCESS",

  DELETE_PAGE_DATA: "DELETE_PAGE_DATA",
  DELETE_PAGE_OBJECT_DATA_SUCCESS: "DELETE_PAGE_OBJECT_DATA_SUCCESS",

  PAGE_OBJECT_DATA: "PAGE_OBJECT_DATA",
  POST_PAGE_DATA: "POST_PAGE_DATA",
  PAGE_OBJECT_DATA_SUCCESS: "PAGE_OBJECT_DATA_SUCCESS",

  PAGINATED_PAGE_DATA: "PAGINATED_PAGE_DATA",
  PAGINATED_PAGE_DATA_SUCCESS: "PAGINATED_PAGE_DATA_SUCCESS",
  PAGINATED_PAGE_OBJECT_SUCCESS: "PAGINATED_PAGE_OBJECT_SUCCESS",
  PAGINATED_PAGE_HAS_MORE: "PAGINATED_PAGE_HAS_MORE",
  PAGINATED_PAGE_CURRENT_PAGE_NUMBER: "PAGINATED_PAGE_CURRENT_PAGE_NUMBER",

  DATA_PERSIST: "DATA_PERSIST",
  DATA_PERSIST_WITH_KEY: "DATA_PERSIST_WITH_KEY",
  POST_PAGINATED_PAGE_DATA: "POST_PAGINATED_PAGE_DATA",
};

interface BasePageStatePayload {
  stateKey: string;
}

export interface Links {
  first: string;
  last: string;
  prev?: string;
  next?: string;
}

export interface MetaLink {
  url?: string;
  label: string;
  active: boolean;
}

export interface Meta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
  links: Array<MetaLink>;
}

export interface PageDataPayload extends BasePageStatePayload {
  apiEndpoint: string;
  refresh?: boolean;
  storeKey?: string;
  clear?: boolean;
  objectID?: any;
  // objectID?: string | number;
  // is_public?: number;
}

// export interface PaginatedDataWithObjectResponse<T>
//   extends PaginatedDataResponse<T> {
//   // content_class?: ClassResponse;
//   // test_class?: ClassResponse;
// }

export interface PaginatedPageDataPayload extends PageDataPayload {
  previousPage: number;
  obj?: boolean;
  params?: Object;
}

export interface PaginatedDataResponse<T> extends BaseDataResponse<T> {
  links: Links;
  meta: Meta;
}

export interface PostPageDataPayload<T = any> extends PageDataPayload {
  req: T;
  append?: "start" | "end";
}

export interface DeletePageDataPayload<T = any> extends PageDataPayload {
  req?: T;
  append?: "start" | "end";
}

export interface PostPaginatedPageDataPayload<T = any>
  extends PaginatedPageDataPayload {
  req: T;
}

interface PageSuccessPayload<T = any> extends BasePageStatePayload {
  data: T;
  clear?: boolean;
  id?: string;
  append?: "start" | "end";
  objectID?: any;
}

const subMain = {
  togglePageState: (payload: PageSuccessPayload<AppState>) => ({
    type: types.PAGE_STATE,
    payload,
  }),
  setPageMessage: (payload: PageSuccessPayload<string>) => ({
    type: types.PAGE_MESSAGE,
    payload,
  }),

  togglePostPageState: (payload: PageSuccessPayload<AppState>) => ({
    type: types.POST_PAGE_STATE,
    payload,
  }),

  setPostPageMessage: (payload: PageSuccessPayload<string>) => ({
    type: types.POST_PAGE_STATE_MESSAGE,
    payload,
  }),

  getPageArrayData: (payload: PageDataPayload) => ({
    type: types.PAGE_ARRAY_DATA,
    payload,
  }),

  postPageArrayDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGE_ARRAY_DATA_SUCCESS,
    payload,
  }),

  getPageObjectData: (payload: PageDataPayload) => ({
    type: types.PAGE_OBJECT_DATA,
    payload,
  }),

  postPageData: (payload: PostPageDataPayload) => ({
    type: types.POST_PAGE_DATA,
    payload,
  }),

  postPageObjectDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGE_OBJECT_DATA_SUCCESS,
    payload,
  }),

  deletePageData: (payload: DeletePageDataPayload) => ({
    type: types.DELETE_PAGE_DATA,
    payload,
  }),

  deletePageObjectDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.DELETE_PAGE_OBJECT_DATA_SUCCESS,
    payload,
  }),

  getPaginatedPageData: (payload: PaginatedPageDataPayload) => ({
    type: types.PAGINATED_PAGE_DATA,
    payload,
  }),

  postPaginatedPageDataSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGINATED_PAGE_DATA_SUCCESS,
    payload,
  }),
  postPaginatedPageObjectSuccess: (payload: PageSuccessPayload) => ({
    type: types.PAGINATED_PAGE_OBJECT_SUCCESS,
    payload,
  }),
  togglePaginatedPageHasMore: (payload: PageSuccessPayload<boolean>) => ({
    type: types.PAGINATED_PAGE_HAS_MORE,
    payload,
  }),
  setPaginatedPageCurrentPageNumber: (payload: PageSuccessPayload<number>) => ({
    type: types.PAGINATED_PAGE_CURRENT_PAGE_NUMBER,
    payload,
  }),

  postPageDataPersist: (payload: PageSuccessPayload) => ({
    type: types.DATA_PERSIST,
    payload,
  }),
  postPageDataPersistWithKey: (payload: PageSuccessPayload) => ({
    type: types.DATA_PERSIST_WITH_KEY,
    payload,
  }),
  postPaginatedPageData: (payload: PostPaginatedPageDataPayload) => ({
    type: types.POST_PAGINATED_PAGE_DATA,
    payload,
  }),
};
export default subMain;
