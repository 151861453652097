import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../redux/actions";
import { IRootState } from "../redux/states";
import Load from "./Load";
import subMain from "../redux/actions/mainn.action";
import { StateKeys } from "../redux/states/mainn.state";
import { ApiEndpoint } from "../services/api.endpoint.service";
import { ReactionRequest } from "../services/api.request.service";
import { AppState } from "../services/app.service";
import { StoryResponse } from "../services/api.response.service";

interface props {
  story: StoryResponse;
  detailed: boolean;
  postPageState?: number;
  isPublic: boolean;
}

function Story({ story, detailed, postPageState, isPublic }: props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);
  const [isOpen, setIsOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isReact, setIsReact] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleMore = () => setIsMoreOpen(!isMoreOpen);
  const toggleReact = () => setIsReact(!isReact);
  const toggleDelete = () => setIsDelete(!isDelete);

  const menuClass = `${isOpen ? " show" : ""}`;
  const emojiClass = `${isReact ? " active" : ""} ${
    detailed && story.resources.length > 0 && "position-relative right-200"
  }`;
  const moreClass = () => `${isMoreOpen ? " show" : ""}`;

  useEffect(() => {
    if (postPageState === AppState.LOADED) {
      setIsReact(false);
    }
  }, [postPageState]);

  useEffect(() => {}, [postPageState]);

  const react = (storyID: string, reaction: ReactionRequest) => {
    dispatch(
      subMain.postPageData({
        stateKey:
          StateKeys[
            //check if path name is not home then make stateKeys
            window.location.pathname !== "/home"
              ? isPublic
                ? "JOURNAL"
                : "PRIVATE_JOURNAL"
              : "STORIES"
          ],
        apiEndpoint: ApiEndpoint.REACT_TO_STORY(storyID),
        req: reaction,
        objectID: storyID,
        clear: true,
      })
    );
  };

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3">
          <img
            src={story.by.photo_url}
            alt={story.by.first_name}
            className="shadow-sm rounded-circle w45 h45"
          />
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          {" "}
          {story.by.first_name} {story.by.last_name}{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {moment.duration(moment(story.created_at).diff(moment())).asDays() <
            -7
              ? moment(story.created_at).format("ddd DD, MMM YYYY")
              : moment(story.created_at).fromNow()}
          </span>
        </h4>
        {/*<div className="ms-auto pointer"><i
                    className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"/></div>*/}
        {user!.id === story.by.id && (
          <div
            className={`ms-auto pointer ${moreClass()}`}
            id="moreMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={toggleMore}
          >
            <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss" />
          </div>
        )}
        <div
          className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${moreClass()} top-10per`}
          aria-labelledby="moreMenu"
        >
          <Link to={`/story/edit/${story.id}`} className="card-body p-0 d-flex">
            <i className="feather-edit text-grey-500 me-3 font-lg" />
            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
              Edit
              <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                Update the story
              </span>
            </h4>
          </Link>
          <div className="card-body p-0 d-flex mt-2" onClick={toggleDelete}>
            <i className="feather-x text-danger me-3 font-lg" />
            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
              Delete
              <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                Permanent Removal
              </span>
            </h4>
          </div>
          {isDelete && (
            <div className="card-body p-0 d-flex flex-column mt-2">
              {postPageState === AppState.LOADING ? (
                <Load message={`Deleting Story`} noMarginTop={true} />
              ) : (
                <>
                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                    Are you sure?
                  </h4>
                  <div className="d-flex justify-content-evenly">
                    <span
                      className={`d-none pointer d-lg-block p-3 z-index-1 rounded-3 text-warning font-xsssss text-uppercase fw-700 ls-3 bg-transparent`}
                      onClick={toggleDelete}
                    >
                      No
                    </span>
                    <span
                      className={`d-none pointer d-lg-block p-3 z-index-1 rounded-3 text-danger font-xsssss text-uppercase fw-700 ls-3 bg-transparent`}
                      onClick={() =>
                        dispatch(
                          subMain.deletePageData({
                            stateKey: StateKeys.STORIES,
                            apiEndpoint: ApiEndpoint.STORY(story.id),
                            objectID: story.id,
                          })
                        )
                      }
                    >
                      Yes
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          {/*<div className="card-body p-0 d-flex mt-2">
                        <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide all from Group <span
                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span>
                        </h4>
                    </div>
                    <div className="card-body p-0 d-flex mt-2">
                        <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                        <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">Unfollow Group <span
                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span>
                        </h4>
                    </div>*/}
        </div>
      </div>
      {/*{postvideo ?
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                <a href="/defaultvideo" className="video-btn">
                    <video autoPlay loop className="float-right w-100">
                        <source src={`assets/images/${postvideo}`} type="video/mp4" />
                    </video>
                </a>
            </div>
            : ''}*/}
      {detailed ? (
        <div className="card-body p-0 me-lg-5">
          <h4 className="mb-2">{story.title}</h4>
          {story.body.split("\n").map((str) => (
            <p
              className={`fw-500 text-grey-600 lh-26 font-xssss w-100 mb-2 text-justify mb-3`}
            >
              {str}
            </p>
          ))}
        </div>
      ) : (
        <Link to={`/story/view/${story.id}`} className="card-body p-0 me-lg-5">
          <h5 className="mb-2">{story.title}</h5>

          <p
            className={`fw-500 text-grey-${
              detailed ? 600 : 500
            } lh-26 font-xssss w-100 mb-2 text-justify`}
          >
            {story.body.substring(0, 200)}
            {story.body.length > 200 && (
              <div className="fw-600 text-primary ms-2">See more</div>
            )}
          </p>
        </Link>
      )}
      {/*{postimage ?*/}
      {story.cover_image !== user?.photo_url && (
        <div className="card-body d-block p-0 mb-3">
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1">
              <img
                src={story.cover_image}
                className="rounded-3 w-100"
                alt="post"
              />
            </div>
          </div>
        </div>
      )}
      {/*: ''}*/}
      <div className="card-body d-flex p-0">
        <div
          className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
          onClick={toggleReact}
        >
          <i className="em em-heart me-1" /> {story.reactions["1"]}
          <i className="em em-grinning me-1 ms-2" /> {story.reactions["2"]}
          <i className="em em-rolling_on_the_floor_laughing me-1 ms-2" />{" "}
          {story.reactions["3"]}
          <i className="em em---1 me-1 me-1 ms-2" /> {story.reactions["4"]} |{" "}
          <span className="ms-2">{story.views} Views</span>
        </div>
        <div className={`emoji-wrap pointer ${emojiClass}`}>
          <ul className="emojis list-inline mb-0">
            <li
              className="emoji list-inline-item"
              onClick={() => react(story.id, { reaction: 1 })}
            >
              <i className="em em-heart" />
            </li>
            <li
              className="emoji list-inline-item"
              onClick={() => react(story.id, { reaction: 2 })}
            >
              <i className="em em-grinning" />
            </li>
            <li
              className="emoji list-inline-item"
              onClick={() => react(story.id, { reaction: 3 })}
            >
              <i className="em em-rolling_on_the_floor_laughing" />
            </li>
            <li
              className="emoji list-inline-item"
              onClick={() => react(story.id, { reaction: 4 })}
            >
              <i className="em em---1" />
            </li>
          </ul>
        </div>
        {/*<a href="/defaultvideo"
                   className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i
                    className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span
                    className="d-none-xss">22 Comment</span></a>*/}
        {/*<div
                    className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${menuClass}`}
                    id={`dropdownMenu${story.id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}>
                    <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"/><span
                    className="d-none-xs">Share</span></div>*/}
        <div
          className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass} top-70per`}
          aria-labelledby={`dropdownMenu${story.id}`}
        >
          <h4
            className="fw-700 font-xss text-grey-900 d-flex align-items-center"
            onClick={toggleOpen}
          >
            Share{" "}
            <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2" />
          </h4>
          {/*<div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i
                                className="font-xs ti-facebook text-white"/></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i
                                className="font-xs ti-twitter-alt text-white"/></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i
                                className="font-xs ti-linkedin text-white"/></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i
                                className="font-xs ti-instagram text-white"/></span></li>
                            <li><span className="btn-round-lg pointer bg-pinterest"><i
                                className="font-xs ti-pinterest text-white"/></span></li>
                        </ul>
                    </div>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i
                                className="font-xs ti-tumblr text-white"/></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i
                                className="font-xs ti-youtube text-white"/></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i
                                className="font-xs ti-flickr text-white"/></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-black"><i
                                className="font-xs ti-vimeo-alt text-white"/></span></li>
                            <li><span className="btn-round-lg pointer bg-whatsup"><i
                                className="font-xs feather-phone text-white"/></span></li>
                        </ul>
                    </div>*/}
          <h4
            className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3"
            onClick={() =>
              navigator.clipboard.writeText(
                `${window.location.host}/story/view/${story.id}`
              )
            }
          >
            Copy Link <i className="feather-copy ms-1 font-xs text-grey-800" />
          </h4>
          {/*<i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"/>
                    <input type="text" placeholder={`${window.location.host}/story/view/${story.id}`}
                           className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg"/>*/}
        </div>
      </div>

      {detailed && story.resources.length > 0 && (
        <div className="card-body p-0 mt-5">
          <div className="row">
            <div className="col-xl-12">
              <div className="row ps-2 pe-1">
                <h3 className="mb-2">Resources</h3>
              </div>
              <div className="row ps-2 pe-1">
                {story.resources.map((item) => (
                  <a
                    rel="noopener noreferrer"
                    href={item.url}
                    target="_blank"
                    key={item.id}
                    className="col-lg-4 col-md-6 pe-2 ps-2"
                  >
                    <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                      <div className="card-body d-flex ps-0 pe-0 pb-0 pt-0">
                        <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                          <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                            <i
                              className={`font-xl me-1 ${
                                item.type === "image" && "feather-external-link"
                              }`}
                            />
                          </h4>
                        </div>
                        <h2 className="fw-700 lh-3 font-xss">
                          <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                            {item.type}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Story;
