import { put, all, takeLatest, call, delay } from "redux-saga/effects";
import {
  ILoginRequest,
  RegisterRequest,
} from "../../services/api.request.service";
import Actions from "../actions";
import { types } from "../actions/auth.action";
import Api from "../../services/api.service";
import contants from "../../../store/contants";
import { AppState } from "../../services/app.service";

function* login({ payload }: any) {
  yield put(Actions.auth.setLoginError(false));
  yield put(Actions.auth.setLoginMessage(""));
  yield put(Actions.auth.setLoginLoading(true));
  // console.log(payload)
  try {
    if (payload.token.length > 1) {
      const { data } = yield call(Api.login, payload as ILoginRequest);
      localStorage.setItem(contants.TOKEN, data.data.access_token);
      localStorage.setItem(
        contants.USER,
        JSON.stringify({ ...data.data, access_token: null })
      );
      localStorage.setItem(contants.CHAT, JSON.stringify([]));

      const path = localStorage.getItem("to.path");
      localStorage.removeItem("to.path");
      window.location.replace(
        path ?? data.data?.mentor_profile ? "/connection" : "/home"
      );

      yield put(Actions.auth.setLoginError(false));
      yield put(Actions.auth.setLoginMessage("Success"));
    } else {
      yield put(Actions.auth.setLoginError(true));
      yield put(Actions.auth.setLoginMessage("Please refresh the page"));
    }
  } catch (e) {
    yield put(Actions.auth.setLoginError(true));
    yield put(
      Actions.auth.setLoginMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Email or password invalid"
      )
    );
  }

  yield put(Actions.auth.setLoginLoading(false));
}

function* register({ payload }: any) {
  yield put(Actions.auth.setRegisterError(false));
  yield put(Actions.auth.setRegisterMessage(""));
  yield put(Actions.auth.setRegisterLoading(true));
  yield put(Actions.auth.setRegisterErrorInput(false));
  // console.log(payload)
  try {
    if (
      payload.first_name.length > 0 &&
      payload.last_name.length > 0 &&
      payload.email.length > 0 &&
      payload.phone_number.length > 0 &&
      payload.city.length > 0 &&
      payload.country.length > 0 &&
      payload.password.length > 0 &&
      payload.confirmPassword.length > 0 &&
      payload.password === payload.confirmPassword
    ) {
      // console.log(payload)
      if (payload.token.length > 1) {
        if (payload.mentor) {
          if (payload.image) {
            yield put(Actions.auth.setRegisterMessage("Uploading file"));
            const { data } = yield call(Api.uploadFile, {
              file: payload.image,
              for: "profile",
              type: "image",
            });
            payload = { ...payload, photo_url: data.url };
          }

          // else {
          //   yield put(
          //     Actions.auth.setRegisterMessage(
          //       "Please upload a photo of yourself"
          //     )
          //   );
          //   yield put(Actions.auth.setRegisterErrorInput(true));
          //   yield put(Actions.auth.setRegisterLoading(false));
          //   return;
          // }
        }
        yield put(Actions.auth.setRegisterMessage("Creating account"));
        const { data } = yield call(Api.register, payload as RegisterRequest);

        yield put(Actions.auth.setRegisterError(false));
        yield put(Actions.auth.setRegisterMessage("Logging in"));

        yield new Promise((resolve) =>
          setTimeout(() => {
            localStorage.setItem(contants.TOKEN, data.data.access_token);
            localStorage.setItem(
              contants.USER,
              JSON.stringify({ ...data.data, access_token: null })
            );
            localStorage.setItem(contants.CHAT, JSON.stringify([]));
            window.location.replace(
              payload.tab === "mentor" ? "/connection" : "/"
            );
            //@ts-ignore
            resolve();
          }, 3000)
        );
        // yield put(Actions.auth.setRegisterMessage('Logging in'))
      } else {
        yield put(Actions.auth.setRegisterError(true));
        yield put(Actions.auth.setRegisterMessage("Please refresh the page"));
      }
    } else {
      yield put(
        Actions.auth.setRegisterMessage("Fields are required and must be valid")
      );
      yield put(Actions.auth.setRegisterErrorInput(true));
    }
  } catch (e) {
    console.log(e);
    yield put(Actions.auth.setRegisterError(true));
    yield put(
      Actions.auth.setRegisterMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Failed to register"
      )
    );
  }

  yield put(Actions.auth.setRegisterLoading(false));
}

function* logout() {
  localStorage.removeItem(contants.TOKEN);
  localStorage.removeItem(contants.USER);
  localStorage.removeItem(contants.CHAT);
  yield delay(1000);
  window.location.replace("/login");
}

function* forgotPassword({ payload }: any) {
  yield put(Actions.auth.setForgotPasswordError(false));
  yield put(Actions.auth.setForgotPasswordMessage(""));
  yield put(Actions.auth.setForgotPasswordLoading(true));
  // console.log(payload)
  try {
    if (payload.token.length > 1) {
      const { data } = yield call(Api.passwordForgot, payload.email);

      yield put(Actions.auth.setForgotPasswordError(false));
      yield put(Actions.auth.setForgotPasswordMessage(data.message));
    } else {
      yield put(Actions.auth.setForgotPasswordError(true));
      yield put(
        Actions.auth.setForgotPasswordMessage("Please refresh the page")
      );
    }
  } catch (e) {
    yield put(Actions.auth.setForgotPasswordError(true));
    yield put(
      Actions.auth.setForgotPasswordMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Email or password invalid"
      )
    );
  }

  yield put(Actions.auth.setForgotPasswordLoading(false));
}

function* deleteAccount({ payload }: any) {
  yield put(Actions.auth.setDeleteAccountError(false));
  yield put(Actions.auth.setDeleteAccountMessage(""));
  yield put(Actions.auth.setDeleteAccountState(AppState.LOADING));

  try {
    if (payload.userEmail === payload.email) {
      yield call(Api.delete);

      localStorage.removeItem(contants.TOKEN);
      localStorage.removeItem(contants.USER);
      localStorage.removeItem(contants.CHAT);
      yield delay(1000);
      window.location.replace("/register");
    } else {
      yield put(Actions.auth.setDeleteAccountMessage("Email do not match"));
    }
  } catch (e) {
    yield put(
      Actions.auth.setDeleteAccountMessage(
        //@ts-ignore
        e.response?.data?.message ?? "Something happened"
      )
    );
  }

  yield put(Actions.auth.setDeleteAccountState(AppState.LOADED));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield all([
    takeLatest(types.LOGIN, login),
    takeLatest(types.REGISTER, register),
    takeLatest(types.LOGOUT, logout),
    takeLatest(types.FORGOT_PASSWORD, forgotPassword),
    takeLatest(types.DELETE_ACCOUNT, deleteAccount),
  ]);
}
