import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import {
  GroupResponse,
  MessageResponse,
} from "../../services/api.response.service";
import Actions from "../../redux/actions";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import MessageItem from "./MessageItem";
import MessageReply from "./MessageReply";
import Load from "../Load";

interface props {
  getGroup: GroupResponse;
  setOpenGroup: (trigger: boolean) => void;
}

const Chats = ({ getGroup, setOpenGroup }: props) => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const { pageState, data, message }: BaseState<Array<MessageResponse>> =
    state[StateKeys.CHAT_MESSAGES];
  const sendMessage: BaseState<MessageResponse> = state[StateKeys.SEND_MESSAGE];
  const [msg, setMsg] = useState("");
  const [replyMsg, setReplyMsg] = useState<MessageResponse>();
  const messageEnd = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (getGroup) {
      window.Pusher.unsubscribe(getGroup.conversation.channel_name);
      window.Pusher.subscribe(getGroup.conversation.channel_name).bind(
        getGroup.conversation.events[2],
        (e: any) => {
          if (e.message) {
            dispatch(
              Actions.mainSub.postPageArrayDataSuccess({
                stateKey: StateKeys.CHAT_MESSAGES,
                data: [e.message as MessageResponse],
              })
            );
          }
        }
      );

      dispatch(
        subMain.getPageArrayData({
          stateKey: StateKeys.CHAT_MESSAGES,
          apiEndpoint: ApiEndpoint.CHAT_MESSAGES(getGroup?.conversation.id, 20),
        })
      );

      dispatch(
        subMain.postPageData({
          stateKey: StateKeys.CONVERSATION_MARK_AS_READ,
          apiEndpoint: ApiEndpoint.CONVERSATION_MARK_AS_READ(
            getGroup?.conversation.id
          ),
          req: {},
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGroup]);

  useEffect(() => {
    messageEnd.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  useEffect(() => {
    if (sendMessage.pageState === AppState.LOADED) {
      dispatch(
        Actions.mainSub.postPageArrayDataSuccess({
          stateKey: StateKeys.CHAT_MESSAGES,
          data: [sendMessage.data],
        })
      );
      setMsg("");
      messageEnd.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sendMessage.pageState]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(getGroup?.conversation.id, "trigger");

    if (msg.trim().length === 0) {
      return;
    }

    const payload = {
      message: msg.trim(),
      type: "text",
      reply_to_id: replyMsg ? replyMsg.id : null,
      // if (data.replyingTo != null) 'reply_to_id': data.replyingTo!.id,
      // if (data.extra != null) ...{'data': data.extra!.toJson()},
    };

    dispatch(
      subMain.postPageData({
        stateKey: StateKeys.SEND_MESSAGE,
        apiEndpoint: ApiEndpoint.CHAT_MESSAGES(getGroup?.conversation.id),
        req: payload,
      })
    );
    setMsg("");
    messageEnd.current?.scrollIntoView({ behavior: "smooth" });
    setReplyMsg(null!);
  };

  const getMessages = () => {
    if (pageState !== AppState.LOADED) {
      return [];
    }

    return data
      .filter((e) => e.conversation_id === getGroup?.conversation.id)
      .sort((a, b) => a.id - b.id)
      .filter((e, i, s) => i === s.findIndex((t) => t.id === e.id));
  };

  return (
    <>
      {" "}
      <div className="row">
        {getGroup && (
          <div className="col-lg-12 position-relative">
            <div className="border-bottom sticky">
              <div className="d-flex justify-content-start align-items-center gap-3 pb-4 px-4">
                <div className="pt-3">
                  <img
                    src={getGroup?.image_url}
                    alt={getGroup?.title}
                    className="border-secondary border"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  />
                </div>

                <div
                  className="pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenGroup(true)}
                >
                  <h4 className="card-title text-truncate text-bold mb-0 pb-0">
                    {getGroup?.title}
                  </h4>
                  <small className="text-dark" style={{ fontSize: "11px" }}>
                    Click here for group info
                  </small>
                </div>
              </div>{" "}
            </div>
            <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
              <div className="chat-body p-3* mb-3 px-4">
                <div className="messages-content pb-5 mb-5">
                  {pageState === AppState.LOADED ? (
                    <>
                      {getMessages().map((e) => (
                        <MessageItem
                          message={e}
                          key={e.id}
                          hideUser={true}
                          setReplyMsg={setReplyMsg}
                        />
                      ))}
                      <div className="clearfix" ref={messageEnd} />{" "}
                    </>
                  ) : (
                    <Load
                      message="Loading messages"
                      noCard={false}
                      noMarginTop={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="chat-bottom dark-bg p-3* shadow-none theme-dark-bg"
              style={{ width: "98%" }}
            >
              <form
                onSubmit={handleSubmit}
                className="chat-form row d-flex* justify-content-between* align-items-center"
              >
                <div className={`col-1 ${replyMsg && "pt-5 mt-4"}`}>
                  <button className="bg-grey float-left">
                    <i className="ti-microphone text-grey-600" />
                  </button>{" "}
                </div>
                {/* <div className="form-group"> */}
                {/*<input placeholder="Send a message"
                                           className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600`}
                                           value={msg} onChange={(e)=> setMsg(e.target.value)}
                                    />*/}
                <div className="col-10 position-relative">
                  <MessageReply
                    setReplyMsg={setReplyMsg}
                    replyMsg={replyMsg!}
                  />
                  <textarea
                    placeholder="Send a message"
                    className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600`}
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    rows={5}
                  />{" "}
                </div>
                {/* </div> */}{" "}
                <div className={`col-1 ${replyMsg && "pt-5 mt-4"}`}>
                  <button
                    type="submit"
                    className="bg-current"
                    // onClick={handleSubmit}
                  >
                    <i className="ti-arrow-right text-white" />
                  </button>{" "}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Chats;
