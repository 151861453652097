import firebase from "firebase/compat/app";
import "firebase/compat/remote-config"

const firebaseConfig = {
  apiKey: "AIzaSyBrcW1YatktdP2JbR25UxnCeDosZhjXDbA",
  authDomain: "power-to-girls.firebaseapp.com",
  databaseURL: "https://power-to-girls.firebaseio.com",
  projectId: "power-to-girls",
  storageBucket: "power-to-girls.appspot.com",
  messagingSenderId: "735334414542",
  appId: "1:735334414542:web:178921bdfb2060a095389f",
  measurementId: "G-BW22QT744N"
};

const app = firebase.initializeApp(firebaseConfig);
export const remoteConfig = app.remoteConfig();
remoteConfig.settings = {
  fetchTimeoutMillis: 6000,
  minimumFetchIntervalMillis: 3600000
}

remoteConfig.fetchAndActivate().then(() => {})
  .catch((err) => {
    console.error(err);
  });



