import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import { Link } from "react-router-dom";
import { AppState } from "../../services/app.service";
import NoData from "../../components/NoData";
import subMain from "../../redux/actions/mainn.action";
import { StateKeys } from "../../redux/states/mainn.state";
import { ApiEndpoint } from "../../services/api.endpoint.service";

const scheduleZone = new Date().toTimeString().slice(9, 17);
function DeleteProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);
  const { state, message, error } = useSelector(
    (state: IRootState) => state.auth.deleteAccount
  );

  const [email, setEmail] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      userEmail: user!.email,
      email,
    };

    // console.log(data);
    // dispatch(
    //   subMain.postPageData({
    //     stateKey: StateKeys.STORY,
    //     apiEndpoint: ApiEndpoint.DELETE_ACCOUNT,
    //     // req: data,
    //   })
    // );
    dispatch(Actions.auth.deleteAccount(data));
  };

  return (
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 ">
              <div className="card-body p-4 w-100 bg-danger border-0 d-flex rounded-3">
                <Link
                  to={`/profile/${user?.id}`}
                  className="d-inline-block mt-2"
                >
                  <i className="ti-arrow-left font-sm text-white" />
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                  Delete Account
                </h4>
              </div>

              <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                {error && <NoData message={message} error={true} />}
                {message.length > 0 && !error && (
                  <NoData message={message} success={true} />
                )}
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          disabled={state === AppState.LOADING}
                        />
                      </div>
                      <small>
                        <b>NB:</b> This action cannot be unchanged
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      {state === AppState.LOADING ? (
                        <Load message="Submitting" />
                      ) : (
                        <button
                          type="submit"
                          className="bg-danger text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteProfile;
