import rootState from "../states";
import { types } from "../actions/mainn.action";
import { AnyAction } from "redux";
import { StateKeys } from "../states/mainn.state";
import { NoStroller } from "styled-icons/material-outlined";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = rootState.mainSub,
  { type, payload }: AnyAction
) {
  switch (type) {
    case types.PAGE_STATE:
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          pageState: payload.data,
        },
      };

    case types.POST_PAGE_STATE:
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          postPageState: payload.data,
        },
      };

    case types.POST_PAGE_STATE_MESSAGE:
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          message: payload.data,
        },
      };

    case types.PAGE_MESSAGE:
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          message: payload.data,
        },
      };

    case types.PAGE_ARRAY_DATA_SUCCESS:
      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          data: payload.clear
            ? payload.data
            : [...state[payload.stateKey].data, ...payload.data],
        },
      };

    case types.PAGE_OBJECT_DATA_SUCCESS:

      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          data: payload.clear
            ? payload.append
              ? [payload.data]
              : [
                  ...state[payload.stateKey].data.map((item: any) =>
                    item.id === payload.data.id ? payload.data : item
                  ),
                ]
            : payload.append
            ? payload.append === "start"
              ? [payload.data, ...state[payload.stateKey].data]
              : [...state[payload.stateKey].data, payload.data]
            : { ...state[payload.stateKey].data, ...payload.data },
        },
      };

    case types.DELETE_PAGE_OBJECT_DATA_SUCCESS:

      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          data: payload.objectID //check if payload has object id it does
            ? //trigger delete
              [
                ...state[payload.stateKey].data.filter(
                  (item: any) => item.id !== payload.objectID
                ),
              ]
            : { ...state[payload.stateKey].data, ...payload.data },
        },
      };

    case types.PAGINATED_PAGE_OBJECT_SUCCESS:
      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          obj: payload.clear
            ? payload.data
            : { ...state[payload.stateKey].data, ...payload.data },
        },
      };

    case types.PAGINATED_PAGE_DATA_SUCCESS:
      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          data: payload.clear
            ? payload.data
            : [...state[payload.stateKey].data, ...payload.data],
        },
      };

    case types.PAGINATED_PAGE_HAS_MORE:
      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          hasMore: payload.data,
        },
      };

    case types.PAGINATED_PAGE_CURRENT_PAGE_NUMBER:
      // @ts-ignore
      return {
        ...state,
        [payload.stateKey]: {
          ...state[payload.stateKey],
          currentPage: payload.data,
        },
      };

    case types.DATA_PERSIST:
      // @ts-ignore
      return {
        ...state,
        [StateKeys.DATA_PERSIST]: {
          ...state[StateKeys.DATA_PERSIST], // @ts-ignore

          [payload.stateKey]: state[StateKeys.DATA_PERSIST][payload.stateKey] // @ts-ignore
            ? [...state[StateKeys.DATA_PERSIST][payload.stateKey], payload.data]
            : [payload.data],
        },
      };

    case types.DATA_PERSIST_WITH_KEY:
      // @ts-ignore
      return {
        ...state,
        [StateKeys.DATA_PERSIST]: {
          ...state[StateKeys.DATA_PERSIST],
          data: {
            ...state[StateKeys.DATA_PERSIST].data,
            [payload.stateKey]: state[payload.data],
          },
        },
      };

    default:
      return { ...state };
  }
}
