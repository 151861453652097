import {
  UserProfileResponse,
  StoryResponse,
  UserResponseFriendRequest,
  AnnouncementResponse,
  ResourceResponse,
  ConnectMentorResponse,
  InterestResponse,
  GroupResponse,
} from "../../services/api.response.service";
import {
  DeleteRequest,
  ReportRequest,
  StoryRequest,
  UserEditRequest,
  UserVerifyRequest,
} from "../../services/api.request.service";

export const types = {
  //Home
  HOME_LOADER: "HOME_LOADER",
  HOME_ERROR: "HOME_ERROR",
  HOME_MESSAGE: "HOME_MESSAGE",

  //Stories
  HOME_STORIES_LOADER: "HOME_STORIES_LOADER",
  HOME_STORIES_SUCCESS: "HOME_STORIES_SUCCESS",
  HOME_GET_STORIES: "HOME_GET_STORIES",
  HOME_STORIES_ERROR: "HOME_STORIES_ERROR",
  HOME_STORIES_LOADED: "HOME_STORIES_LOADED",
  HOME_LOAD_MORE_STORIES: "HOME_LOAD_MORE_STORIES",
  HOME_HAS_MORE_STORIES: "HOME_HAS_MORE_STORIES",
  HOME_STORIES_LOADED_PAGE: "HOME_STORIES_LOADED_PAGE",

  //Featured Mentors
  HOME_FEATURED_MENTORS_LOADER: "HOME_FEATURED_MENTORS_LOADER",
  HOME_FEATURED_MENTORS_SUCCESS: "HOME_FEATURED_MENTORS_SUCCESS",
  HOME_GET_FEATURED_MENTORS: "HOME_GET_FEATURED_MENTORS",
  HOME_FEATURED_MENTORS_ERROR: "HOME_FEATURED_MENTORS_ERROR",
  HOME_FEATURED_MENTORS_LOADED: "HOME_FEATURED_MENTORS_LOADED",
  HOME_LOAD_MORE_FEATURED_MENTORS: "HOME_LOAD_MORE_FEATURED_MENTORS",
  HOME_HAS_MORE_FEATURED_MENTORS: "HOME_HAS_MORE_FEATURED_MENTORS",
  HOME_FEATURED_MENTORS_LOADED_PAGE: "HOME_FEATURED_MENTORS_LOADED_PAGE",

  //Requested Connections
  HOME_FRIEND_REQUESTS_LOADER: "HOME_FRIEND_REQUESTS_LOADER",
  HOME_FRIEND_REQUESTS_SUCCESS: "HOME_FRIEND_REQUESTS_SUCCESS",
  HOME_GET_FRIEND_REQUESTS: "HOME_GET_FRIEND_REQUESTS",
  HOME_FRIEND_REQUESTS_ERROR: "HOME_FRIEND_REQUESTS_ERROR",
  HOME_FRIEND_REQUESTS_LOADED: "HOME_FRIEND_REQUESTS_LOADED",
  HOME_LOAD_MORE_FRIEND_REQUESTS: "HOME_LOAD_MORE_FRIEND_REQUESTS",
  HOME_HAS_MORE_FRIEND_REQUESTS: "HOME_HAS_MORE_FRIEND_REQUESTS",
  HOME_FRIEND_REQUESTS_LOADED_PAGE: "HOME_FRIEND_REQUESTS_LOADED_PAGE",
  HOME_FRIEND_REQUESTS_ACCEPT: "HOME_FRIEND_REQUESTS_ACCEPT",
  HOME_FRIEND_REQUESTS_ACCEPT_SUCCESS: "HOME_FRIEND_REQUESTS_ACCEPT_SUCCESS",
  HOME_FRIEND_REQUESTS_DECLINE: "HOME_FRIEND_REQUESTS_DECLINE",
  HOME_FRIEND_REQUESTS_DECLINE_SUCCESS: "HOME_FRIEND_REQUESTS_DECLINE_SUCCESS",

  //Suggested Connections
  HOME_SUGGESTED_FRIENDS_LOADER: "HOME_SUGGESTED_FRIENDS_LOADER",
  HOME_SUGGESTED_FRIENDS_SUCCESS: "HOME_SUGGESTED_FRIENDS_SUCCESS",
  HOME_GET_SUGGESTED_FRIENDS: "HOME_GET_SUGGESTED_FRIENDS",
  HOME_SUGGESTED_FRIENDS_ERROR: "HOME_SUGGESTED_FRIENDS_ERROR",
  HOME_SUGGESTED_FRIENDS_LOADED: "HOME_SUGGESTED_FRIENDS_LOADED",
  HOME_LOAD_MORE_SUGGESTED_FRIENDS: "HOME_LOAD_MORE_SUGGESTED_FRIENDS",
  HOME_HAS_MORE_SUGGESTED_FRIENDS: "HOME_HAS_MORE_SUGGESTED_FRIENDS",
  HOME_SUGGESTED_FRIENDS_LOADED_PAGE: "HOME_SUGGESTED_FRIENDS_LOADED_PAGE",
  HOME_SUGGESTED_FRIENDS_MAKE_REQUEST: "HOME_SUGGESTED_FRIENDS_MAKE_REQUEST",
  HOME_SUGGESTED_FRIENDS_ADD_REQUEST: "HOME_SUGGESTED_FRIENDS_ADD_REQUEST",

  //Connections
  FRIENDS_LOADER: "FRIENDS_LOADER",
  FRIENDS_SUCCESS: "FRIENDS_SUCCESS",
  GET_FRIENDS: "GET_FRIENDS",
  FRIENDS_ERROR: "FRIENDS_ERROR",
  FRIENDS_LOADED: "FRIENDS_LOADED",
  LOAD_MORE_FRIENDS: "LOAD_MORE_FRIENDS",
  HAS_MORE_FRIENDS: "HAS_MORE_FRIENDS",
  FRIENDS_LOADED_PAGE: "FRIENDS_LOADED_PAGE",
  FRIENDS_MESSAGE: "FRIENDS_MESSAGE",

  //Journal
  JOURNAL_PUBLIC_LOADER: "JOURNAL_PUBLIC_LOADER",
  JOURNAL_PUBLIC_SUCCESS: "JOURNAL_PUBLIC_SUCCESS",
  GET_JOURNAL_PUBLIC: "GET_JOURNAL_PUBLIC",
  JOURNAL_PUBLIC_ERROR: "JOURNAL_PUBLIC_ERROR",
  JOURNAL_PUBLIC_LOADED: "JOURNAL_PUBLIC_LOADED",
  LOAD_MORE_JOURNAL_PUBLIC: "LOAD_MORE_JOURNAL_PUBLIC",
  HAS_MORE_JOURNAL_PUBLIC: "HAS_MORE_JOURNAL_PUBLIC",
  JOURNAL_PUBLIC_LOADED_PAGE: "JOURNAL_PUBLIC_LOADED_PAGE",
  JOURNAL_PUBLIC_MESSAGE: "JOURNAL_PUBLIC_MESSAGE",

  JOURNAL_PRIVATE_LOADER: "JOURNAL_PRIVATE_LOADER",
  JOURNAL_PRIVATE_SUCCESS: "JOURNAL_PRIVATE_SUCCESS",
  GET_JOURNAL_PRIVATE: "GET_JOURNAL_PRIVATE",
  JOURNAL_PRIVATE_ERROR: "JOURNAL_PRIVATE_ERROR",
  JOURNAL_PRIVATE_LOADED: "JOURNAL_PRIVATE_LOADED",
  LOAD_MORE_JOURNAL_PRIVATE: "LOAD_MORE_JOURNAL_PRIVATE",
  HAS_MORE_JOURNAL_PRIVATE: "HAS_MORE_JOURNAL_PRIVATE",
  JOURNAL_PRIVATE_LOADED_PAGE: "JOURNAL_PRIVATE_LOADED_PAGE",
  JOURNAL_PRIVATE_MESSAGE: "JOURNAL_PRIVATE_MESSAGE",

  //Announcements
  ANNOUNCEMENTS_LOADER: "ANNOUNCEMENTS_LOADER",
  ANNOUNCEMENTS_SUCCESS: "ANNOUNCEMENTS_SUCCESS",
  GET_ANNOUNCEMENTS: "GET_ANNOUNCEMENTS",
  ANNOUNCEMENTS_ERROR: "ANNOUNCEMENTS_ERROR",
  ANNOUNCEMENTS_LOADED: "ANNOUNCEMENTS_LOADED",
  LOAD_MORE_ANNOUNCEMENTS: "LOAD_MORE_ANNOUNCEMENTS",
  HAS_MORE_ANNOUNCEMENTS: "HAS_MORE_ANNOUNCEMENTS",
  ANNOUNCEMENTS_LOADED_PAGE: "ANNOUNCEMENTS_LOADED_PAGE",
  ANNOUNCEMENTS_MESSAGE: "ANNOUNCEMENTS_MESSAGE",

  //Resources
  RESOURCES_IMAGES_LOADER: "RESOURCES_IMAGES_LOADER",
  RESOURCES_IMAGES_MESSAGE: "RESOURCES_IMAGES_MESSAGE",
  RESOURCES_IMAGES_SUCCESS: "RESOURCES_IMAGES_SUCCESS",
  GET_RESOURCES_IMAGES: "GET_RESOURCES_IMAGES",
  RESOURCES_IMAGES_ERROR: "RESOURCES_IMAGES_ERROR",
  RESOURCES_IMAGES_LOADED: "RESOURCES_IMAGES_LOADED",
  LOAD_MORE_RESOURCES_IMAGES: "LOAD_MORE_RESOURCES_IMAGES",
  HAS_MORE_RESOURCES_IMAGES: "HAS_MORE_RESOURCES_IMAGES",
  RESOURCES_IMAGES_LOADED_PAGE: "RESOURCES_IMAGES_LOADED_PAGE",

  RESOURCES_AUDIOS_LOADER: "RESOURCES_AUDIOS_LOADER",
  RESOURCES_AUDIOS_MESSAGE: "RESOURCES_AUDIOS_MESSAGE",
  RESOURCES_AUDIOS_SUCCESS: "RESOURCES_AUDIOS_SUCCESS",
  GET_RESOURCES_AUDIOS: "GET_RESOURCES_AUDIOS",
  RESOURCES_AUDIOS_ERROR: "RESOURCES_AUDIOS_ERROR",
  RESOURCES_AUDIOS_LOADED: "RESOURCES_AUDIOS_LOADED",
  LOAD_MORE_RESOURCES_AUDIOS: "LOAD_MORE_RESOURCES_AUDIOS",
  HAS_MORE_RESOURCES_AUDIOS: "HAS_MORE_RESOURCES_AUDIOS",
  RESOURCES_AUDIOS_LOADED_PAGE: "RESOURCES_AUDIOS_LOADED_PAGE",

  RESOURCES_VIDEOS_LOADER: "RESOURCES_VIDEOS_LOADER",
  RESOURCES_VIDEOS_MESSAGE: "RESOURCES_VIDEOS_MESSAGE",
  RESOURCES_VIDEOS_SUCCESS: "RESOURCES_VIDEOS_SUCCESS",
  GET_RESOURCES_VIDEOS: "GET_RESOURCES_VIDEOS",
  RESOURCES_VIDEOS_ERROR: "RESOURCES_VIDEOS_ERROR",
  RESOURCES_VIDEOS_LOADED: "RESOURCES_VIDEOS_LOADED",
  LOAD_MORE_RESOURCES_VIDEOS: "LOAD_MORE_RESOURCES_VIDEOS",
  HAS_MORE_RESOURCES_VIDEOS: "HAS_MORE_RESOURCES_VIDEOS",
  RESOURCES_VIDEOS_LOADED_PAGE: "RESOURCES_VIDEOS_LOADED_PAGE",

  RESOURCES_DOCUMENTS_LOADER: "RESOURCES_DOCUMENTS_LOADER",
  RESOURCES_DOCUMENTS_MESSAGE: "RESOURCES_DOCUMENTS_MESSAGE",
  RESOURCES_DOCUMENTS_SUCCESS: "RESOURCES_DOCUMENTS_SUCCESS",
  GET_RESOURCES_DOCUMENTS: "GET_RESOURCES_DOCUMENTS",
  RESOURCES_DOCUMENTS_ERROR: "RESOURCES_DOCUMENTS_ERROR",
  RESOURCES_DOCUMENTS_LOADED: "RESOURCES_DOCUMENTS_LOADED",
  LOAD_MORE_RESOURCES_DOCUMENTS: "LOAD_MORE_RESOURCES_DOCUMENTS",
  HAS_MORE_RESOURCES_DOCUMENTS: "HAS_MORE_RESOURCES_DOCUMENTS",
  RESOURCES_DOCUMENTS_LOADED_PAGE: "RESOURCES_DOCUMENTS_LOADED_PAGE",

  RESOURCES_URLS_LOADER: "RESOURCES_URLS_LOADER",
  RESOURCES_URLS_MESSAGE: "RESOURCES_URLS_MESSAGE",
  RESOURCES_URLS_SUCCESS: "RESOURCES_URLS_SUCCESS",
  GET_RESOURCES_URLS: "GET_RESOURCES_URLS",
  RESOURCES_URLS_ERROR: "RESOURCES_URLS_ERROR",
  RESOURCES_URLS_LOADED: "RESOURCES_URLS_LOADED",
  LOAD_MORE_RESOURCES_URLS: "LOAD_MORE_RESOURCES_URLS",
  HAS_MORE_RESOURCES_URLS: "HAS_MORE_RESOURCES_URLS",
  RESOURCES_URLS_LOADED_PAGE: "RESOURCES_URLS_LOADED_PAGE",

  //Mentors
  MENTORS_LOADER: "MENTORS_LOADER",
  MENTORS_SUCCESS: "MENTORS_SUCCESS",
  GET_MENTORS: "GET_MENTORS",
  MENTORS_ERROR: "MENTORS_ERROR",
  MENTORS_LOADED: "MENTORS_LOADED",
  LOAD_MORE_MENTORS: "LOAD_MORE_MENTORS",
  HAS_MORE_MENTORS: "HAS_MORE_MENTORS",
  MENTORS_LOADED_PAGE: "MENTORS_LOADED_PAGE",
  MENTORS_MESSAGE: "MENTORS_MESSAGE",

  //Profile
  PROFILE_LOADER: "PROFILE_LOADER",
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  GET_PROFILE: "GET_PROFILE",
  PROFILE_ERROR: "PROFILE_ERROR",
  PROFILE_LOADED: "PROFILE_LOADED",
  PROFILE_MESSAGE: "PROFILE_MESSAGE",
  EDIT_PROFILE: "EDIT_PROFILE",
  VERIFY_PROFILE: "VERIFY_PROFILE",
  REPORT_USER: "REPORT_USER",
  BLOCK_USER: "BLOCK_USER",
  UNBLOCK_USER: "UNBLOCK_USER",
  UNBLOCK_USER_SUCCESS: "UNBLOCK_USER_SUCCESS",
  DELETE_USER: "DELETE_USER",

  PROFILE_JOURNAL_PUBLIC_LOADER: "PROFILE_JOURNAL_PUBLIC_LOADER",
  PROFILE_JOURNAL_PUBLIC_SUCCESS: "PROFILE_JOURNAL_PUBLIC_SUCCESS",
  GET_PROFILE_JOURNAL_PUBLIC: "GET_PROFILE_JOURNAL_PUBLIC",
  PROFILE_JOURNAL_PUBLIC_ERROR: "PROFILE_JOURNAL_PUBLIC_ERROR",
  PROFILE_JOURNAL_PUBLIC_LOADED: "PROFILE_JOURNAL_PUBLIC_LOADED",
  LOAD_MORE_PROFILE_JOURNAL_PUBLIC: "LOAD_MORE_PROFILE_JOURNAL_PUBLIC",
  HAS_MORE_PROFILE_JOURNAL_PUBLIC: "HAS_MORE_PROFILE_JOURNAL_PUBLIC",
  PROFILE_JOURNAL_PUBLIC_LOADED_PAGE: "PROFILE_JOURNAL_PUBLIC_LOADED_PAGE",
  PROFILE_JOURNAL_PUBLIC_MESSAGE: "PROFILE_JOURNAL_PUBLIC_MESSAGE",

  PROFILE_JOURNAL_PRIVATE_LOADER: "PROFILE_JOURNAL_PRIVATE_LOADER",
  PROFILE_JOURNAL_PRIVATE_SUCCESS: "PROFILE_JOURNAL_PRIVATE_SUCCESS",
  GET_PROFILE_JOURNAL_PRIVATE: "GET_PROFILE_JOURNAL_PRIVATE",
  PROFILE_JOURNAL_PRIVATE_ERROR: "PROFILE_JOURNAL_PRIVATE_ERROR",
  PROFILE_JOURNAL_PRIVATE_LOADED: "PROFILE_JOURNAL_PRIVATE_LOADED",
  LOAD_MORE_PROFILE_JOURNAL_PRIVATE: "LOAD_MORE_PROFILE_JOURNAL_PRIVATE",
  HAS_MORE_PROFILE_JOURNAL_PRIVATE: "HAS_MORE_PROFILE_JOURNAL_PRIVATE",
  PROFILE_JOURNAL_PRIVATE_LOADED_PAGE: "PROFILE_JOURNAL_PRIVATE_LOADED_PAGE",
  PROFILE_JOURNAL_PRIVATE_MESSAGE: "PROFILE_JOURNAL_PRIVATE_MESSAGE",

  PROFILE_INTEREST_LOADER: "PROFILE_INTEREST_LOADER",
  PROFILE_INTEREST_SUCCESS: "PROFILE_INTEREST_SUCCESS",
  GET_PROFILE_INTEREST: "GET_PROFILE_INTEREST",
  PROFILE_INTEREST_ERROR: "PROFILE_INTEREST_ERROR",
  PROFILE_INTEREST_LOADED: "PROFILE_INTEREST_LOADED",
  PROFILE_INTEREST_MESSAGE: "PROFILE_INTEREST_MESSAGE",
  UPDATE_PROFILE_INTEREST: "UPDATE_PROFILE_INTEREST",
  UPDATE_PROFILE_INTEREST_SUCCESS: "UPDATE_PROFILE_INTEREST_SUCCESS",

  //Mentor Profile
  MENTOR_PROFILE_LOADER: "MENTOR_PROFILE_LOADER",
  MENTOR_PROFILE_SUCCESS: "MENTOR_PROFILE_SUCCESS",
  GET_MENTOR_PROFILE: "GET_MENTOR_PROFILE",
  MENTOR_PROFILE_ERROR: "MENTOR_PROFILE_ERROR",
  MENTOR_PROFILE_LOADED: "MENTOR_PROFILE_LOADED",
  MENTOR_PROFILE_MESSAGE: "MENTOR_PROFILE_MESSAGE",
  MENTOR_CONNECTION: "MENTOR_CONNECTION",
  MENTOR_CONNECTION_SUCCESS: "MENTOR_CONNECTION_SUCCESS",
  EDIT_MENTOR_PROFILE: "EDIT_MENTOR_PROFILE",
  VERIFY_MENTOR_PROFILE: "VERIFY_MENTOR_PROFILE",

  MENTOR_PROFILE_INTERESTS_LOADER: "MENTOR_PROFILE_INTERESTS_LOADER",
  MENTOR_PROFILE_INTERESTS_SUCCESS: "MENTOR_PROFILE_INTERESTS_SUCCESS",
  GET_MENTOR_PROFILE_INTERESTS: "GET_MENTOR_PROFILE_INTERESTS",
  MENTOR_PROFILE_INTERESTS_ERROR: "MENTOR_PROFILE_INTERESTS_ERROR",
  MENTOR_PROFILE_INTERESTS_LOADED: "MENTOR_PROFILE_INTERESTS_LOADED",
  MENTOR_PROFILE_INTERESTS_MESSAGE: "MENTOR_PROFILE_INTERESTS_MESSAGE",
  UPDATE_MENTOR_PROFILE_INTERESTS: "UPDATE_MENTOR_PROFILE_INTERESTS",
  UPDATE_MENTOR_PROFILE_INTERESTS_SUCCESS:
    "UPDATE_MENTOR_PROFILE_INTERESTS_SUCCESS",

  //Catergories
  CATEGORIES_LOADER: "CATEGORIES_LOADER",
  CATEGORIES_SUCCESS: "CATEGORIES_SUCCESS",
  GET_CATEGORIES: "GET_CATEGORIES",
  CATEGORIES_ERROR: "CATEGORIES_ERROR",
  CATEGORIES_LOADED: "CATEGORIES_LOADED",
  CATEGORIES_MESSAGE: "CATEGORIES_MESSAGE",
  LOAD_MORE_CATEGORIES: "LOAD_MORE_CATEGORIES",
  HAS_MORE_CATEGORIES: "HAS_MORE_CATEGORIES",
  CATEGORIES_LOADED_PAGE: "CATEGORIES_LOADED_PAGE",

  //Story
  STORY_LOADER: "STORY_LOADER",
  STORY_SUCCESS: "STORY_SUCCESS",
  GET_STORY: "GET_STORY",
  STORY_ERROR: "STORY_ERROR",
  STORY_LOADED: "STORY_LOADED",
  STORY_MESSAGE: "STORY_MESSAGE",

  ADD_STORY: "ADD_STORY",
  ADD_STORY_SUCCESS: "ADD_STORY_SUCCESS",

  EDIT_STORY: "EDIT_STORY",
  EDIT_STORY_SUCCESS: "EDIT_STORY_SUCCESS",

  DELETE_STORY: "DELETE_STORY",
  DELETE_STORY_SUCCESS: "DELETE_STORY_SUCCESS",

  REACT_STORY: "REACT_STORY",
  REACT_STORY_SUCCESS: "REACT_STORY_SUCCESS",

  //Groups
  GROUPS_LOADER: "GROUPS_LOADER",
  GROUPS_SUCCESS: "GROUPS_SUCCESS",
  GET_GROUPS: "GET_GROUPS",
  GROUPS_ERROR: "GROUPS_ERROR",
  GROUPS_LOADED: "GROUPS_LOADED",
  LOAD_MORE_GROUPS: "LOAD_MORE_GROUPS",
  HAS_MORE_GROUPS: "HAS_MORE_GROUPS",
  GROUPS_LOADED_PAGE: "GROUPS_LOADED_PAGE",
  GROUPS_MESSAGE: "GROUPS_MESSAGE",

  //Interests
  INTERESTS_LOADER: "INTERESTS_LOADER",
  INTERESTS_SUCCESS: "INTERESTS_SUCCESS",
  GET_INTERESTS: "GET_INTERESTS",
  INTERESTS_ERROR: "INTERESTS_ERROR",
  INTERESTS_LOADED: "INTERESTS_LOADED",
  INTERESTS_MESSAGE: "INTERESTS_MESSAGE",

  //Girls
  GIRLS_LOADER: "GIRLS_LOADER",
  GIRLS_SUCCESS: "GIRLS_SUCCESS",
  GET_GIRLS: "GET_GIRLS",
  GIRLS_ERROR: "GIRLS_ERROR",
  GIRLS_LOADED: "GIRLS_LOADED",
  LOAD_MORE_GIRLS: "LOAD_MORE_GIRLS",
  HAS_MORE_GIRLS: "HAS_MORE_GIRLS",
  GIRLS_LOADED_PAGE: "GIRLS_LOADED_PAGE",
  GIRLS_MESSAGE: "GIRLS_MESSAGE",
};

const Main = {
  //Home
  setHomeLoader: (payload: boolean) => ({ type: types.HOME_LOADER, payload }),
  setHomeError: (payload: boolean) => ({ type: types.HOME_ERROR, payload }),
  setHomeMessage: (payload: string) => ({ type: types.HOME_MESSAGE, payload }),

  //Stories
  getHomeStories: (page: number, stories: Array<StoryResponse>) => ({
    type: types.HOME_GET_STORIES,
    payload: { page, stories },
  }),
  setHomeStoriesLoader: (payload: boolean) => ({
    type: types.HOME_STORIES_LOADER,
    payload,
  }),
  setHomeStoriesSuccess: (payload: Array<StoryResponse>) => ({
    type: types.HOME_STORIES_SUCCESS,
    payload,
  }),
  setHomeStoriesError: (payload: boolean) => ({
    type: types.HOME_STORIES_ERROR,
    payload,
  }),
  setHomeStoriesLoaded: (payload: boolean) => ({
    type: types.HOME_STORIES_LOADED,
    payload,
  }),
  setHomeLoadMoreStories: (payload: boolean) => ({
    type: types.HOME_LOAD_MORE_STORIES,
    payload,
  }),
  setHomeHasMoreStories: (payload: boolean) => ({
    type: types.HOME_HAS_MORE_STORIES,
    payload,
  }),
  setHomeStoriesLoadedPage: (payload: number) => ({
    type: types.HOME_STORIES_LOADED_PAGE,
    payload,
  }),

  //Featured Mentors
  getHomeFeatureMentors: (
    page: number,
    featuredMentors: Array<UserProfileResponse>
  ) => ({
    type: types.HOME_GET_FEATURED_MENTORS,
    payload: { page, featuredMentors },
  }),
  setHomeFeatureMentorsLoader: (payload: boolean) => ({
    type: types.HOME_FEATURED_MENTORS_LOADER,
    payload,
  }),
  setHomeFeatureMentorsSuccess: (payload: Array<UserProfileResponse>) => ({
    type: types.HOME_FEATURED_MENTORS_SUCCESS,
    payload,
  }),
  setHomeFeatureMentorsError: (payload: boolean) => ({
    type: types.HOME_FEATURED_MENTORS_ERROR,
    payload,
  }),
  setHomeFeatureMentorsLoaded: (payload: boolean) => ({
    type: types.HOME_FEATURED_MENTORS_LOADED,
    payload,
  }),
  setHomeLoadMoreFeatureMentors: (payload: boolean) => ({
    type: types.HOME_LOAD_MORE_FEATURED_MENTORS,
    payload,
  }),
  setHomeHasMoreFeatureMentors: (payload: boolean) => ({
    type: types.HOME_HAS_MORE_FEATURED_MENTORS,
    payload,
  }),
  setHomeFeatureMentorsLoadedPage: (payload: number) => ({
    type: types.HOME_FEATURED_MENTORS_LOADED_PAGE,
    payload,
  }),

  //Requested Connections
  getHomeFriendRequests: (
    page: number,
    friendRequests: Array<UserResponseFriendRequest>
  ) => ({
    type: types.HOME_GET_FRIEND_REQUESTS,
    payload: { page, friendRequests },
  }),
  setHomeFriendRequestsLoader: (payload: boolean) => ({
    type: types.HOME_FRIEND_REQUESTS_LOADER,
    payload,
  }),
  setHomeFriendRequestsSuccess: (
    payload: Array<UserResponseFriendRequest>
  ) => ({ type: types.HOME_FRIEND_REQUESTS_SUCCESS, payload }),
  setHomeFriendRequestsError: (payload: boolean) => ({
    type: types.HOME_FRIEND_REQUESTS_ERROR,
    payload,
  }),
  setHomeFriendRequestsLoaded: (payload: boolean) => ({
    type: types.HOME_FRIEND_REQUESTS_LOADED,
    payload,
  }),
  setHomeLoadMoreFriendRequests: (payload: boolean) => ({
    type: types.HOME_LOAD_MORE_FRIEND_REQUESTS,
    payload,
  }),
  setHomeHasMoreFriendRequests: (payload: boolean) => ({
    type: types.HOME_HAS_MORE_FRIEND_REQUESTS,
    payload,
  }),
  setHomeFriendRequestsLoadedPage: (payload: number) => ({
    type: types.HOME_FRIEND_REQUESTS_LOADED_PAGE,
    payload,
  }),
  getHomeFriendRequestsAccept: (payload: string) => ({
    type: types.HOME_FRIEND_REQUESTS_ACCEPT,
    payload,
  }),
  getHomeFriendRequestsAcceptSuccess: (payload: UserResponseFriendRequest) => ({
    type: types.HOME_FRIEND_REQUESTS_ACCEPT_SUCCESS,
    payload,
  }),
  getHomeFriendRequestsDecline: (payload: string) => ({
    type: types.HOME_FRIEND_REQUESTS_DECLINE,
    payload,
  }),
  getHomeFriendRequestsDeclineSuccess: (
    payload: UserResponseFriendRequest
  ) => ({ type: types.HOME_FRIEND_REQUESTS_DECLINE_SUCCESS, payload }),

  //Suggested Connections
  getHomeSuggestedFriends: (
    page: number,
    suggestedFriends: Array<UserProfileResponse>
  ) => ({
    type: types.HOME_GET_SUGGESTED_FRIENDS,
    payload: { page, suggestedFriends },
  }),
  setHomeSuggestedFriendsLoader: (payload: boolean) => ({
    type: types.HOME_SUGGESTED_FRIENDS_LOADER,
    payload,
  }),
  setHomeSuggestedFriendsSuccess: (payload: Array<UserProfileResponse>) => ({
    type: types.HOME_SUGGESTED_FRIENDS_SUCCESS,
    payload,
  }),
  setHomeSuggestedFriendsError: (payload: boolean) => ({
    type: types.HOME_SUGGESTED_FRIENDS_ERROR,
    payload,
  }),
  setHomeSuggestedFriendsLoaded: (payload: boolean) => ({
    type: types.HOME_SUGGESTED_FRIENDS_LOADED,
    payload,
  }),
  setHomeLoadMoreSuggestedFriends: (payload: boolean) => ({
    type: types.HOME_LOAD_MORE_SUGGESTED_FRIENDS,
    payload,
  }),
  setHomeHasMoreSuggestedFriends: (payload: boolean) => ({
    type: types.HOME_HAS_MORE_SUGGESTED_FRIENDS,
    payload,
  }),
  setHomeSuggestedFriendsLoadedPage: (payload: number) => ({
    type: types.HOME_SUGGESTED_FRIENDS_LOADED_PAGE,
    payload,
  }),
  postHomeSuggestedFriendsMakeRequest: (payload: string) => ({
    type: types.HOME_SUGGESTED_FRIENDS_MAKE_REQUEST,
    payload,
  }),
  setHomeSuggestedFriendsAddRequest: (payload: string) => ({
    type: types.HOME_SUGGESTED_FRIENDS_ADD_REQUEST,
    payload,
  }),

  //Connections
  getFriends: (page: number, friends: Array<UserResponseFriendRequest>) => ({
    type: types.GET_FRIENDS,
    payload: { page, friends },
  }),
  setFriendsLoader: (payload: boolean) => ({
    type: types.FRIENDS_LOADER,
    payload,
  }),
  setFriendsSuccess: (payload: Array<UserResponseFriendRequest>) => ({
    type: types.FRIENDS_SUCCESS,
    payload,
  }),
  setFriendsError: (payload: boolean) => ({
    type: types.FRIENDS_ERROR,
    payload,
  }),
  setFriendsLoaded: (payload: boolean) => ({
    type: types.FRIENDS_LOADED,
    payload,
  }),
  setLoadMoreFriends: (payload: boolean) => ({
    type: types.LOAD_MORE_FRIENDS,
    payload,
  }),
  setHasMoreFriends: (payload: boolean) => ({
    type: types.HAS_MORE_FRIENDS,
    payload,
  }),
  setFriendsLoadedPage: (payload: number) => ({
    type: types.FRIENDS_LOADED_PAGE,
    payload,
  }),
  setFriendsMessage: (payload: string) => ({
    type: types.FRIENDS_MESSAGE,
    payload,
  }),

  //Journal
  getJournalPublic: (
    page: number,
    stories: Array<StoryResponse>,
    userID: string
  ) => ({ type: types.GET_JOURNAL_PUBLIC, payload: { page, stories, userID } }),
  setJournalPublicLoader: (payload: boolean) => ({
    type: types.JOURNAL_PUBLIC_LOADER,
    payload,
  }),
  setJournalPublicSuccess: (payload: Array<StoryResponse>) => ({
    type: types.JOURNAL_PUBLIC_SUCCESS,
    payload,
  }),
  setJournalPublicError: (payload: boolean) => ({
    type: types.JOURNAL_PUBLIC_ERROR,
    payload,
  }),
  setJournalPublicLoaded: (payload: boolean) => ({
    type: types.JOURNAL_PUBLIC_LOADED,
    payload,
  }),
  setLoadMoreJournalPublic: (payload: boolean) => ({
    type: types.LOAD_MORE_JOURNAL_PUBLIC,
    payload,
  }),
  setHasMoreJournalPublic: (payload: boolean) => ({
    type: types.HAS_MORE_JOURNAL_PUBLIC,
    payload,
  }),
  setJournalPublicLoadedPage: (payload: number) => ({
    type: types.JOURNAL_PUBLIC_LOADED_PAGE,
    payload,
  }),
  setJournalPublicMessage: (payload: string) => ({
    type: types.JOURNAL_PUBLIC_MESSAGE,
    payload,
  }),

  getJournalPrivate: (
    page: number,
    stories: Array<StoryResponse>,
    userID: string
  ) => ({
    type: types.GET_JOURNAL_PRIVATE,
    payload: { page, stories, userID },
  }),
  setJournalPrivateLoader: (payload: boolean) => ({
    type: types.JOURNAL_PRIVATE_LOADER,
    payload,
  }),
  setJournalPrivateSuccess: (payload: Array<StoryResponse>) => ({
    type: types.JOURNAL_PRIVATE_SUCCESS,
    payload,
  }),
  setJournalPrivateError: (payload: boolean) => ({
    type: types.JOURNAL_PRIVATE_ERROR,
    payload,
  }),
  setJournalPrivateLoaded: (payload: boolean) => ({
    type: types.JOURNAL_PRIVATE_LOADED,
    payload,
  }),
  setLoadMoreJournalPrivate: (payload: boolean) => ({
    type: types.LOAD_MORE_JOURNAL_PRIVATE,
    payload,
  }),
  setHasMoreJournalPrivate: (payload: boolean) => ({
    type: types.HAS_MORE_JOURNAL_PRIVATE,
    payload,
  }),
  setJournalPrivateLoadedPage: (payload: number) => ({
    type: types.JOURNAL_PRIVATE_LOADED_PAGE,
    payload,
  }),
  setJournalPrivateMessage: (payload: string) => ({
    type: types.JOURNAL_PRIVATE_MESSAGE,
    payload,
  }),

  //Announcement
  getAnnouncements: (
    page: number,
    announcements: Array<AnnouncementResponse>
  ) => ({ type: types.GET_ANNOUNCEMENTS, payload: { page, announcements } }),
  setAnnouncementsLoader: (payload: boolean) => ({
    type: types.ANNOUNCEMENTS_LOADER,
    payload,
  }),
  setAnnouncementsSuccess: (payload: Array<AnnouncementResponse>) => ({
    type: types.ANNOUNCEMENTS_SUCCESS,
    payload,
  }),
  setAnnouncementsError: (payload: boolean) => ({
    type: types.ANNOUNCEMENTS_ERROR,
    payload,
  }),
  setAnnouncementsLoaded: (payload: boolean) => ({
    type: types.ANNOUNCEMENTS_LOADED,
    payload,
  }),
  setLoadMoreAnnouncements: (payload: boolean) => ({
    type: types.LOAD_MORE_ANNOUNCEMENTS,
    payload,
  }),
  setHasMoreAnnouncements: (payload: boolean) => ({
    type: types.HAS_MORE_ANNOUNCEMENTS,
    payload,
  }),
  setAnnouncementsLoadedPage: (payload: number) => ({
    type: types.ANNOUNCEMENTS_LOADED_PAGE,
    payload,
  }),
  setAnnouncementsMessage: (payload: string) => ({
    type: types.ANNOUNCEMENTS_MESSAGE,
    payload,
  }),

  //Resources
  getResourceImages: (page: number, images: Array<ResourceResponse>) => ({
    type: types.GET_RESOURCES_IMAGES,
    payload: { page, images },
  }),
  setResourceImagesLoader: (payload: boolean) => ({
    type: types.RESOURCES_IMAGES_LOADER,
    payload,
  }),
  setResourceImagesSuccess: (payload: Array<ResourceResponse>) => ({
    type: types.RESOURCES_IMAGES_SUCCESS,
    payload,
  }),
  setResourceImagesError: (payload: boolean) => ({
    type: types.RESOURCES_IMAGES_ERROR,
    payload,
  }),
  setResourceImagesLoaded: (payload: boolean) => ({
    type: types.RESOURCES_IMAGES_LOADED,
    payload,
  }),
  setLoadMoreResourceImages: (payload: boolean) => ({
    type: types.LOAD_MORE_RESOURCES_IMAGES,
    payload,
  }),
  setHasMoreResourceImages: (payload: boolean) => ({
    type: types.HAS_MORE_RESOURCES_IMAGES,
    payload,
  }),
  setResourceImagesLoadedPage: (payload: number) => ({
    type: types.RESOURCES_IMAGES_LOADED_PAGE,
    payload,
  }),
  setResourceImagesMessage: (payload: string) => ({
    type: types.RESOURCES_IMAGES_MESSAGE,
    payload,
  }),

  getResourceAudios: (page: number, audios: Array<ResourceResponse>) => ({
    type: types.GET_RESOURCES_AUDIOS,
    payload: { page, audios },
  }),
  setResourceAudiosLoader: (payload: boolean) => ({
    type: types.RESOURCES_AUDIOS_LOADER,
    payload,
  }),
  setResourceAudiosSuccess: (payload: Array<ResourceResponse>) => ({
    type: types.RESOURCES_AUDIOS_SUCCESS,
    payload,
  }),
  setResourceAudiosError: (payload: boolean) => ({
    type: types.RESOURCES_AUDIOS_ERROR,
    payload,
  }),
  setResourceAudiosLoaded: (payload: boolean) => ({
    type: types.RESOURCES_AUDIOS_LOADED,
    payload,
  }),
  setLoadMoreResourceAudios: (payload: boolean) => ({
    type: types.LOAD_MORE_RESOURCES_AUDIOS,
    payload,
  }),
  setHasMoreResourceAudios: (payload: boolean) => ({
    type: types.HAS_MORE_RESOURCES_AUDIOS,
    payload,
  }),
  setResourceAudiosLoadedPage: (payload: number) => ({
    type: types.RESOURCES_AUDIOS_LOADED_PAGE,
    payload,
  }),
  setResourceAudiosMessage: (payload: string) => ({
    type: types.RESOURCES_AUDIOS_MESSAGE,
    payload,
  }),

  getResourceVideos: (page: number, videos: Array<ResourceResponse>) => ({
    type: types.GET_RESOURCES_VIDEOS,
    payload: { page, videos },
  }),
  setResourceVideosLoader: (payload: boolean) => ({
    type: types.RESOURCES_VIDEOS_LOADER,
    payload,
  }),
  setResourceVideosSuccess: (payload: Array<ResourceResponse>) => ({
    type: types.RESOURCES_VIDEOS_SUCCESS,
    payload,
  }),
  setResourceVideosError: (payload: boolean) => ({
    type: types.RESOURCES_VIDEOS_ERROR,
    payload,
  }),
  setResourceVideosLoaded: (payload: boolean) => ({
    type: types.RESOURCES_VIDEOS_LOADED,
    payload,
  }),
  setLoadMoreResourceVideos: (payload: boolean) => ({
    type: types.LOAD_MORE_RESOURCES_VIDEOS,
    payload,
  }),
  setHasMoreResourceVideos: (payload: boolean) => ({
    type: types.HAS_MORE_RESOURCES_VIDEOS,
    payload,
  }),
  setResourceVideosLoadedPage: (payload: number) => ({
    type: types.RESOURCES_VIDEOS_LOADED_PAGE,
    payload,
  }),
  setResourceVideosMessage: (payload: string) => ({
    type: types.RESOURCES_VIDEOS_MESSAGE,
    payload,
  }),

  getResourceDocuments: (page: number, documents: Array<ResourceResponse>) => ({
    type: types.GET_RESOURCES_DOCUMENTS,
    payload: { page, documents },
  }),
  setResourceDocumentsLoader: (payload: boolean) => ({
    type: types.RESOURCES_DOCUMENTS_LOADER,
    payload,
  }),
  setResourceDocumentsSuccess: (payload: Array<ResourceResponse>) => ({
    type: types.RESOURCES_DOCUMENTS_SUCCESS,
    payload,
  }),
  setResourceDocumentsError: (payload: boolean) => ({
    type: types.RESOURCES_DOCUMENTS_ERROR,
    payload,
  }),
  setResourceDocumentsLoaded: (payload: boolean) => ({
    type: types.RESOURCES_DOCUMENTS_LOADED,
    payload,
  }),
  setLoadMoreResourceDocuments: (payload: boolean) => ({
    type: types.LOAD_MORE_RESOURCES_DOCUMENTS,
    payload,
  }),
  setHasMoreResourceDocuments: (payload: boolean) => ({
    type: types.HAS_MORE_RESOURCES_DOCUMENTS,
    payload,
  }),
  setResourceDocumentsLoadedPage: (payload: number) => ({
    type: types.RESOURCES_DOCUMENTS_LOADED_PAGE,
    payload,
  }),
  setResourceDocumentsMessage: (payload: string) => ({
    type: types.RESOURCES_DOCUMENTS_MESSAGE,
    payload,
  }),

  getResourceUrls: (page: number, urls: Array<ResourceResponse>) => ({
    type: types.GET_RESOURCES_URLS,
    payload: { page, urls },
  }),
  setResourceUrlsLoader: (payload: boolean) => ({
    type: types.RESOURCES_URLS_LOADER,
    payload,
  }),
  setResourceUrlsSuccess: (payload: Array<ResourceResponse>) => ({
    type: types.RESOURCES_URLS_SUCCESS,
    payload,
  }),
  setResourceUrlsError: (payload: boolean) => ({
    type: types.RESOURCES_URLS_ERROR,
    payload,
  }),
  setResourceUrlsLoaded: (payload: boolean) => ({
    type: types.RESOURCES_URLS_LOADED,
    payload,
  }),
  setLoadMoreResourceUrls: (payload: boolean) => ({
    type: types.LOAD_MORE_RESOURCES_URLS,
    payload,
  }),
  setHasMoreResourceUrls: (payload: boolean) => ({
    type: types.HAS_MORE_RESOURCES_URLS,
    payload,
  }),
  setResourceUrlsLoadedPage: (payload: number) => ({
    type: types.RESOURCES_URLS_LOADED_PAGE,
    payload,
  }),
  setResourceUrlsMessage: (payload: string) => ({
    type: types.RESOURCES_URLS_MESSAGE,
    payload,
  }),

  //Mentors
  getMentors: (page: number, mentors: Array<ConnectMentorResponse>) => ({
    type: types.GET_MENTORS,
    payload: { page, mentors },
  }),
  setMentorsLoader: (payload: boolean) => ({
    type: types.MENTORS_LOADER,
    payload,
  }),
  setMentorsSuccess: (payload: Array<ConnectMentorResponse>) => ({
    type: types.MENTORS_SUCCESS,
    payload,
  }),
  setMentorsError: (payload: boolean) => ({
    type: types.MENTORS_ERROR,
    payload,
  }),
  setMentorsLoaded: (payload: boolean) => ({
    type: types.MENTORS_LOADED,
    payload,
  }),
  setLoadMoreMentors: (payload: boolean) => ({
    type: types.LOAD_MORE_MENTORS,
    payload,
  }),
  setHasMoreMentors: (payload: boolean) => ({
    type: types.HAS_MORE_MENTORS,
    payload,
  }),
  setMentorsLoadedPage: (payload: number) => ({
    type: types.MENTORS_LOADED_PAGE,
    payload,
  }),
  setMentorsMessage: (payload: string) => ({
    type: types.MENTORS_MESSAGE,
    payload,
  }),

  //Profile
  getProfile: (id: string, user: UserProfileResponse) => ({
    type: types.GET_PROFILE,
    payload: { id, user },
  }),
  setProfileLoader: (payload: boolean) => ({
    type: types.PROFILE_LOADER,
    payload,
  }),
  setProfileSuccess: (payload: UserProfileResponse) => ({
    type: types.PROFILE_SUCCESS,
    payload,
  }),
  setProfileError: (payload: boolean) => ({
    type: types.PROFILE_ERROR,
    payload,
  }),
  setProfileLoaded: (payload: boolean) => ({
    type: types.PROFILE_LOADED,
    payload,
  }),
  setProfileMessage: (payload: string) => ({
    type: types.PROFILE_MESSAGE,
    payload,
  }),
  setEditProfile: (id: string, data: UserEditRequest) => ({
    type: types.EDIT_PROFILE,
    payload: { id, data },
  }),
  setVerifyProfile: (user: UserProfileResponse, data: UserVerifyRequest) => ({
    type: types.VERIFY_PROFILE,
    payload: { user, data },
  }),
  reportUser: (payload: ReportRequest) => ({
    type: types.REPORT_USER,
    payload,
  }),
  blockUser: (payload: string) => ({ type: types.BLOCK_USER, payload }),
  unBlockUser: (payload: string) => ({ type: types.UNBLOCK_USER, payload }),
  unBlockUserSuccess: (payload: string) => ({
    type: types.UNBLOCK_USER_SUCCESS,
    payload,
  }),
  deleteUser: (payload: DeleteRequest) => ({
    type: types.DELETE_USER,
    payload,
  }),

  getProfileJournalPublic: (
    page: number,
    stories: Array<StoryResponse>,
    userID: string
  ) => ({
    type: types.GET_PROFILE_JOURNAL_PUBLIC,
    payload: { page, stories, userID },
  }),
  setProfileJournalPublicLoader: (payload: boolean) => ({
    type: types.PROFILE_JOURNAL_PUBLIC_LOADER,
    payload,
  }),
  setProfileJournalPublicSuccess: (payload: Array<StoryResponse>) => ({
    type: types.PROFILE_JOURNAL_PUBLIC_SUCCESS,
    payload,
  }),
  setProfileJournalPublicError: (payload: boolean) => ({
    type: types.PROFILE_JOURNAL_PUBLIC_ERROR,
    payload,
  }),
  setProfileJournalPublicLoaded: (payload: boolean) => ({
    type: types.PROFILE_JOURNAL_PUBLIC_LOADED,
    payload,
  }),
  setLoadMoreProfileJournalPublic: (payload: boolean) => ({
    type: types.LOAD_MORE_PROFILE_JOURNAL_PUBLIC,
    payload,
  }),
  setHasMoreProfileJournalPublic: (payload: boolean) => ({
    type: types.HAS_MORE_PROFILE_JOURNAL_PUBLIC,
    payload,
  }),
  setProfileJournalPublicLoadedPage: (payload: number) => ({
    type: types.PROFILE_JOURNAL_PUBLIC_LOADED_PAGE,
    payload,
  }),
  setProfileJournalPublicMessage: (payload: string) => ({
    type: types.PROFILE_JOURNAL_PUBLIC_MESSAGE,
    payload,
  }),

  getProfileJournalPrivate: (
    page: number,
    stories: Array<StoryResponse>,
    userID: string
  ) => ({
    type: types.GET_PROFILE_JOURNAL_PRIVATE,
    payload: { page, stories, userID },
  }),
  setProfileJournalPrivateLoader: (payload: boolean) => ({
    type: types.PROFILE_JOURNAL_PRIVATE_LOADER,
    payload,
  }),
  setProfileJournalPrivateSuccess: (payload: Array<StoryResponse>) => ({
    type: types.PROFILE_JOURNAL_PRIVATE_SUCCESS,
    payload,
  }),
  setProfileJournalPrivateError: (payload: boolean) => ({
    type: types.PROFILE_JOURNAL_PRIVATE_ERROR,
    payload,
  }),
  setProfileJournalPrivateLoaded: (payload: boolean) => ({
    type: types.PROFILE_JOURNAL_PRIVATE_LOADED,
    payload,
  }),
  setLoadMoreProfileJournalPrivate: (payload: boolean) => ({
    type: types.LOAD_MORE_PROFILE_JOURNAL_PRIVATE,
    payload,
  }),
  setHasMoreProfileJournalPrivate: (payload: boolean) => ({
    type: types.HAS_MORE_PROFILE_JOURNAL_PRIVATE,
    payload,
  }),
  setProfileJournalPrivateLoadedPage: (payload: number) => ({
    type: types.PROFILE_JOURNAL_PRIVATE_LOADED_PAGE,
    payload,
  }),
  setProfileJournalPrivateMessage: (payload: string) => ({
    type: types.PROFILE_JOURNAL_PRIVATE_MESSAGE,
    payload,
  }),

  getProfileInterest: (payload: string) => ({
    type: types.GET_PROFILE_INTEREST,
    payload,
  }),
  setProfileInterestLoader: (payload: boolean) => ({
    type: types.PROFILE_INTEREST_LOADER,
    payload,
  }),
  setProfileInterestSuccess: (payload: Array<InterestResponse>) => ({
    type: types.PROFILE_INTEREST_SUCCESS,
    payload,
  }),
  setProfileInterestError: (payload: boolean) => ({
    type: types.PROFILE_INTEREST_ERROR,
    payload,
  }),
  setProfileInterestLoaded: (payload: boolean) => ({
    type: types.PROFILE_INTEREST_LOADED,
    payload,
  }),
  setProfileInterestMessage: (payload: string) => ({
    type: types.PROFILE_INTEREST_MESSAGE,
    payload,
  }),
  updateProfileInterest: (add: Array<string>, remove: Array<string>) => ({
    type: types.UPDATE_PROFILE_INTEREST,
    payload: { add, remove },
  }),
  updateProfileInterestSuccess: (payload: Array<InterestResponse>) => ({
    type: types.UPDATE_PROFILE_INTEREST_SUCCESS,
    payload,
  }),

  //Mentor Profile
  getMentorProfile: (id: string, user: UserProfileResponse) => ({
    type: types.GET_MENTOR_PROFILE,
    payload: { id, user },
  }),
  setMentorProfileLoader: (payload: boolean) => ({
    type: types.MENTOR_PROFILE_LOADER,
    payload,
  }),
  setMentorProfileSuccess: (payload: UserProfileResponse) => ({
    type: types.MENTOR_PROFILE_SUCCESS,
    payload,
  }),
  setMentorProfileError: (payload: boolean) => ({
    type: types.MENTOR_PROFILE_ERROR,
    payload,
  }),
  setMentorProfileLoaded: (payload: boolean) => ({
    type: types.MENTOR_PROFILE_LOADED,
    payload,
  }),
  setMentorProfileMessage: (payload: string) => ({
    type: types.MENTOR_PROFILE_MESSAGE,
    payload,
  }),
  getMentorConnection: (payload: string) => ({
    type: types.MENTOR_CONNECTION,
    payload,
  }),
  getMentorConnectionSuccess: (payload: ConnectMentorResponse) => ({
    type: types.MENTOR_CONNECTION_SUCCESS,
    payload,
  }),
  setEditMentorProfile: (id: string, data: UserEditRequest) => ({
    type: types.EDIT_MENTOR_PROFILE,
    payload: { id, data },
  }),
  setVerifyMentorProfile: (
    user: UserProfileResponse,
    data: UserVerifyRequest
  ) => ({ type: types.VERIFY_MENTOR_PROFILE, payload: { user, data } }),

  getMentorProfileInterest: (payload: string) => ({
    type: types.GET_MENTOR_PROFILE_INTERESTS,
    payload,
  }),
  setMentorProfileInterestLoader: (payload: boolean) => ({
    type: types.MENTOR_PROFILE_INTERESTS_LOADER,
    payload,
  }),
  setMentorProfileInterestSuccess: (payload: Array<InterestResponse>) => ({
    type: types.MENTOR_PROFILE_INTERESTS_SUCCESS,
    payload,
  }),
  setMentorProfileInterestError: (payload: boolean) => ({
    type: types.MENTOR_PROFILE_INTERESTS_ERROR,
    payload,
  }),
  setMentorProfileInterestLoaded: (payload: boolean) => ({
    type: types.MENTOR_PROFILE_INTERESTS_LOADED,
    payload,
  }),
  setMentorProfileInterestMessage: (payload: string) => ({
    type: types.MENTOR_PROFILE_INTERESTS_MESSAGE,
    payload,
  }),
  updateMentorProfileInterest: (add: Array<string>, remove: Array<string>) => ({
    type: types.UPDATE_MENTOR_PROFILE_INTERESTS,
    payload: { add, remove },
  }),
  updateMentorProfileInterestSuccess: (payload: Array<InterestResponse>) => ({
    type: types.UPDATE_MENTOR_PROFILE_INTERESTS_SUCCESS,
    payload,
  }),

  //Categories
  getCategories: (page: number, categories: Array<InterestResponse>) => ({
    type: types.GET_CATEGORIES,
    payload: { page, categories },
  }),
  setCategoriesLoader: (payload: boolean) => ({
    type: types.CATEGORIES_LOADER,
    payload,
  }),
  setCategoriesSuccess: (payload: Array<InterestResponse>) => ({
    type: types.CATEGORIES_SUCCESS,
    payload,
  }),
  setCategoriesError: (payload: boolean) => ({
    type: types.CATEGORIES_ERROR,
    payload,
  }),
  setCategoriesLoaded: (payload: boolean) => ({
    type: types.CATEGORIES_LOADED,
    payload,
  }),
  setLoadMoreCategories: (payload: boolean) => ({
    type: types.LOAD_MORE_CATEGORIES,
    payload,
  }),
  setHasMoreCategories: (payload: boolean) => ({
    type: types.HAS_MORE_CATEGORIES,
    payload,
  }),
  setCategoriesLoadedPage: (payload: number) => ({
    type: types.CATEGORIES_LOADED_PAGE,
    payload,
  }),
  setCategoriesMessage: (payload: string) => ({
    type: types.CATEGORIES_MESSAGE,
    payload,
  }),

  //Story
  getStory: (payload: string) => ({ type: types.GET_STORY, payload }),
  setStoryLoader: (payload: boolean) => ({ type: types.STORY_LOADER, payload }),
  setStorySuccess: (payload: StoryResponse) => ({
    type: types.STORY_SUCCESS,
    payload,
  }),
  setStoryError: (payload: boolean) => ({ type: types.STORY_ERROR, payload }),
  setStoryLoaded: (payload: boolean) => ({ type: types.STORY_LOADED, payload }),
  setStoryMessage: (payload: string) => ({
    type: types.STORY_MESSAGE,
    payload,
  }),

  addStory: (data: StoryRequest, file?: File) => ({
    type: types.ADD_STORY,
    payload: { data, file },
  }),
  addStorySuccess: (payload: StoryResponse) => ({
    type: types.ADD_STORY_SUCCESS,
    payload,
  }),

  editStory: (id: string, data: StoryRequest, file?: File) => ({
    type: types.EDIT_STORY,
    payload: { id, data, file },
  }),
  editStorySuccess: (payload: StoryResponse) => ({
    type: types.EDIT_STORY_SUCCESS,
    payload,
  }),

  deleteStory: (payload: string) => ({ type: types.DELETE_STORY, payload }),
  deleteStorySuccess: (payload: StoryResponse) => ({
    type: types.DELETE_STORY_SUCCESS,
    payload,
  }),

  reactStory: (id: string, reaction: number) => ({
    type: types.REACT_STORY,
    payload: { id, reaction },
  }),
  reactStorySuccess: (payload: StoryResponse) => ({
    type: types.REACT_STORY_SUCCESS,
    payload,
  }),

  //Groups
  getGroups: (page: number, groups: Array<GroupResponse>) => ({
    type: types.GET_GROUPS,
    payload: { page, groups },
  }),
  setGroupsLoader: (payload: boolean) => ({
    type: types.GROUPS_LOADER,
    payload,
  }),
  setGroupsSuccess: (payload: Array<GroupResponse>) => ({
    type: types.GROUPS_SUCCESS,
    payload,
  }),
  setGroupsError: (payload: boolean) => ({ type: types.GROUPS_ERROR, payload }),
  setGroupsLoaded: (payload: boolean) => ({
    type: types.GROUPS_LOADED,
    payload,
  }),
  setLoadMoreGroups: (payload: boolean) => ({
    type: types.LOAD_MORE_GROUPS,
    payload,
  }),
  setHasMoreGroups: (payload: boolean) => ({
    type: types.HAS_MORE_GROUPS,
    payload,
  }),
  setGroupsLoadedPage: (payload: number) => ({
    type: types.GROUPS_LOADED_PAGE,
    payload,
  }),
  setGroupsMessage: (payload: string) => ({
    type: types.GROUPS_MESSAGE,
    payload,
  }),

  //Interests
  getInterests: () => ({ type: types.GET_INTERESTS }),
  setInterestsLoader: (payload: boolean) => ({
    type: types.INTERESTS_LOADER,
    payload,
  }),
  setInterestsSuccess: (payload: Array<InterestResponse>) => ({
    type: types.INTERESTS_SUCCESS,
    payload,
  }),
  setInterestsError: (payload: boolean) => ({
    type: types.INTERESTS_ERROR,
    payload,
  }),
  setInterestsLoaded: (payload: boolean) => ({
    type: types.INTERESTS_LOADED,
    payload,
  }),
  setInterestsMessage: (payload: string) => ({
    type: types.INTERESTS_MESSAGE,
    payload,
  }),

  //Girls
  getGirls: (page: number, girls: Array<ConnectMentorResponse>) => ({
    type: types.GET_GIRLS,
    payload: { page, girls },
  }),
  setGirlsLoader: (payload: boolean) => ({ type: types.GIRLS_LOADER, payload }),
  setGirlsSuccess: (payload: Array<ConnectMentorResponse>) => ({
    type: types.GIRLS_SUCCESS,
    payload,
  }),
  setGirlsError: (payload: boolean) => ({ type: types.GIRLS_ERROR, payload }),
  setGirlsLoaded: (payload: boolean) => ({ type: types.GIRLS_LOADED, payload }),
  setLoadMoreGirls: (payload: boolean) => ({
    type: types.LOAD_MORE_GIRLS,
    payload,
  }),
  setHasMoreGirls: (payload: boolean) => ({
    type: types.HAS_MORE_GIRLS,
    payload,
  }),
  setGirlsLoadedPage: (payload: number) => ({
    type: types.GIRLS_LOADED_PAGE,
    payload,
  }),
  setGirlsMessage: (payload: string) => ({
    type: types.GIRLS_MESSAGE,
    payload,
  }),
};

export default Main;
