import React, {useEffect} from "react";
import Header from './../components/Header';
import Rightchat from '../components/Rightchat';
import Appfooter from './../components/Appfooter';
import {RouteComponentProps} from "react-router";
import Mobile from "../../pages/mobile";
import {BaseState, StateKeys} from "../redux/states/mainn.state";
import {HelplineResponse} from "../services/api.response.service";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../redux/states";
import {AppState} from "../services/app.service";
import subMain from "../redux/actions/mainn.action";
import {ApiEndpoint} from "../services/api.endpoint.service";

const Dashboard:React.FC<RouteComponentProps> = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: IRootState) => state.mainSub);
    const helpline: BaseState<HelplineResponse> = state[StateKeys.HELPLINE];

    useEffect(() => {
        if (helpline.pageState !== AppState.LOADED) {
            dispatch(
              subMain.getPageObjectData({
                  stateKey: StateKeys.HELPLINE,
                  apiEndpoint: ApiEndpoint.HELPLINE,
              })
            );
        }
    }, []);

    if(window.innerWidth < 770){
        return <Mobile/>
    }
    return <>
        <Header/>
        <Rightchat/>
        {props.children}
        {/*<Popupchat/>*/}
        <Appfooter/>
    </>
}

export default Dashboard;