import rootState from "../states";
import { types } from "../actions/main.action";
import {
  StoryResponse,
  UserResponseFriendRequest,
} from "../../services/api.response.service";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = rootState.main, { type, payload }: any) {
  switch (type) {
    //Home
    case types.HOME_LOADER:
      return { ...state, home: { ...state.home, loader: payload } };
    case types.HOME_ERROR:
      return { ...state, home: { ...state.home, error: payload } };
    case types.HOME_MESSAGE:
      return { ...state, home: { ...state.home, message: payload } };

    //Stories
    case types.HOME_STORIES_LOADER:
      return { ...state, home: { ...state.home, storiesLoader: payload } };
    case types.HOME_STORIES_SUCCESS:
      return { ...state, home: { ...state.home, stories: payload } };
    case types.HOME_STORIES_ERROR:
      return { ...state, home: { ...state.home, storiesError: payload } };
    case types.HOME_STORIES_LOADED:
      return { ...state, home: { ...state.home, storiesLoaded: payload } };
    case types.HOME_LOAD_MORE_STORIES:
      return { ...state, home: { ...state.home, loadMoreStories: payload } };
    case types.HOME_HAS_MORE_STORIES:
      return { ...state, home: { ...state.home, hasMoreStories: payload } };
    case types.HOME_STORIES_LOADED_PAGE:
      return { ...state, home: { ...state.home, storiesLoadedPage: payload } };

    //Featured Mentors
    case types.HOME_FEATURED_MENTORS_LOADER:
      return {
        ...state,
        home: { ...state.home, featuredMentorsLoader: payload },
      };
    case types.HOME_FEATURED_MENTORS_SUCCESS:
      return { ...state, home: { ...state.home, featuredMentors: payload } };
    case types.HOME_FEATURED_MENTORS_ERROR:
      return {
        ...state,
        home: { ...state.home, featuredMentorsError: payload },
      };
    case types.HOME_FEATURED_MENTORS_LOADED:
      return {
        ...state,
        home: { ...state.home, featuredMentorsLoaded: payload },
      };
    case types.HOME_LOAD_MORE_FEATURED_MENTORS:
      return {
        ...state,
        home: { ...state.home, loadMoreFeaturedMentors: payload },
      };
    case types.HOME_HAS_MORE_FEATURED_MENTORS:
      return {
        ...state,
        home: { ...state.home, hasMoreFeaturedMentors: payload },
      };
    case types.HOME_FEATURED_MENTORS_LOADED_PAGE:
      return {
        ...state,
        home: { ...state.home, featuredMentorsLoadedPage: payload },
      };

    //Requested Connections
    case types.HOME_FRIEND_REQUESTS_LOADER:
      return {
        ...state,
        home: { ...state.home, friendRequestsLoader: payload },
      };
    case types.HOME_FRIEND_REQUESTS_SUCCESS:
      return { ...state, home: { ...state.home, friendRequests: payload } };
    case types.HOME_FRIEND_REQUESTS_ERROR:
      return {
        ...state,
        home: { ...state.home, friendRequestsError: payload },
      };
    case types.HOME_FRIEND_REQUESTS_LOADED:
      return {
        ...state,
        home: { ...state.home, friendRequestsLoaded: payload },
      };
    case types.HOME_LOAD_MORE_FRIEND_REQUESTS:
      return {
        ...state,
        home: { ...state.home, loadMoreFriendRequests: payload },
      };
    case types.HOME_HAS_MORE_FRIEND_REQUESTS:
      return {
        ...state,
        home: { ...state.home, hasMoreFriendRequests: payload },
      };
    case types.HOME_FRIEND_REQUESTS_LOADED_PAGE:
      return {
        ...state,
        home: { ...state.home, friendRequestsLoadedPage: payload },
      };
    case types.HOME_FRIEND_REQUESTS_ACCEPT_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          friends: state.friends.loaded
            ? [payload, ...state.friends.friends]
            : [],
        },
        profile: {
          ...state.profile,
          profile:
            state.profile.profile?.id === payload.user.id
              ? payload.user
              : state.profile.profile,
        },
        home: {
          ...state.home,
          friendRequests: [
            ...state.home.friendRequests.filter(
              (e) => e.user.id !== payload.id
            ),
          ],
        },
      };
    case types.HOME_FRIEND_REQUESTS_DECLINE_SUCCESS:
      return {
        ...state,
        home: {
          ...state.home,
          friendRequests: [
            ...state.home.friendRequests.filter(
              (e) => e.user.id !== payload.user.id
            ),
          ],
        },
      };

    //Suggested Connections
    case types.HOME_SUGGESTED_FRIENDS_LOADER:
      return {
        ...state,
        home: { ...state.home, suggestedFriendsLoader: payload },
      };
    case types.HOME_SUGGESTED_FRIENDS_SUCCESS:
      return { ...state, home: { ...state.home, suggestedFriends: payload } };
    case types.HOME_SUGGESTED_FRIENDS_ERROR:
      return {
        ...state,
        home: { ...state.home, suggestedFriendsError: payload },
      };
    case types.HOME_SUGGESTED_FRIENDS_LOADED:
      return {
        ...state,
        home: { ...state.home, suggestedFriendsLoaded: payload },
      };
    case types.HOME_LOAD_MORE_SUGGESTED_FRIENDS:
      return {
        ...state,
        home: { ...state.home, loadMoreSuggestedFriends: payload },
      };
    case types.HOME_HAS_MORE_SUGGESTED_FRIENDS:
      return {
        ...state,
        home: { ...state.home, hasMoreSuggestedFriends: payload },
      };
    case types.HOME_SUGGESTED_FRIENDS_LOADED_PAGE:
      return {
        ...state,
        home: { ...state.home, suggestedFriendsLoadedPage: payload },
      };
    case types.HOME_SUGGESTED_FRIENDS_ADD_REQUEST:
      return {
        ...state,
        home: {
          ...state.home,
          suggestedFriendsAddRequest: [
            ...state.home.suggestedFriendsAddRequest,
            payload,
          ],
        },
      };

    //Friends
    case types.FRIENDS_LOADER:
      return { ...state, friends: { ...state.friends, loader: payload } };
    case types.FRIENDS_MESSAGE:
      return { ...state, friends: { ...state.friends, message: payload } };
    case types.FRIENDS_SUCCESS:
      return { ...state, friends: { ...state.friends, friends: payload } };
    case types.FRIENDS_ERROR:
      return { ...state, friends: { ...state.friends, error: payload } };
    case types.FRIENDS_LOADED:
      return { ...state, friends: { ...state.friends, loaded: payload } };
    case types.LOAD_MORE_FRIENDS:
      return { ...state, friends: { ...state.friends, loadMore: payload } };
    case types.HAS_MORE_FRIENDS:
      return { ...state, friends: { ...state.friends, hasMore: payload } };
    case types.FRIENDS_LOADED_PAGE:
      return { ...state, friends: { ...state.friends, loadedPage: payload } };

    //Journal
    case types.JOURNAL_PUBLIC_LOADER:
      return { ...state, journal: { ...state.journal, publicLoader: payload } };
    case types.JOURNAL_PUBLIC_MESSAGE:
      return {
        ...state,
        journal: { ...state.journal, publicMessage: payload },
      };
    case types.JOURNAL_PUBLIC_SUCCESS:
      return {
        ...state,
        journal: { ...state.journal, publicStories: payload },
      };
    case types.JOURNAL_PUBLIC_ERROR:
      return { ...state, journal: { ...state.journal, publicError: payload } };
    case types.JOURNAL_PUBLIC_LOADED:
      return { ...state, journal: { ...state.journal, publicLoaded: payload } };
    case types.LOAD_MORE_JOURNAL_PUBLIC:
      return {
        ...state,
        journal: { ...state.journal, loadMorePublic: payload },
      };
    case types.HAS_MORE_JOURNAL_PUBLIC:
      return {
        ...state,
        journal: { ...state.journal, hasMorePublic: payload },
      };
    case types.JOURNAL_PUBLIC_LOADED_PAGE:
      return {
        ...state,
        journal: { ...state.journal, publicLoadedPage: payload },
      };

    case types.JOURNAL_PRIVATE_LOADER:
      return {
        ...state,
        journal: { ...state.journal, privateLoader: payload },
      };
    case types.JOURNAL_PRIVATE_MESSAGE:
      return {
        ...state,
        journal: { ...state.journal, privateMessage: payload },
      };
    case types.JOURNAL_PRIVATE_SUCCESS:
      return {
        ...state,
        journal: { ...state.journal, privateStories: payload },
      };
    case types.JOURNAL_PRIVATE_ERROR:
      return { ...state, journal: { ...state.journal, privateError: payload } };
    case types.JOURNAL_PRIVATE_LOADED:
      return {
        ...state,
        journal: { ...state.journal, privateLoaded: payload },
      };
    case types.LOAD_MORE_JOURNAL_PRIVATE:
      return {
        ...state,
        journal: { ...state.journal, loadMorePrivate: payload },
      };
    case types.HAS_MORE_JOURNAL_PRIVATE:
      return {
        ...state,
        journal: { ...state.journal, hasMorePrivate: payload },
      };
    case types.JOURNAL_PRIVATE_LOADED_PAGE:
      return {
        ...state,
        journal: { ...state.journal, privateLoadedPage: payload },
      };

    //Announcement
    case types.ANNOUNCEMENTS_LOADER:
      return {
        ...state,
        announcements: { ...state.announcements, loader: payload },
      };
    case types.ANNOUNCEMENTS_MESSAGE:
      return {
        ...state,
        announcements: { ...state.announcements, message: payload },
      };
    case types.ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: { ...state.announcements, announcements: payload },
      };
    case types.ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        announcements: { ...state.announcements, error: payload },
      };
    case types.ANNOUNCEMENTS_LOADED:
      return {
        ...state,
        announcements: { ...state.announcements, loaded: payload },
      };
    case types.LOAD_MORE_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: { ...state.announcements, loadMore: payload },
      };
    case types.HAS_MORE_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: { ...state.announcements, hasMore: payload },
      };
    case types.ANNOUNCEMENTS_LOADED_PAGE:
      return {
        ...state,
        announcements: { ...state.announcements, loadedPage: payload },
      };

    case types.RESOURCES_IMAGES_LOADER:
      return {
        ...state,
        resources: { ...state.resources, resourceImagesLoader: payload },
      };
    case types.RESOURCES_IMAGES_MESSAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceImagesMessage: payload },
      };
    case types.RESOURCES_IMAGES_SUCCESS:
      return {
        ...state,
        resources: { ...state.resources, resourceImages: payload },
      };
    case types.RESOURCES_IMAGES_ERROR:
      return {
        ...state,
        resources: { ...state.resources, resourceImagesError: payload },
      };
    case types.RESOURCES_IMAGES_LOADED:
      return {
        ...state,
        resources: { ...state.resources, resourceImagesLoaded: payload },
      };
    case types.LOAD_MORE_RESOURCES_IMAGES:
      return {
        ...state,
        resources: { ...state.resources, loadMoreResourceImages: payload },
      };
    case types.HAS_MORE_RESOURCES_IMAGES:
      return {
        ...state,
        resources: { ...state.resources, hasMoreResourceImages: payload },
      };
    case types.RESOURCES_IMAGES_LOADED_PAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceImagesLoadedPage: payload },
      };

    case types.RESOURCES_AUDIOS_LOADER:
      return {
        ...state,
        resources: { ...state.resources, resourceAudiosLoader: payload },
      };
    case types.RESOURCES_AUDIOS_MESSAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceAudiosMessage: payload },
      };
    case types.RESOURCES_AUDIOS_SUCCESS:
      return {
        ...state,
        resources: { ...state.resources, resourceAudios: payload },
      };
    case types.RESOURCES_AUDIOS_ERROR:
      return {
        ...state,
        resources: { ...state.resources, resourceAudiosError: payload },
      };
    case types.RESOURCES_AUDIOS_LOADED:
      return {
        ...state,
        resources: { ...state.resources, resourceAudiosLoaded: payload },
      };
    case types.LOAD_MORE_RESOURCES_AUDIOS:
      return {
        ...state,
        resources: { ...state.resources, loadMoreResourceAudios: payload },
      };
    case types.HAS_MORE_RESOURCES_AUDIOS:
      return {
        ...state,
        resources: { ...state.resources, hasMoreResourceAudios: payload },
      };
    case types.RESOURCES_AUDIOS_LOADED_PAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceAudiosLoadedPage: payload },
      };

    case types.RESOURCES_VIDEOS_LOADER:
      return {
        ...state,
        resources: { ...state.resources, resourceVideosLoader: payload },
      };
    case types.RESOURCES_VIDEOS_MESSAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceVideosMessage: payload },
      };
    case types.RESOURCES_VIDEOS_SUCCESS:
      return {
        ...state,
        resources: { ...state.resources, resourceVideos: payload },
      };
    case types.RESOURCES_VIDEOS_ERROR:
      return {
        ...state,
        resources: { ...state.resources, resourceVideosError: payload },
      };
    case types.RESOURCES_VIDEOS_LOADED:
      return {
        ...state,
        resources: { ...state.resources, resourceVideosLoaded: payload },
      };
    case types.LOAD_MORE_RESOURCES_VIDEOS:
      return {
        ...state,
        resources: { ...state.resources, loadMoreResourceVideos: payload },
      };
    case types.HAS_MORE_RESOURCES_VIDEOS:
      return {
        ...state,
        resources: { ...state.resources, hasMoreResourceVideos: payload },
      };
    case types.RESOURCES_VIDEOS_LOADED_PAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceVideosLoadedPage: payload },
      };

    case types.RESOURCES_DOCUMENTS_LOADER:
      return {
        ...state,
        resources: { ...state.resources, resourceDocumentsLoader: payload },
      };
    case types.RESOURCES_DOCUMENTS_MESSAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceDocumentsMessage: payload },
      };
    case types.RESOURCES_DOCUMENTS_SUCCESS:
      return {
        ...state,
        resources: { ...state.resources, resourceDocuments: payload },
      };
    case types.RESOURCES_DOCUMENTS_ERROR:
      return {
        ...state,
        resources: { ...state.resources, resourceDocumentsError: payload },
      };
    case types.RESOURCES_DOCUMENTS_LOADED:
      return {
        ...state,
        resources: { ...state.resources, resourceDocumentsLoaded: payload },
      };
    case types.LOAD_MORE_RESOURCES_DOCUMENTS:
      return {
        ...state,
        resources: { ...state.resources, loadMoreResourceDocuments: payload },
      };
    case types.HAS_MORE_RESOURCES_DOCUMENTS:
      return {
        ...state,
        resources: { ...state.resources, hasMoreResourceDocuments: payload },
      };
    case types.RESOURCES_DOCUMENTS_LOADED_PAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceDocumentsLoadedPage: payload },
      };

    case types.RESOURCES_URLS_LOADER:
      return {
        ...state,
        resources: { ...state.resources, resourceUrlsLoader: payload },
      };
    case types.RESOURCES_URLS_MESSAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceUrlsMessage: payload },
      };
    case types.RESOURCES_URLS_SUCCESS:
      return {
        ...state,
        resources: { ...state.resources, resourceUrls: payload },
      };
    case types.RESOURCES_URLS_ERROR:
      return {
        ...state,
        resources: { ...state.resources, resourceUrlsError: payload },
      };
    case types.RESOURCES_URLS_LOADED:
      return {
        ...state,
        resources: { ...state.resources, resourceUrlsLoaded: payload },
      };
    case types.LOAD_MORE_RESOURCES_URLS:
      return {
        ...state,
        resources: { ...state.resources, loadMoreResourceUrls: payload },
      };
    case types.HAS_MORE_RESOURCES_URLS:
      return {
        ...state,
        resources: { ...state.resources, hasMoreResourceUrls: payload },
      };
    case types.RESOURCES_URLS_LOADED_PAGE:
      return {
        ...state,
        resources: { ...state.resources, resourceUrlsLoadedPage: payload },
      };

    //Mentors
    case types.MENTORS_LOADER:
      return { ...state, mentors: { ...state.mentors, loader: payload } };
    case types.MENTORS_MESSAGE:
      return { ...state, mentors: { ...state.mentors, message: payload } };
    case types.MENTORS_SUCCESS:
      return { ...state, mentors: { ...state.mentors, mentors: payload } };
    case types.MENTORS_ERROR:
      return { ...state, mentors: { ...state.mentors, error: payload } };
    case types.MENTORS_LOADED:
      return { ...state, mentors: { ...state.mentors, loaded: payload } };
    case types.LOAD_MORE_MENTORS:
      return { ...state, mentors: { ...state.mentors, loadMore: payload } };
    case types.HAS_MORE_MENTORS:
      return { ...state, mentors: { ...state.mentors, hasMore: payload } };
    case types.MENTORS_LOADED_PAGE:
      return { ...state, mentors: { ...state.mentors, loadedPage: payload } };

    //Profile
    case types.PROFILE_LOADER:
      return { ...state, profile: { ...state.profile, loader: payload } };
    case types.PROFILE_MESSAGE:
      return { ...state, profile: { ...state.profile, message: payload } };
    case types.PROFILE_SUCCESS:
      return { ...state, profile: { ...state.profile, profile: payload } };
    case types.PROFILE_ERROR:
      return { ...state, profile: { ...state.profile, error: payload } };
    case types.PROFILE_LOADED:
      return { ...state, profile: { ...state.profile, loaded: payload } };

    case types.PROFILE_JOURNAL_PUBLIC_LOADER:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, publicLoader: payload },
        },
      };
    case types.PROFILE_JOURNAL_PUBLIC_MESSAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, publicMessage: payload },
        },
      };
    case types.PROFILE_JOURNAL_PUBLIC_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, publicStories: payload },
        },
      };
    case types.PROFILE_JOURNAL_PUBLIC_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, publicError: payload },
        },
      };
    case types.PROFILE_JOURNAL_PUBLIC_LOADED:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, publicLoaded: payload },
        },
      };
    case types.LOAD_MORE_PROFILE_JOURNAL_PUBLIC:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, loadMorePublic: payload },
        },
      };
    case types.HAS_MORE_PROFILE_JOURNAL_PUBLIC:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, hasMorePublic: payload },
        },
      };
    case types.PROFILE_JOURNAL_PUBLIC_LOADED_PAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, publicLoadedPage: payload },
        },
      };

    case types.PROFILE_JOURNAL_PRIVATE_LOADER:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, privateLoader: payload },
        },
      };
    case types.PROFILE_JOURNAL_PRIVATE_MESSAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, privateMessage: payload },
        },
      };
    case types.PROFILE_JOURNAL_PRIVATE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, privateStories: payload },
        },
      };
    case types.PROFILE_JOURNAL_PRIVATE_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, privateError: payload },
        },
      };
    case types.PROFILE_JOURNAL_PRIVATE_LOADED:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, privateLoaded: payload },
        },
      };
    case types.LOAD_MORE_PROFILE_JOURNAL_PRIVATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, loadMorePrivate: payload },
        },
      };
    case types.HAS_MORE_PROFILE_JOURNAL_PRIVATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, hasMorePrivate: payload },
        },
      };
    case types.PROFILE_JOURNAL_PRIVATE_LOADED_PAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          journal: { ...state.profile.journal, privateLoadedPage: payload },
        },
      };

    case types.PROFILE_INTEREST_LOADER:
      return {
        ...state,
        profile: {
          ...state.profile,
          interest: { ...state.profile.interest, loader: payload },
        },
      };
    case types.PROFILE_INTEREST_MESSAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          interest: { ...state.profile.interest, message: payload },
        },
      };
    case types.PROFILE_INTEREST_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          interest: { ...state.profile.interest, interests: payload },
        },
      };
    case types.PROFILE_INTEREST_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          interest: { ...state.profile.interest, error: payload },
        },
      };
    case types.PROFILE_INTEREST_LOADED:
      return {
        ...state,
        profile: {
          ...state.profile,
          interest: { ...state.profile.interest, loaded: payload },
        },
      };
    case types.UPDATE_PROFILE_INTEREST_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          interest: { ...state.profile.interest, interests: payload },
        },
      };

    //Mentor Profile
    case types.MENTOR_PROFILE_LOADER:
      return {
        ...state,
        mentorProfile: { ...state.mentorProfile, loader: payload },
      };
    case types.MENTOR_PROFILE_MESSAGE:
      return {
        ...state,
        mentorProfile: { ...state.mentorProfile, message: payload },
      };
    case types.MENTOR_PROFILE_SUCCESS:
      return {
        ...state,
        mentorProfile: { ...state.mentorProfile, profile: payload },
      };
    case types.MENTOR_PROFILE_ERROR:
      return {
        ...state,
        mentorProfile: { ...state.mentorProfile, error: payload },
      };
    case types.MENTOR_PROFILE_LOADED:
      return {
        ...state,
        mentorProfile: { ...state.mentorProfile, loaded: payload },
      };
    case types.MENTOR_CONNECTION_SUCCESS:
      return {
        ...state,
        mentors: {
          ...state.mentors,
          mentors: state.mentors.loaded
            ? [payload, ...state.mentors.mentors]
            : [],
        },
        mentorProfile: {
          ...state.mentorProfile,
          profile:
            state.mentorProfile.profile?.id === payload.mentor.id
              ? payload.mentor
              : state.mentorProfile.profile,
        },
      };

    case types.MENTOR_PROFILE_INTERESTS_LOADER:
      return {
        ...state,
        mentorProfile: {
          ...state.mentorProfile,
          interest: { ...state.mentorProfile.interest, loader: payload },
        },
      };
    case types.MENTOR_PROFILE_INTERESTS_MESSAGE:
      return {
        ...state,
        mentorProfile: {
          ...state.mentorProfile,
          interest: { ...state.mentorProfile.interest, message: payload },
        },
      };
    case types.MENTOR_PROFILE_INTERESTS_SUCCESS:
      return {
        ...state,
        mentorProfile: {
          ...state.mentorProfile,
          interest: { ...state.mentorProfile.interest, interests: payload },
        },
      };
    case types.MENTOR_PROFILE_INTERESTS_ERROR:
      return {
        ...state,
        mentorProfile: {
          ...state.mentorProfile,
          interest: { ...state.mentorProfile.interest, error: payload },
        },
      };
    case types.MENTOR_PROFILE_INTERESTS_LOADED:
      return {
        ...state,
        mentorProfile: {
          ...state.mentorProfile,
          interest: { ...state.mentorProfile.interest, loaded: payload },
        },
      };
    case types.UPDATE_MENTOR_PROFILE_INTERESTS_SUCCESS:
      return {
        ...state,
        mentorProfile: {
          ...state.mentorProfile,
          interest: { ...state.mentorProfile.interest, interests: payload },
        },
      };

    //Categories
    case types.CATEGORIES_LOADER:
      return { ...state, categories: { ...state.categories, loader: payload } };
    case types.CATEGORIES_MESSAGE:
      return {
        ...state,
        categories: { ...state.categories, message: payload },
      };
    case types.CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: { ...state.categories, categories: payload },
      };
    case types.CATEGORIES_ERROR:
      return { ...state, categories: { ...state.categories, error: payload } };
    case types.CATEGORIES_LOADED:
      return { ...state, categories: { ...state.categories, loaded: payload } };
    case types.LOAD_MORE_CATEGORIES:
      return {
        ...state,
        categories: { ...state.categories, loadMore: payload },
      };
    case types.HAS_MORE_CATEGORIES:
      return {
        ...state,
        categories: { ...state.categories, hasMore: payload },
      };
    case types.CATEGORIES_LOADED_PAGE:
      return {
        ...state,
        categories: { ...state.categories, loadedPage: payload },
      };

    //Story
    case types.STORY_LOADER:
      return { ...state, story: { ...state.story, loader: payload } };
    case types.STORY_MESSAGE:
      return { ...state, story: { ...state.story, message: payload } };
    case types.STORY_SUCCESS:
      return { ...state, story: { ...state.story, story: payload } };
    case types.STORY_ERROR:
      return { ...state, story: { ...state.story, error: payload } };
    case types.STORY_LOADED:
      return { ...state, story: { ...state.story, loaded: payload } };

    case types.ADD_STORY_SUCCESS:
      return {
        ...state,
        journal: {
          ...state.journal,
          publicStories: state.journal.publicLoaded
            ? payload.is_public
              ? [payload, ...state.journal.publicStories]
              : state.journal.publicStories
            : [],
          privateStories: state.journal.privateLoaded
            ? !payload.is_public
              ? [payload, ...state.journal.privateStories]
              : state.journal.privateStories
            : [],
        },
        home: {
          ...state.home,
          stories: state.home.storiesLoaded
            ? payload.is_public
              ? [payload, ...state.home.stories]
              : state.home.stories
            : [],
        },
        profile: {
          ...state.profile,
          journal: {
            ...state.profile.journal,
            publicStories: state.profile.journal.publicLoaded
              ? payload.is_public
                ? [payload, ...state.profile.journal.publicStories]
                : state.profile.journal.publicStories
              : [],
            privateStories: state.profile.journal.privateLoaded
              ? !payload.is_public
                ? [payload, ...state.profile.journal.privateStories]
                : state.profile.journal.privateStories
              : [],
          },
        },
      };

    case types.EDIT_STORY_SUCCESS:
      return {
        ...state,
        journal: {
          ...state.journal,
          publicStories: state.journal.publicLoaded
            ? state.story.story!.is_public
              ? payload.is_public
                ? [
                    ...(
                      state.journal.publicStories as Array<StoryResponse>
                    ).map((item) => (item.id === payload.id ? payload : item)),
                  ]
                : state.journal.publicStories.filter(
                    (item) => item.id !== payload.id
                  )
              : payload.is_public
              ? [payload, ...state.journal.publicStories]
              : state.journal.publicStories
            : [],
          privateStories: state.journal.privateLoaded
            ? !state.story.story!.is_public
              ? !payload.is_public
                ? [
                    ...(
                      state.journal.privateStories as Array<StoryResponse>
                    ).map((item) => (item.id === payload.id ? payload : item)),
                  ]
                : state.journal.privateStories.filter(
                    (item) => item.id !== payload.id
                  )
              : !payload.is_public
              ? [payload, ...state.journal.privateStories]
              : state.journal.privateStories
            : [],
        },
        home: {
          ...state.home,
          stories: state.home.storiesLoaded
            ? state.story.story!.is_public
              ? payload.is_public
                ? [
                    ...(state.home.stories as Array<StoryResponse>).map(
                      (item) => (item.id === payload.id ? payload : item)
                    ),
                  ]
                : state.home.stories.filter((item) => item.id !== payload.id)
              : payload.is_public
              ? [payload, ...state.home.stories]
              : state.home.stories
            : [],
        },
        story: { ...state.story, story: payload },
      };

    case types.DELETE_STORY_SUCCESS:
      return {
        ...state,
        journal: {
          ...state.journal,
          publicStories: state.journal.publicLoaded
            ? [
                ...state.journal.publicStories.filter(
                  (item) => item.id !== payload
                ),
              ]
            : [],
          privateStories: state.journal.privateLoaded
            ? [
                ...state.journal.privateStories.filter(
                  (item) => item.id !== payload
                ),
              ]
            : [],
        },
        home: {
          ...state.home,
          stories: state.home.storiesLoaded
            ? [...state.home.stories.filter((item) => item.id !== payload)]
            : [],
        },
        story: {
          ...state.story,
          loaded: state.story.loaded ? false : state.story.loaded,
          message: state.story.loaded
            ? "Story has been deleted"
            : state.story.message,
        },
      };

    case types.REACT_STORY_SUCCESS:
      return {
        ...state,
        journal: {
          ...state.journal,
          publicStories: state.journal.publicLoaded
            ? payload.is_public
              ? [
                  ...(state.journal.publicStories as Array<StoryResponse>).map(
                    (item) => (item.id === payload.id ? payload : item)
                  ),
                ]
              : state.journal.publicStories
            : [],
          privateStories: state.journal.privateLoaded
            ? !payload.is_public
              ? [
                  ...(state.journal.privateStories as Array<StoryResponse>).map(
                    (item) => (item.id === payload.id ? payload : item)
                  ),
                ]
              : state.journal.privateStories
            : [],
        },
        home: {
          ...state.home,
          stories: state.home.storiesLoaded
            ? payload.is_public
              ? [
                  ...(state.home.stories as Array<StoryResponse>).map((item) =>
                    item.id === payload.id ? payload : item
                  ),
                ]
              : state.home.stories
            : [],
        },
        story: { ...state.story, story: payload },
      };

    //Groups
    case types.GROUPS_LOADER:
      return { ...state, groups: { ...state.groups, loader: payload } };
    case types.GROUPS_MESSAGE:
      return { ...state, groups: { ...state.groups, message: payload } };
    case types.GROUPS_SUCCESS:
      return { ...state, groups: { ...state.groups, groups: payload } };
    case types.GROUPS_ERROR:
      return { ...state, groups: { ...state.groups, error: payload } };
    case types.GROUPS_LOADED:
      return { ...state, groups: { ...state.groups, loaded: payload } };
    case types.LOAD_MORE_GROUPS:
      return { ...state, groups: { ...state.groups, loadMore: payload } };
    case types.HAS_MORE_GROUPS:
      return { ...state, groups: { ...state.groups, hasMore: payload } };
    case types.GROUPS_LOADED_PAGE:
      return { ...state, groups: { ...state.groups, loadedPage: payload } };

    //Interests
    case types.INTERESTS_LOADER:
      return { ...state, interests: { ...state.interests, loader: payload } };
    case types.INTERESTS_MESSAGE:
      return { ...state, interests: { ...state.interests, message: payload } };
    case types.INTERESTS_SUCCESS:
      return {
        ...state,
        interests: { ...state.interests, interests: payload },
      };
    case types.INTERESTS_ERROR:
      return { ...state, interests: { ...state.interests, error: payload } };
    case types.INTERESTS_LOADED:
      return { ...state, interests: { ...state.interests, loaded: payload } };

    case types.UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          friends: [
            ...(state.friends.friends as Array<UserResponseFriendRequest>).map(
              (item) =>
                item.user.id === payload
                  ? {
                      ...item,
                      is_blocked: false,
                    }
                  : item
            ),
          ],
        },
      };

    //Girls
    case types.GIRLS_LOADER:
      return { ...state, girls: { ...state.girls, loader: payload } };
    case types.GIRLS_MESSAGE:
      return { ...state, girls: { ...state.girls, message: payload } };
    case types.GIRLS_SUCCESS:
      return { ...state, girls: { ...state.girls, girls: payload } };
    case types.GIRLS_ERROR:
      return { ...state, girls: { ...state.girls, error: payload } };
    case types.GIRLS_LOADED:
      return { ...state, girls: { ...state.girls, loaded: payload } };
    case types.LOAD_MORE_GIRLS:
      return { ...state, girls: { ...state.girls, loadMore: payload } };
    case types.HAS_MORE_GIRLS:
      return { ...state, girls: { ...state.girls, hasMore: payload } };
    case types.GIRLS_LOADED_PAGE:
      return { ...state, girls: { ...state.girls, loadedPage: payload } };

    default:
      return { ...state };
  }
}
