import {
    UserProfileResponse, StoryResponse, UserResponseFriendRequest, AnnouncementResponse,
    ResourceResponse, ConnectMentorResponse, InterestResponse, GroupResponse
} from "../../services/api.response.service";

export interface HomeState{
    loader: boolean
    error: boolean
    message: string

    //Stories
    storiesLoader: boolean
    storiesError: boolean
    stories: [] | Array<StoryResponse>
    storiesLoaded: boolean
    loadMoreStories: boolean
    hasMoreStories: boolean
    storiesLoadedPage: number

    //Featured Mentors
    featuredMentorsLoader: boolean
    featuredMentorsError: boolean
    featuredMentors: [] | Array<UserProfileResponse>
    featuredMentorsLoaded: boolean
    loadMoreFeaturedMentors: boolean
    hasMoreFeaturedMentors: boolean
    featuredMentorsLoadedPage: number

    //Requested Connections
    friendRequestsLoader: boolean
    friendRequestsError: boolean
    friendRequests: [] | Array<UserResponseFriendRequest>
    friendRequestsLoaded: boolean
    loadMoreFriendRequests: boolean
    hasMoreFriendRequests: boolean
    friendRequestsLoadedPage: number

    //Suggested Connections
    suggestedFriendsLoader: boolean
    suggestedFriendsError: boolean
    suggestedFriends: [] | Array<UserProfileResponse>
    suggestedFriendsLoaded: boolean
    loadMoreSuggestedFriends: boolean
    hasMoreSuggestedFriends: boolean
    suggestedFriendsLoadedPage: number
    suggestedFriendsAddRequest: Array<string>
}

export interface FriendsState{
    loader: boolean
    error: boolean
    message: string
    friends: [] | Array<UserResponseFriendRequest>
    loaded: boolean
    loadMore: boolean
    hasMore: boolean
    loadedPage: number
}

export interface JournalState{
    publicLoader: boolean
    publicError: boolean
    publicMessage: string
    publicStories: [] | Array<StoryResponse>
    publicLoaded: boolean
    loadMorePublic: boolean
    hasMorePublic: boolean
    publicLoadedPage: number

    privateLoader: boolean
    privateError: boolean
    privateMessage: string
    privateStories: [] | Array<StoryResponse>
    privateLoaded: boolean
    loadMorePrivate: boolean
    hasMorePrivate: boolean
    privateLoadedPage: number
}

export interface AnnouncementState{
    loader: boolean
    error: boolean
    message: string
    announcements: [] | Array<AnnouncementResponse>
    loaded: boolean
    loadMore: boolean
    hasMore: boolean
    loadedPage: number
}

export interface ResourceState{
    //
    resourceImagesLoader: boolean
    resourceImagesError: boolean
    resourceImagesMessage: string
    resourceImages: [] | Array<ResourceResponse>
    resourceImagesLoaded: boolean
    loadMoreResourceImages: boolean
    hasMoreResourceImages: boolean
    resourceImagesLoadedPage: number

    resourceAudiosLoader: boolean
    resourceAudiosError: boolean
    resourceAudiosMessage: string
    resourceAudios: [] | Array<ResourceResponse>
    resourceAudiosLoaded: boolean
    loadMoreResourceAudios: boolean
    hasMoreResourceAudios: boolean
    resourceAudiosLoadedPage: number

    resourceVideosLoader: boolean
    resourceVideosError: boolean
    resourceVideosMessage: string
    resourceVideos: [] | Array<ResourceResponse>
    resourceVideosLoaded: boolean
    loadMoreResourceVideos: boolean
    hasMoreResourceVideos: boolean
    resourceVideosLoadedPage: number

    resourceDocumentsLoader: boolean
    resourceDocumentsError: boolean
    resourceDocumentsMessage: string
    resourceDocuments: [] | Array<ResourceResponse>
    resourceDocumentsLoaded: boolean
    loadMoreResourceDocuments: boolean
    hasMoreResourceDocuments: boolean
    resourceDocumentsLoadedPage: number

    resourceUrlsLoader: boolean
    resourceUrlsError: boolean
    resourceUrlsMessage: string
    resourceUrls: [] | Array<ResourceResponse>
    resourceUrlsLoaded: boolean
    loadMoreResourceUrls: boolean
    hasMoreResourceUrls: boolean
    resourceUrlsLoadedPage: number
}

export interface MentorsState{
    loader: boolean
    error: boolean
    message: string
    mentors: [] | Array<ConnectMentorResponse>
    loaded: boolean
    loadMore: boolean
    hasMore: boolean
    loadedPage: number
}

export interface ProfileState{
    loader: boolean
    error: boolean
    message: string
    profile: null | UserProfileResponse
    loaded: boolean
    journal: JournalState
    interest: InterestState
}

export interface MentorProfileState{
    loader: boolean
    error: boolean
    message: string
    profile: null | UserProfileResponse
    loaded: boolean
    interest: InterestState
}

export interface CategoriesState{
    loader: boolean
    error: boolean
    message: string
    categories: [] | Array<InterestResponse>
    loaded: boolean
    loadMore: boolean
    hasMore: boolean
    loadedPage: number
}

export interface StoryState{
    loader: boolean
    error: boolean
    message: string
    story: null | StoryResponse
    loaded: boolean
}

export interface GroupsState{
    loader: boolean
    error: boolean
    message: string
    groups: [] | Array<GroupResponse>
    loaded: boolean
    loadMore: boolean
    hasMore: boolean
    loadedPage: number
}

export interface InterestState{
    loader: boolean
    error: boolean
    message: string
    interests: [] | Array<InterestResponse>
    loaded: boolean
}

export interface GirlsState{
    loader: boolean
    error: boolean
    message: string
    girls: [] | Array<ConnectMentorResponse>
    loaded: boolean
    loadMore: boolean
    hasMore: boolean
    loadedPage: number
}

const home: HomeState = {
    loader: true,
    error: false,
    message: '',

    //Stories
    storiesLoader: true,
    storiesError: false,
    stories: [],
    storiesLoaded: false,
    loadMoreStories: false,
    hasMoreStories: false,
    storiesLoadedPage: 0,

    //Featured Mentors
    featuredMentorsLoader: true,
    featuredMentorsError: false,
    featuredMentors: [],
    featuredMentorsLoaded: false,
    loadMoreFeaturedMentors: false,
    hasMoreFeaturedMentors: false,
    featuredMentorsLoadedPage: 0,

    //Requested Connections
    friendRequestsLoader: true,
    friendRequestsError: false,
    friendRequests: [],
    friendRequestsLoaded: false,
    loadMoreFriendRequests: false,
    hasMoreFriendRequests: false,
    friendRequestsLoadedPage: 0,

    //Suggested Connections
    suggestedFriendsLoader: true,
    suggestedFriendsError: false,
    suggestedFriends: [],
    suggestedFriendsLoaded: false,
    loadMoreSuggestedFriends: false,
    hasMoreSuggestedFriends: false,
    suggestedFriendsLoadedPage: 0,
    suggestedFriendsAddRequest: [],
}

const friends:FriendsState ={
    loader: true,
    error: false,
    message: '',
    friends: [],
    loaded: false,
    loadMore: false,
    hasMore: false,
    loadedPage: 0,
}

const journal: JournalState = {
    publicLoader: true,
    publicError: false,
    publicMessage: '',
    publicStories: [],
    publicLoaded: false,
    loadMorePublic: false,
    hasMorePublic: false,
    publicLoadedPage: 0,

    privateLoader: true,
    privateError: false,
    privateMessage: '',
    privateStories: [],
    privateLoaded: false,
    loadMorePrivate: false,
    hasMorePrivate: false,
    privateLoadedPage: 0
}

const announcements:AnnouncementState ={
    loader: true,
    error: false,
    message: '',
    announcements: [],
    loaded: false,
    loadMore: false,
    hasMore: false,
    loadedPage: 0,
}

const resources:ResourceState ={
    resourceImagesLoader: true,
    resourceImagesError: false,
    resourceImagesMessage: '',
    resourceImages: [],
    resourceImagesLoaded: false,
    loadMoreResourceImages: false,
    hasMoreResourceImages: false,
    resourceImagesLoadedPage: 0,

    resourceAudiosLoader: true,
    resourceAudiosError: false,
    resourceAudiosMessage: '',
    resourceAudios: [],
    resourceAudiosLoaded: false,
    loadMoreResourceAudios: false,
    hasMoreResourceAudios: false,
    resourceAudiosLoadedPage: 0,

    resourceVideosLoader: true,
    resourceVideosError: false,
    resourceVideosMessage: '',
    resourceVideos: [],
    resourceVideosLoaded: false,
    loadMoreResourceVideos: false,
    hasMoreResourceVideos: false,
    resourceVideosLoadedPage: 0,

    resourceDocumentsLoader: true,
    resourceDocumentsError: false,
    resourceDocumentsMessage: '',
    resourceDocuments: [],
    resourceDocumentsLoaded: false,
    loadMoreResourceDocuments: false,
    hasMoreResourceDocuments: false,
    resourceDocumentsLoadedPage: 0,

    resourceUrlsLoader: true,
    resourceUrlsError: false,
    resourceUrlsMessage: '',
    resourceUrls: [],
    resourceUrlsLoaded: false,
    loadMoreResourceUrls: false,
    hasMoreResourceUrls: false,
    resourceUrlsLoadedPage: 0,
}

const mentors:MentorsState ={
    loader: true,
    error: false,
    message: '',
    mentors: [],
    loaded: false,
    loadMore: false,
    hasMore: false,
    loadedPage: 0,
}

const profile:ProfileState ={
    loader: true,
    error: false,
    message: '',
    profile: null,
    loaded: false,
    journal: {
        publicLoader: true,
        publicError: false,
        publicMessage: '',
        publicStories: [],
        publicLoaded: false,
        loadMorePublic: false,
        hasMorePublic: false,
        publicLoadedPage: 0,

        privateLoader: true,
        privateError: false,
        privateMessage: '',
        privateStories: [],
        privateLoaded: false,
        loadMorePrivate: false,
        hasMorePrivate: false,
        privateLoadedPage: 0
    },
    interest: {
        loader: true,
        error: false,
        message: '',
        interests: [],
        loaded: false,
    }
}

const mentorProfile:MentorProfileState ={
    loader: true,
    error: false,
    message: '',
    profile: null,
    loaded: false,
    interest: {
        loader: true,
        error: false,
        message: '',
        interests: [],
        loaded: false,
    }
}

const categories:CategoriesState ={
    loader: true,
    error: false,
    message: '',
    categories: [],
    loaded: false,
    loadMore: false,
    hasMore: false,
    loadedPage: 0,
}

const story:StoryState ={
    loader: true,
    error: false,
    message: '',
    story: null,
    loaded: false,
}

const groups:GroupsState ={
    loader: true,
    error: false,
    message: '',
    groups: [],
    loaded: false,
    loadMore: false,
    hasMore: false,
    loadedPage: 0,
}

const interests:InterestState ={
    loader: true,
    error: false,
    message: '',
    interests: [],
    loaded: false,
}

const girls:GirlsState ={
    loader: true,
    error: false,
    message: '',
    girls: [],
    loaded: false,
    loadMore: false,
    hasMore: false,
    loadedPage: 0,
}

export interface MainState {
    home: HomeState
    friends: FriendsState
    journal: JournalState
    announcements: AnnouncementState
    resources: ResourceState
    mentors: MentorsState
    profile:ProfileState
    mentorProfile:MentorProfileState
    categories:CategoriesState
    story:StoryState
    groups:GroupsState
    interests:InterestState
    girls:GirlsState
}

const main: MainState = {
    home,friends,journal,announcements,resources,mentors,profile,mentorProfile,categories,story,groups,interests,girls
}

export default main