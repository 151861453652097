import React from "react";
import { Link } from "react-router-dom";
import { CoursesResponse } from "../../services/api.response.service";

interface props {
  course: CoursesResponse;
}

const Courses = ({ course }: props) => {
  return (
    <>
      <div className="col-sm-6 col-md-4 col-xl-3 col-lg-3* pb-4">
        <div
          className="card"
          style={{
            // width: "200px",
            borderRadius: "19px",
            height: "250px",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${course.banner})`,
              height: "156px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              backgroundPosition: "center",
            }}
          ></div>

          <div className="card-body">
            <Link to={`/lms/courses/${course.id}`}>
              <h5 className="card-title text-truncate">{course?.title}</h5>
            </Link>
            <p className="card-text text-truncate">{course?.description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
