import React from "react";
import Courses from "./Courses";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { CoursesResponse } from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import { useSelector } from "react-redux";
import Load from "../Load";
import NoData from "../NoData";

const PopularCourses = () => {
  const state = useSelector((state: IRootState) => state.mainSub);
  const courses: BaseState<Array<CoursesResponse>> = state[StateKeys.COURSES];

  const { data, pageState } = courses;

  return (
    <>
      {" "}
      <div className="col-xl-12">
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
          <div className="mb-0 mt-0 d-flex justify-content-between w-100 p-5 pb-0">
            <h2 className="fw-700 font-md text-grey-900">
              Popular <small className="fw-normal">Courses</small>
            </h2>

            <a href="#">See All </a>
          </div>

          <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs*">
            <div className="p-5 ">
              {pageState === AppState.LOADED ||
              pageState === AppState.LOADING_MORE ? (
                data.length > 0 ? (
                  <div className="row">
                    {data.map((course: CoursesResponse) => (
                      <Courses course={course} key={course.id} />
                    ))}{" "}
                  </div>
                ) : (
                  <NoData message="No Courses Available" />
                )
              ) : (
                <Load message="Loading" noMarginTop={true} />
              )}
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default PopularCourses;
