import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import auth from "./auth.saga";
import main from "./main.saga";
import submain from "./submain.saga";

function* allSagas() {
  yield all([auth(), main(), submain()]);
}

const sagas = [allSagas];

const sagaMiddleware = createSagaMiddleware();

export function runSagas() {
  sagas.forEach((saga) => sagaMiddleware.run(saga));
}

export default sagaMiddleware;
