import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import subMain from "../../redux/actions/mainn.action";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import {
  CategoryResponse,
  CoursesResponse,
  CourseTopicsResponse,
  SubscriptionCourseResponse,
} from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import Load from "../Load";
import Category from "./Category";
import CourseTopic from "./CourseTopics";

const Course = () => {
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const course: BaseState<CoursesResponse> = state[StateKeys.COURSE];
  const subscriptionCourse: BaseState<SubscriptionCourseResponse> =
    state[StateKeys.SUBSCRIPTION_COURSE];
  const course_topics: BaseState<Array<CourseTopicsResponse>> =
    state[StateKeys.COURSE_TOPICS];
  const [hasSubscribed, setHasSubscribed] = useState<boolean>(false);

  const { data, pageState } = course;

  useEffect(() => {
    if (pageState !== AppState.LOADED || data?.id !== id) {
      dispatch(
        subMain.getPageObjectData({
          stateKey: StateKeys.COURSE,
          apiEndpoint: ApiEndpoint.COURSE(id),
          clear: true,
        })
      );

      dispatch(
        subMain.getPageArrayData({
          stateKey: StateKeys.COURSE_TOPICS,
          apiEndpoint: ApiEndpoint.COURSE_TOPICS(id),
          clear: true,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setHasSubscribed(data.has_subscribed);
  }, [data]);

  useEffect(() => {
    const { data } = subscriptionCourse;
    if (data.course) {
      setHasSubscribed(data.course.has_subscribed);
    }
  }, [subscriptionCourse]);

  const Subscribe = () => {
    hasSubscribed
      ? dispatch(
          subMain.postPageData({
            stateKey: StateKeys.SUBSCRIPTION_COURSE,
            apiEndpoint: ApiEndpoint.UNSUBSCRIBE_COURSE(data?.id),
            req: "",
          })
        )
      : dispatch(
          subMain.postPageData({
            stateKey: StateKeys.SUBSCRIPTION_COURSE,
            apiEndpoint: ApiEndpoint.SUBSCRIBE_COURSE(data?.id),
            req: "",
          })
        );
  };

  return (
    <>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {pageState === AppState.LOADED && data ? (
              <>
                <div className="col-xl-12 mb-3">
                  <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                      <img
                        src={data.banner}
                        className="w-100 rounded-3"
                        style={{ width: "100%" }}
                        alt="user"
                      />
                    </div>
                    <div className="card-body p-0 position-relative">
                      <figure
                        className="avatar position-absolute w100 z-index-1"
                        style={{ top: "-40px", left: "30px" }}
                      >
                        <img
                          src={data.logo}
                          alt="user"
                          className="float-right p-1 bg-white rounded-circle  w100 h100"
                        />
                      </figure>
                      <h4 className="fw-700 font-sm mt-2 mb-lg-4 mb-3 pl-15">
                        <div className="d-flex justify-content-between pe-4">
                          <div>
                            {data.title}
                            <span className="fw-500 font-xssss text-grey-500 mt-1 d-block">
                              <i className="ti-user me-1"></i>{" "}
                              {data.author.name}
                            </span>
                          </div>

                          <button
                            className="btn bg-primary-gradiant text-white btn-sm"
                            onClick={() => Subscribe()}
                            disabled={
                              subscriptionCourse.pageState === AppState.LOADING
                            }
                          >
                            {hasSubscribed ? "Unsubscribe" : "Subscribe"}
                          </button>
                        </div>

                        <div className="w-100 mt-2">
                          <div className="d-block pt-4">
                            <h4 className="fw-700 mb-1 font-xsss text-grey-900">
                              Description
                            </h4>
                            <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                              {data.description}
                            </p>
                          </div>
                        </div>
                      </h4>
                    </div>
                    {data.categories && (
                      <div className="pt-3">
                        <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                          <div className="d-flex justify-content-start align-items-center gap-2 p-3 ">
                            <h3 className="pe-3">Categories</h3>
                            {data.categories.map(
                              (category: CategoryResponse) => (
                                <Category category={category} />
                              )
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Load message="Loading" noMarginTop={true} />
            )}{" "}
            {course_topics.pageState === AppState.LOADED &&
            course_topics.data ? (
              <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs*">
                  <div className="p-5">
                    <div className="row">
                      <h3>Course Topics</h3>
                      {course_topics.data?.map(
                        (topic: CourseTopicsResponse) => (
                          <CourseTopic
                            topic={topic}
                            hasSubscribed={hasSubscribed}
                          />
                        )
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Load message="Loading" noMarginTop={true} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Course;
