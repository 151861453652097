import React from "react";
import { Link } from "react-router-dom";

import { CategoryResponse } from "../../services/api.response.service";

interface props {
  category: CategoryResponse;
}

const Category = ({ category }: props) => {
  return (
    <>
      {" "}
      <div id="Category">
        <Link
          to={`/lms/categories/${category.id}/courses`}
          key={category.id}
          className="badge rounded-pill text-bg-danger p-2 px-3 text-decoration-none text-white"
          style={{ background: `${category.colour}` }}
        >
          {category.title}
        </Link>
      </div>{" "}
    </>
  );
};

export default Category;
