import React, {FormEvent, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states"
import Actions from "../../redux/actions";
import Load from '../../components/Load';
import {Link, Redirect} from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import {Reference} from "../../services/api.response.service";

const scheduleZone = new Date().toTimeString().slice(9,17)
function VerifyMentorProfile({history}: RouteComponentProps) {
    const dispatch = useDispatch()
    const {user} = useSelector(((state: IRootState) => state.setting))
    const [scheduleDate, setScheduleDate] = useState('')
    const [references, setReferences] = useState<Array<Reference>>([])

    const {
        loaderIds
    } = useSelector(((state: IRootState) => state.setting))

    const loadingRequest = () => loaderIds.includes(user!.id)

    const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            schedule_date: scheduleDate,
            schedule_zone: scheduleZone,
            references
        }
        dispatch(Actions.main.setVerifyMentorProfile(user!, data))
    }
    if(loaderIds.includes('verifyMentorProfileSaved')){
        dispatch(Actions.setting.removeLoaderId('verifyMentorProfileSaved'))
        history.push(`/mentor/profile/${user!.id}`)
    }
    if(!user!.mentor_profile){
        return <Redirect to={{pathname: `/verify/mentor/account`}} />
    }
    return <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
                <div className="middle-wrap">
                    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                        <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                            <Link to={`/mentor/profile/${user?.id}`} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"/></Link>
                            <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Verify Account</h4>
                        </div>
                        <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">Schedule a date</label>
                                            <input type="datetime-local" className="style2-input form-control text-grey-900 font-xsss fw-600" defaultValue={scheduleDate} required
                                                   onChange={(e)=>setScheduleDate(e.target.value)}
                                                   disabled={loadingRequest()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">References</label>
                                            {references.length > 0 && references.map((item,i)=>
                                                <div className="d-flex mb-2" key={`references-${i}`}>
                                                    <div className="row">
                                                        <div className="col-lg-12 mb-1">
                                                            <div className="form-group">
                                                                <input type="text" className="style2-input form-control text-grey-900 font-xsss fw-600" placeholder="Name"
                                                                       onChange={(event)=>setReferences([...references.map((e,j)=> i === j ? {...e, name: event.target.value}:e)])}
                                                                       value={item.name} required disabled={loadingRequest()}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-1">
                                                            <div className="form-group">
                                                                <input type="email" className="style2-input form-control text-grey-900 font-xsss fw-600" placeholder="Email"
                                                                       onChange={(event)=>setReferences([...references.map((e,j)=> i === j ? {...e, email: event.target.value}:e)])}
                                                                       value={item.email} required disabled={loadingRequest()}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-1">
                                                            <div className="form-group">
                                                                <input type="tel" className="style2-input form-control text-grey-900 font-xsss fw-600" placeholder="Phone number"
                                                                       onChange={(event)=>setReferences([...references.map((e,j)=> i === j ? {...e, phone_number: event.target.value}:e)])}
                                                                       value={item.phone_number} disabled={loadingRequest()}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="d-block ms-1 ls-3" onClick={()=>setReferences([...references.filter((e,j)=> j !== i)])}>
                                                        <i className={`feather-minus bg-danger btn-round-sm font-md text-white`}/>
                                                    </span>
                                                </div>
                                            )}
                                            <span className="d-block ms-1 ls-3" onClick={()=>setReferences([...references, {name:'',email:'',phone_number:''}])}>
                                                <i className={`feather-plus bg-success btn-round-sm font-md text-white`}/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {loadingRequest() ? <Load message="Submitting"/> :
                                        <button type="submit" className={`${references.length === 0 ? 'bg-grey text-black':'bg-current text-white'} text-center font-xsss fw-600 p-3 w175 rounded-3 d-inline-block`} disabled={references.length === 0}>
                                            Submit
                                        </button>}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
}

export default VerifyMentorProfile;