import React, { useEffect } from "react";
import {
  GroupResponse,
  UserProfileResponse,
} from "../../services/api.response.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import { AppState } from "../../services/app.service";
import { Link } from "react-router-dom";

interface props {
  group: GroupResponse;
  setOpenGroup: (trigger: boolean) => void;
}

const GroupProfile = ({ group, setOpenGroup }: props) => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const {
    pageState,
    data: users,
    message,
  }: BaseState<Array<UserProfileResponse>> = state[
    StateKeys.GROUP_PARTICIPANTS
  ];

  useEffect(() => {
    dispatch(
      subMain.getPageArrayData({
        stateKey: StateKeys.GROUP_PARTICIPANTS,
        apiEndpoint: ApiEndpoint.GET_GROUP_PARTICIPANTS(
          group?.conversation.id,
          1,
          ""
        ),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <>
      {" "}
      <section id="GroupProfile">
        <div style={{ height: "100vw" }} className="">
          <div className="sticky">
            <div className="d-flex justify-content-start align-items-center gap-3 border-bottom px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenGroup(false)}
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>

              <h5 className="pt-2">Group Info</h5>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center gap-3 mb-4 pt-3 border-bottom">
              <div>
                <img
                  src={group?.image_url}
                  alt={group?.title}
                  className="border-secondary border"
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: "50%",
                  }}
                />
              </div>

              <div>
                <h2>{group?.title}</h2>
              </div>
            </div>

            <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg px-3">
              <div className="pb-3">
                <small className="text-dark" style={{ fontSize: "12px" }}>
                  About Group:
                </small>
                <p className="text-dark">{group?.description}</p>
              </div>
              <div className="chat-body mb-3">
                <small className="text-dark" style={{ fontSize: "12px" }}>
                  Group members:
                </small>
                <div className="messages-content pb-5 mb-5">
                  {pageState === AppState.LOADED ? (
                    <>
                      {" "}
                      {users.map((user: UserProfileResponse) => (
                        <ul className="list-group list-group-flush ">
                          {" "}
                          <li
                            className="list-group-item"
                            style={{ cursor: "pointer" }}
                            //   onClick={() => setGetGroup(groupChats)}
                          >
                            <div className="d-flex justify-content-start align-items-center gap-3 mb-3">
                              <div>
                                <img
                                  src={user?.photo_url}
                                  alt=""
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </div>

                              <div className="pt-2*">
                                <Link
                                  to={`/profile/${user.id}`}
                                  className="card-title text-decoration-none text-dark text-truncate m-0 p-0"
                                >
                                  {user?.first_name} {user?.last_name}
                                </Link>
                                <br />
                                <small
                                  className="p-0 m-0"
                                  style={{ fontSize: "11px" }}
                                >
                                  {user?.age}yrs. {user?.city}{" "}
                                </small>
                              </div>
                            </div>{" "}
                          </li>
                        </ul>
                      ))}
                    </>
                  ) : (
                    <div
                      className="snippet mt-5 ms-auto me-auto"
                      data-title=".dot-typing"
                    >
                      <div className="stage">
                        <div className="dot-typing" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>{" "}
    </>
  );
};

export default GroupProfile;
