import React from "react";
import { GroupResponse } from "../../services/api.response.service";
import { Link } from "react-router-dom";

interface props {
  groupChats: GroupResponse;
  setGetGroup: (id: GroupResponse) => void;
}

const Groups = ({ groupChats, setGetGroup }: props) => {
  return (
    <>
      <li
        className="list-group-item"
        style={{ cursor: "pointer" }}
        onClick={() => setGetGroup(groupChats)}
      >
        <div className="d-flex justify-content-start align-items-center gap-3 mb-3">
          <div>
            <img
              src={groupChats.image_url}
              alt=""
              className="border-secondary border"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
              }}
            />
          </div>

          <div className="pt-2">
            <h5 className="card-title text-truncate">{groupChats?.title}</h5>
            <small className="text-dark" style={{ fontSize: "11px" }}>
              {groupChats?.conversation?.last_message.sender.first_name}{" "}
              {groupChats?.conversation?.last_message.sender.last_name}:{" "}
              {groupChats?.conversation?.last_message.body}
            </small>
          </div>
        </div>{" "}
      </li>
    </>
  );
};

export default Groups;
