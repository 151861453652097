import rootState from "../states";
import { types } from "../actions/auth.action";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = rootState.auth, { type, payload }: any) {
  switch (type) {
    //Login
    case types.LOGIN_LOADER:
      return { ...state, login: { ...state.login, loader: payload } };
    case types.LOGIN_ERROR:
      return { ...state, login: { ...state.login, error: payload } };
    case types.LOGIN_MESSAGE:
      return { ...state, login: { ...state.login, message: payload } };
    //Register
    case types.REGISTER_LOADER:
      return { ...state, register: { ...state.register, loader: payload } };
    case types.REGISTER_ERROR:
      return { ...state, register: { ...state.register, error: payload } };
    case types.REGISTER_ERROR_INPUT:
      return {
        ...state,
        register: { ...state.register, errorInput: payload },
      };
    case types.REGISTER_MESSAGE:
      return { ...state, register: { ...state.register, message: payload } };
    //Forgot password
    case types.FORGOT_PASSWORD_LOADER:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, loader: payload },
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, error: payload },
      };
    case types.FORGOT_PASSWORD_MESSAGE:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, message: payload },
      };

    //Delete Account
    case types.DELETE_ACCOUNT_STATE:
      return {
        ...state,
        deleteAccount: { ...state.deleteAccount, state: payload },
      };
    case types.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccount: { ...state.deleteAccount, error: payload },
      };
    case types.DELETE_ACCOUNT_MESSAGE:
      return {
        ...state,
        deleteAccount: { ...state.deleteAccount, message: payload },
      };
    default:
      return { ...state };
  }
}
