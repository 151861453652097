import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states"
import Actions from "../../redux/actions";
import Load from '../../components/Load';
import NoData from "../../components/NoData";
import {ConnectMentorResponse, InterestResponse, StoryResponse} from "../../services/api.response.service";
import {Link} from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import Profilephoto from '../../components/Profilephoto';
import CreateStory from "../../components/CreateStory";
import Story from "../../components/Story";
import {UserResponseFriendRequest} from "../../../store/api.response.type";

function PageNotFound({location}: RouteComponentProps) {
    // @ts-ignore
    const message = location.state?.message ?? 'This page is cannot be found'
    // @ts-ignore
    const image = location.state?.image ?? ''
    return <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
                <div className="row">
                    <NoData message={message} image={image}/>
                </div>
            </div>
        </div>
    </div>
}

export default PageNotFound;