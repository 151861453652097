import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { ConnectMentorResponse } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import subMain from "../../redux/actions/mainn.action";

function Mentors() {
  const dispatch = useDispatch();

  const state = useSelector((state: IRootState) => state.mainSub);
  const mentors: BaseState<Array<ConnectMentorResponse>> =
    state[StateKeys.MENTORS];

  const { data, pageState, hasMore, currentPage } = mentors;

  useEffect(() => {
    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.MENTORS,
          apiEndpoint: ApiEndpoint.MENTORS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  console.log(data);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <div className="mb-0 mt-0 d-flex align-items-center">
                  <h2 className="fw-700 font-md text-grey-900">My Mentors</h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                    <div className="search-form-2 ms-2">
                                        <i className="ti-search font-xss"/>
                                        <input type="text" className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                                    </div>
                                </form>*/}
                </div>
              </div>

              <div className="row ps-2 pe-1">
                {pageState === AppState.LOADED ? (
                  data.length > 0 ? (
                    <>
                      {(data as Array<ConnectMentorResponse>).map((item) => (
                        <div
                          key={item.connection_id}
                          className="col-md-3 col-xss-6 pe-2 ps-2"
                        >
                          <div
                            /*className="card h300 d-block border-0 shadow-xss rounded-3 bg-white-overlay overflow-hidden mb-3 bg-image-cover"
                                                style={{backgroundImage: `url(${item.photo_url})`}}*/
                            className="card h300 d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3"
                          >
                            <div className="card-body d-block w-100 p-4 text-center z-index-1">
                              <div className="d-block h220">
                                <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
                                  <img
                                    src={item.mentor.photo_url}
                                    alt={item.mentor.first_name}
                                    className="float-right p-1 bg-white rounded-circle w100 h100"
                                  />
                                </figure>
                                <div className="clearfix" />
                                <h4 className="fw-700 font-xss mt-2 mb-0">
                                  {item.mentor.first_name}{" "}
                                  {item.mentor.last_name}
                                </h4>
                                {/*<div className="w-100 text-center h100">*/}
                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-2">
                                  {/*{item.mentor_profile?.position}*/}
                                  {item.mentor.mentor_profile?.position?.substring(
                                    0,
                                    50
                                  )}
                                  {item.mentor.mentor_profile?.position &&
                                    item.mentor.mentor_profile?.position
                                      .length > 50 &&
                                    "..."}
                                </p>
                                <p className="fw-500 font-xssss text-grey-900 mt-0">
                                  {item.mentor.about?.substring(
                                    0,
                                    item.mentor.mentor_profile?.position &&
                                      item.mentor.mentor_profile?.position
                                        .length > 22
                                      ? 55
                                      : 80
                                  )}
                                  {item.mentor.about &&
                                    item.mentor.about.length >
                                      (item.mentor.mentor_profile?.position &&
                                      item.mentor.mentor_profile?.position
                                        .length > 22
                                        ? 55
                                        : 80) &&
                                    "..."}
                                </p>
                              </div>
                              <Link
                                to={`/mentor/profile/${item.mentor.id}`}
                                title="Profile"
                                className="d-lg-block d-none ms-1 ls-3"
                              >
                                <i className="feather-user btn-round-md font-md bg-primary-gradiant text-white" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                      {hasMore && pageState === AppState.LOADED && (
                        <div
                          className="col-lg-12 mt-3 mb-5 text-center"
                          onClick={() => {
                            //   dispatch(
                            //     Actions.main.getMentors(loadedPage, mentors)
                            //   );
                          }}
                        >
                          <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                            Load More
                          </span>
                        </div>
                      )}
                      {pageState === Number(AppState.LOADING_MORE) && <Load />}
                    </>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <Load message="Loading" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mentors;
