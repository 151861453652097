import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";

import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { StoryResponse } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import Story from "../../components/Story";
import CreateStory from "../../components/CreateStory";
import { RouteComponentProps } from "react-router";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";

function Journal({ location }: RouteComponentProps) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);
  const state = useSelector((state: IRootState) => state.mainSub);
  const publicJournal: BaseState<Array<StoryResponse>> =
    state[StateKeys.JOURNAL];
  const privateJournal: BaseState<Array<StoryResponse>> =
    state[StateKeys.PRIVATE_JOURNAL];

  useEffect(() => {
    if (publicJournal.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.JOURNAL,
          apiEndpoint: ApiEndpoint.JOURNAL(user!.id),
          previousPage: publicJournal.currentPage!,
          params: { is_public: 1 },
        })
      );
    }

    if (privateJournal.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.PRIVATE_JOURNAL,
          apiEndpoint: ApiEndpoint.JOURNAL(user!.id),
          previousPage: privateJournal.currentPage!,
          params: { is_public: 0 },
        })
      );
    }
  }, []);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
                <div className="mb-0 mt-0 d-flex align-items-center p-4">
                  <h2 className="fw-700 font-md text-grey-900">My Journal</h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                    <div className="search-form-2 ms-2">
                                        <i className="ti-search font-xss"/>
                                        <input type="text"
                                               className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0"
                                               placeholder="Search here."/>
                                    </div>
                                </form>*/}
                </div>

                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                  <ul
                    className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="active list-inline-item me-5">
                      <Link
                        to="journal#public"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          location.hash !== "#private" && "active"
                        }`}
                        data-toggle="tab"
                      >
                        Public
                      </Link>
                    </li>
                    <li className="active list-inline-item me-5">
                      <Link
                        to="journal#private"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          location.hash === "#private" && "active"
                        }`}
                        data-toggle="tab"
                      >
                        Private
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-4 col-lg-5 pe-0 pull-right">
              <CreateStory isPublic={location.hash !== "#private"} />
            </div>
            <div className="col-xl-7 col-xxl-8 col-lg-7">
              {location.hash === "#private" ? (
                privateJournal.pageState === AppState.LOADED ||
                privateJournal.pageState === AppState.LOADING_MORE ? (
                  privateJournal.data.length > 0 ? (
                    <>
                      {(privateJournal.data as Array<StoryResponse>).map(
                        (item) => (
                          <Story
                            key={item.id}
                            story={item}
                            detailed={false}
                            postPageState={privateJournal.postPageState}
                            isPublic={false}
                          />
                        )
                      )}
                      {privateJournal.hasMore &&
                        privateJournal.pageState === AppState.LOADED && (
                          <div
                            className="col-lg-12 mt-3 mb-5 text-center"
                            onClick={() =>
                              dispatch(
                                subMain.getPaginatedPageData({
                                  stateKey: StateKeys.PRIVATE_JOURNAL,
                                  apiEndpoint: ApiEndpoint.JOURNAL(user!.id),
                                  previousPage: privateJournal.currentPage!,
                                  params: { is_public: 0 },
                                })
                              )
                            }
                          >
                            <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                              Load More
                            </span>
                          </div>
                        )}
                      {privateJournal.pageState === AppState.LOADING_MORE && (
                        <Load />
                      )}
                    </>
                  ) : (
                    <NoData
                      image="/assets/img/private_stories.png"
                      message="A lot on mind? <br/> Share them in your private journal."
                    />
                  )
                ) : (
                  <Load message="Loading" noMarginTop={true} />
                )
              ) : publicJournal.pageState === AppState.LOADED ||
                publicJournal.pageState === AppState.LOADING_MORE ? (
                publicJournal.data.length > 0 ? (
                  <>
                    {(publicJournal.data as Array<StoryResponse>).map(
                      (item) => (
                        <Story
                          key={item.id}
                          story={item}
                          detailed={false}
                          postPageState={publicJournal.postPageState}
                          isPublic={true}
                        />
                      )
                    )}
                    {publicJournal.hasMore &&
                      publicJournal.pageState === AppState.LOADED && (
                        <div
                          className="col-lg-12 mt-3 mb-5 text-center"
                          onClick={() =>
                            dispatch(
                              subMain.getPaginatedPageData({
                                stateKey: StateKeys.JOURNAL,
                                apiEndpoint: ApiEndpoint.JOURNAL(user!.id),
                                previousPage: privateJournal.currentPage!,
                                params: { is_public: 1 },
                              })
                            )
                          }
                        >
                          <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                            Load More
                          </span>
                        </div>
                      )}
                    {publicJournal.pageState === AppState.LOADING_MORE && (
                      <Load />
                    )}
                  </>
                ) : (
                  <NoData
                    image="/assets/img/public_stories.png"
                    message="Share your thoughts with your first public story."
                  />
                )
              ) : (
                <Load message="Loading" noMarginTop={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Journal;
