import React from 'react';

interface props {
    message?: string;
    error?: boolean;
    success?: boolean
    spin?: boolean
    noMarginTop?: boolean
    noMarginBottom?: boolean
    noCard?: boolean
    noPadding?: boolean
}

function Load({message, error, success,spin,noMarginTop,noMarginBottom,noCard,noPadding}:props) {
    return (
        <div className={`${!noCard && "card"} w-100 text-center shadow-xss rounded-xxl border-0 
        ${!noPadding && "p-4"} ${!noMarginTop &&"mt-3"} ${!noMarginBottom &&"mb-3"} 
        ${!!error && 'bg-danger opacity-5'} ${!!success && 'bg-success opacity-5'}`}>
            {message ?? ''}
            <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                <div className="stage">
                    <div className={spin?`spinner-border`:`dot-typing`}/>
                </div>
            </div>
        </div>
    );
}

export default Load;