import React from "react";
import {AnnouncementResponse} from "../services/api.response.service";
import moment from "moment";

interface Props{
    item: AnnouncementResponse;
    show: Boolean
    handleClose: VoidFunction
}

function AnnouncementDetails({item,show,handleClose}:Props){
    return <div className={`modal ${show && 'd-block'}`}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close" onClick={handleClose}>
                        <i className="feather-x text-danger me-3 font-sm"/>
                    </button>
                    <h3 className="modal-title">{item.title}</h3>
                </div>
                <div className="modal-body d-flex">
                    <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                            <span className="ls-3 d-block font-xsss text-grey-500 fw-500">
                                {moment(item.date).format('MMM')}
                            </span>
                            {moment(item.date).date()}
                        </h4>
                    </div>
                    <h2 className="fw-700 lh-3 font-xss">
                        {item.body.split('\n').map((str,i) =>
                            <span key={i} className="d-flex font-xssss fw-600 mt-2 lh-3">{str}</span>
                        )}
                    </h2>
                </div>
                {/*<div className="modal-footer">
                    <button type="button" data-dismiss="modal" className="btn btn-danger" onClick={handleClose}>
                        Close</button>
                </div>*/}
            </div>
        </div>
    </div>
}

export default AnnouncementDetails

