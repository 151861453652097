import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states"
import Actions from "../../redux/actions";
import Load from '../../components/Load';
import {Link} from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import NoData from "../../components/NoData";

function ReportUser({location}: RouteComponentProps) {
    const profileId = location.pathname.replace('/report/user/','')
    const dispatch = useDispatch()
    const {user} = useSelector(((state: IRootState) => state.setting))
    const {profile,loaded,loader,message,error} = useSelector(((state: IRootState) => state.main.profile))
    const [report, setReport] = useState('')

    const {
        loaderIds
    } = useSelector(((state: IRootState) => state.setting))

    const loadingRequest = () => loaderIds.includes(profileId)

    const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            user_id:profileId,
            report
        }
        dispatch(Actions.main.reportUser(data))
    }

    useEffect(() => {
        if(profileId === user!.id){
            window.location.replace(`/profile/${profileId}`);
        }
        if (profile?.id !== profileId || !loaded) {
            dispatch(Actions.main.getProfile(profileId, user!))
        }
    }, [profileId]);

    return <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
                <div className="middle-wrap">
                    {!loader ?
                        loaded && !error?
                            <>
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to={`/profile/${profileId}`} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"/></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Report {profile?.first_name} {profile?.last_name}</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-group icon-input mb-3">
                                                        <textarea className={`style2-textarea h100 ps-5 form-control text-grey-900 font-xsss fw-600`}
                                                                  placeholder="Tell us why" rows={5} required minLength={10}
                                                                  onChange={(e)=>setReport(e.target.value)}
                                                                  defaultValue={report} disabled={loadingRequest()}
                                                        />
                                                        <i className="font-sm ti-write text-grey-500 pe-0"/>
                                                    </div>
                                                    {report.length < 11 &&
                                                    <small><b>NB:</b> message should be more 10 characters long</small>}
                                                </div>
                                            </div>
                                            {report.length > 10 &&
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {loadingRequest() ? <Load message="Submitting"/> :
                                                    <button type="submit"
                                                            className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">
                                                        Submit
                                                    </button>}
                                                </div>
                                            </div>}

                                        </form>
                                    </div>
                                </div>
                            </>
                            :
                            <NoData message={message}/>
                        :
                        <Load message="Loading"/>
                    }
                </div>
            </div>

        </div>
    </div>
}

export default ReportUser;