import { AppState } from "../../services/app.service";

export interface LoginState {
  loader: boolean;
  error: boolean;
  message: string;
  state: AppState;
}

export interface RegisterState {
  loader: boolean;
  error: boolean;
  errorInput: boolean;
  message: string;
  state: AppState;
}

export interface ForgotPasswordState {
  loader: boolean;
  error: boolean;
  message: string;
  state: AppState;
}

export interface deleteAccountState {
  state: AppState;
  error: boolean;
  message: string;
}

const login: LoginState = {
  loader: false,
  error: false,
  message: "",
  state: AppState.IDLE,
};

const register: RegisterState = {
  loader: false,
  error: false,
  errorInput: false,
  message: "",
  state: AppState.IDLE,
};

const forgotPassword: ForgotPasswordState = {
  loader: false,
  error: false,
  message: "",
  state: AppState.IDLE,
};

const deleteAccount: deleteAccountState = {
  state: AppState.IDLE,
  error: false,
  message: "",
};

export interface AuthState {
  login: LoginState;
  register: RegisterState;
  forgotPassword: ForgotPasswordState;
  deleteAccount: deleteAccountState;
}

const auth: AuthState = {
  login,
  register,
  forgotPassword,
  deleteAccount,
};

export default auth;
