import auth from "./auth.action";
import setting from "./setting.action";
import main from "./main.action";
import mainSub from "./mainn.action";

const Actions = {
  auth,
  setting,
  main,
  mainSub,
};

export default Actions;
