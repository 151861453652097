import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";

const LeftNav = () => {
  const { user } = useSelector((state: IRootState) => state.setting);
  const profileVerified = () => user!.profile && user!.status === "Verified";
  const mentorProfileVerified = () => user!.mentor_profile;
  return (
    <div className="container ps-0 pe-0">
      <div className="nav-content">
        {(mentorProfileVerified() || profileVerified()) && (
          <>
            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                Feeds
              </div>
              <ul className="mb-3">
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/home"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-home me-3" />
                      <span>Home</span>
                    </NavLink>
                  </li>
                )}
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/journal"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-file me-3" />
                      <span>My Journal</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/announcements"
                    activeClassName="active"
                    className="open-font menu-icon nav-menu-icon"
                  >
                    <i className="font-xl text-current feather-info me-3" />
                    <span>My Announcements</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/resources"
                    activeClassName="active"
                    className="open-font menu-icon nav-menu-icon"
                  >
                    <i className="font-xl text-current feather-image me-3" />
                    <span>My Resources</span>
                  </NavLink>
                </li>

                {/* {profileVerified() && ( */}
                <li>
                  <NavLink
                    to="/learn"
                    activeClassName="active"
                    className="open-font menu-icon nav-menu-icon"
                  >
                    <i className="font-xl text-current feather-help-circle me-3" />
                    <span>Learn</span>
                  </NavLink>
                </li>
                {/* )} */}

                {/*<li><Link to="/defaultemailbox" className="nav-content-bttn open-font"><i className="font-xl text-current feather-inbox me-3"></i><span>Email Box</span><span className="circle-count bg-warning mt-1">584</span></Link></li>
                        <li><Link to="/defaulthotel" className="nav-content-bttn open-font"><i className="font-xl text-current feather-home me-3"></i><span>Near Hotel</span></Link></li>
                        <li><Link to="/defaultevent" className="nav-content-bttn open-font"><i className="font-xl text-current feather-map-pin me-3"></i><span>Latest Event</span></Link></li>
                        <li><Link to="/defaultlive" className="nav-content-bttn open-font"><i className="font-xl text-current feather-youtube me-3"></i><span>Live Stream</span></Link></li>*/}
              </ul>
            </div>

            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                Connections
              </div>
              <ul className="mb-3">
                {mentorProfileVerified() && (
                  <li>
                    <NavLink
                      to="/connection"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-link me-3" />
                      <span>My Girls</span>
                    </NavLink>
                  </li>
                )}
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/friends"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-link me-3" />
                      <span>My Friends</span>
                    </NavLink>
                  </li>
                )}
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/mentors"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-users me-3" />
                      <span>My Mentors</span>
                    </NavLink>
                  </li>
                )}
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/featured-mentors"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-user-check me-3" />
                      <span>Featured mentors</span>
                    </NavLink>
                  </li>
                )}
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/suggestions"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-link-2 me-3" />
                      <span>My Suggestions</span>
                    </NavLink>
                  </li>
                )}
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/requests"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-user-plus me-3" />
                      <span>My Requests</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>

            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                Chat
              </div>
              <ul className="mb-3">
                {profileVerified() && (
                  <li>
                    <NavLink
                      to="/helpline"
                      activeClassName="active"
                      className="open-font menu-icon nav-menu-icon"
                    >
                      <i className="font-xl text-current feather-help-circle me-3" />
                      <span>My Helpline</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/group-chats"
                    activeClassName="active"
                    className="open-font menu-icon nav-menu-icon"
                  >
                    <i className="font-xl text-current feather-message-square me-3" />
                    <span>Group Chats</span>
                    {/* <span className="circle-count bg-warning mt-0">23</span> */}
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
        )}

        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
          <div className="nav-caption fw-600 font-xssss text-grey-500">
            Account
          </div>
          <ul className="mb-3">
            <li>
              <NavLink
                to="/settings"
                activeClassName="active"
                className="open-font menu-icon nav-menu-icon"
              >
                <i className="font-xl text-current feather-settings me-3" />
                <span>Settings</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${mentorProfileVerified() ? "/mentor" : ""}/profile/${
                  user?.id
                }`}
                activeClassName="active"
                className="open-font menu-icon nav-menu-icon"
              >
                <i className="font-xl text-current feather-user me-3" />
                <span>Profile</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeftNav;
