import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { ResourceResponse } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import ViewImage from "../../components/Resources/ViewImage";

function Resources({ location }: RouteComponentProps) {
  const state = useSelector((state: IRootState) => state.mainSub);
  const images: BaseState<Array<ResourceResponse>> =
    state[StateKeys.IMAGE_RESOURCE];
  const audios: BaseState<Array<ResourceResponse>> =
    state[StateKeys.AUDIO_RESOURCE];
  const videos: BaseState<Array<ResourceResponse>> =
    state[StateKeys.VIDEOS_RESOURCE];
  const documents: BaseState<Array<ResourceResponse>> =
    state[StateKeys.DOCUMENTS_RESOURCE];
  const links: BaseState<Array<ResourceResponse>> =
    state[StateKeys.LINKS_RESOURCE];

  const [currentItem, setCurrentItem] = useState<ResourceResponse>();
  const [currentItemStatus, setCurrentItemStatus] = useState("stop");
  const divRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const dispatch = useDispatch();
  const {
    resourceImagesLoadedPage,
    resourceImages,
    resourceImagesLoaded,
    resourceImagesLoader,
    hasMoreResourceImages,
    loadMoreResourceImages,
    resourceAudiosLoadedPage,
    resourceAudios,
    resourceAudiosLoaded,
    resourceAudiosLoader,
    hasMoreResourceAudios,
    loadMoreResourceAudios,
    resourceVideosLoadedPage,
    resourceVideos,
    resourceVideosLoaded,
    resourceVideosLoader,
    hasMoreResourceVideos,
    loadMoreResourceVideos,
    resourceDocumentsLoadedPage,
    resourceDocuments,
    resourceDocumentsLoaded,
    resourceDocumentsLoader,
    hasMoreResourceDocuments,
    loadMoreResourceDocuments,
    resourceUrlsLoadedPage,
    resourceUrls,
    resourceUrlsLoaded,
    resourceUrlsLoader,
    hasMoreResourceUrls,
    loadMoreResourceUrls,
  } = useSelector((state: IRootState) => state.main.resources);
  const [imgURL, setImgURL] = useState<string>();

  // console.log(stories,featuredMentors)

  // useEffect(() => {
  //     if (resourceImagesLoadedPage === 0 && !resourceImagesLoaded) {
  //         dispatch(Actions.main.getResourceImages(resourceImagesLoadedPage, resourceImages))
  //     }
  //     if (resourceAudiosLoadedPage === 0 && !resourceAudiosLoaded) {
  //         dispatch(Actions.main.getResourceAudios(resourceAudiosLoadedPage, resourceAudios))
  //     }
  //     if (resourceVideosLoadedPage === 0 && !resourceVideosLoaded) {
  //         dispatch(Actions.main.getResourceVideos(resourceVideosLoadedPage, resourceVideos))
  //     }
  //     if (resourceDocumentsLoadedPage === 0 && !resourceDocumentsLoaded) {
  //         dispatch(Actions.main.getResourceDocuments(resourceDocumentsLoadedPage, resourceDocuments))
  //     }
  //     if (resourceUrlsLoadedPage === 0 && !resourceUrlsLoaded) {
  //         dispatch(Actions.main.getResourceUrls(resourceUrlsLoadedPage, resourceUrls))
  //     }
  // }, []);

  useEffect(() => {
    if (images.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.IMAGE_RESOURCE,
          apiEndpoint: ApiEndpoint.RESOURCES,
          previousPage: images.currentPage!,
          params: { type: "image" },
        })
      );
    }
    if (audios.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.AUDIO_RESOURCE,
          apiEndpoint: ApiEndpoint.RESOURCES,
          previousPage: audios.currentPage!,
          params: { type: "audio" },
        })
      );
    }
    if (videos.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.VIDEOS_RESOURCE,
          apiEndpoint: ApiEndpoint.RESOURCES,
          previousPage: videos.currentPage!,
          params: { type: "video" },
        })
      );
    }
    if (documents.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.DOCUMENTS_RESOURCE,
          apiEndpoint: ApiEndpoint.RESOURCES,
          previousPage: documents.currentPage!,
          params: { type: "docs" },
        })
      );
    }

    if (links.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.LINKS_RESOURCE,
          apiEndpoint: ApiEndpoint.RESOURCES,
          previousPage: links.currentPage!,
          params: { type: "url" },
        })
      );
    }
  }, []);

  const playVideo = (item: ResourceResponse) => {
    if (item) {
      if (item?.id === currentItem?.id) {
        videoRef.current?.paused
          ? videoRef.current?.play()
          : videoRef.current?.pause();
        // // @ts-ignore
        // divRef.current.className = divRef.current?.className.replace(
        //   "d-none",
        //   ""
        // );
      } else if (!currentItem) {
        setCurrentItem(item);
        // // @ts-ignore
        // divRef.current.className = divRef.current?.className.replace(
        //   "d-none",
        //   ""
        // );
        videoRef.current?.pause();
        setTimeout(() => {
          divRef.current?.focus();
          videoRef.current?.play();
          // setCurrentVideoStatus('playing')
        }, 2000);
      }
    } else {
      videoRef.current?.pause();
      setCurrentItemStatus("paused");
      setCurrentItem(null!);
    }
  };

  const renderTabPage = (tab: string) => {
    switch (tab) {
      case "#audios":
        return audios.pageState === AppState.LOADED ? (
          audios.data.length > 0 ? (
            <>
              {(audios.data as Array<ResourceResponse>).map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 pe-2 ps-2">
                  <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    {/*<div className="card-image w-100">
                                                <video src={item.url} className="w-100 rounded-3"
                                                       style={{width:'100%'}} autoPlay={false} controls={true} />
                                            </div>*/}
                    <div
                      className="card-body d-flex ps-0 pe-0 pb-0 pt-0"
                      onClick={() => playVideo(item)}
                    >
                      <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                          {/*<span className="ls-3 d-block font-xsss text-grey-500 fw-500">{value.month}</span>*/}
                          <i className="font-xl me-1 feather-music" />
                        </h4>
                      </div>
                      <h2 className="fw-700 lh-3 font-xss">
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          {item.description}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
              {audios.hasMore &&
                audios.pageState === Number(AppState.LOADED) && (
                  <div
                    className="col-lg-12 mt-3 mb-5 text-center"
                    onClick={
                      () =>
                        dispatch(
                          subMain.getPaginatedPageData({
                            stateKey: StateKeys.ANNOUNCEMENTS,
                            apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
                            previousPage: audios.currentPage!,
                          })
                        )

                      // onClick={() => {
                      // dispatch(
                      //   Actions.main.getResourceAudios(
                      //     resourceAudiosLoadedPage,
                      //     resourceAudios
                      //   )
                      // );
                    }
                  >
                    <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                      Load More
                    </span>
                  </div>
                )}
              {audios.pageState === Number(AppState.LOADING_MORE) && <Load />}
            </>
          ) : (
            <NoData message="No Audios" />
          )
        ) : (
          <Load message="Loading" />
        );

      case "#videos":
        return videos.pageState === AppState.LOADED ? (
          videos.data.length > 0 ? (
            <>
              {(videos.data as Array<ResourceResponse>).map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 pe-2 ps-2">
                  <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    {/*<div className="card-image w-100">
                                                <video src={item.url} className="w-100 rounded-3"
                                                       style={{width:'100%'}} autoPlay={false} controls={true} />
                                            </div>*/}
                    <div
                      className="card-body d-flex ps-0 pe-0 pb-0 pt-0"
                      onClick={() => playVideo(item)}
                    >
                      <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                          {/*<span className="ls-3 d-block font-xsss text-grey-500 fw-500">{value.month}</span>*/}
                          <i
                            className={`font-xl me-1 ${
                              currentItem?.id === item.id && "text-current"
                            }  ${
                              currentItem?.id === item.id &&
                              currentItemStatus === "playing"
                                ? "feather-pause"
                                : "feather-play"
                            } `}
                          />
                        </h4>
                      </div>
                      <h2 className="fw-700 lh-3 font-xss">
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          {item.description}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
              {videos.hasMore &&
                videos.pageState === Number(AppState.LOADED) && (
                  <div
                    className="col-lg-12 mt-3 mb-5 text-center"
                    onClick={
                      () =>
                        dispatch(
                          subMain.getPaginatedPageData({
                            stateKey: StateKeys.ANNOUNCEMENTS,
                            apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
                            previousPage: videos.currentPage!,
                          })
                        )

                      // onClick={() => {
                      // dispatch(
                      //   Actions.main.getResourceVideos(
                      //     resourceVideosLoadedPage,
                      //     resourceVideos
                      //   )
                      // );
                    }
                  >
                    <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                      Load More
                    </span>
                  </div>
                )}
              {videos.pageState === Number(AppState.LOADING_MORE) && <Load />}
            </>
          ) : (
            <NoData message="No Videos" />
          )
        ) : (
          <Load message="Loading" />
        );

      case "#documents":
        return documents.pageState === AppState.LOADED ? (
          documents.data.length > 0 ? (
            <>
              {(documents.data as Array<ResourceResponse>).map((item) => (
                <a
                  rel="noopener noreferrer"
                  href={item.url}
                  target="_blank"
                  key={item.id}
                  className="col-lg-4 col-md-6 pe-2 ps-2"
                >
                  <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    <div className="card-body d-flex ps-0 pe-0 pb-0 pt-0">
                      <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                          <i className="font-xl me-1 feather-file" />
                        </h4>
                      </div>
                      <h2 className="fw-700 lh-3 font-xss">
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          {item.description}
                        </span>
                      </h2>
                    </div>
                  </div>
                </a>
              ))}
              {documents.hasMore &&
                documents.pageState === Number(AppState.LOADED) && (
                  <div
                    className="col-lg-12 mt-3 mb-5 text-center"
                    onClick={
                      () =>
                        dispatch(
                          subMain.getPaginatedPageData({
                            stateKey: StateKeys.ANNOUNCEMENTS,
                            apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
                            previousPage: documents.currentPage!,
                          })
                        )

                      // onClick={() => {
                      // dispatch(
                      //   Actions.main.getResourceDocuments(
                      //     resourceDocumentsLoadedPage,
                      //     resourceDocuments
                      //   )
                      // );
                    }
                  >
                    <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                      Load More
                    </span>
                  </div>
                )}
              {documents.pageState === Number(AppState.LOADING_MORE) && (
                <Load />
              )}
            </>
          ) : (
            <NoData message="No Documents" />
          )
        ) : (
          <Load message="Loading" />
        );

      case "#links":
        return links.pageState === AppState.LOADED ? (
          links.data.length > 0 ? (
            <>
              {(links.data as Array<ResourceResponse>).map((item) => (
                <a
                  rel="noopener noreferrer"
                  href={item.url}
                  target="_blank"
                  key={item.id}
                  className="col-lg-4 col-md-6 pe-2 ps-2"
                >
                  <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    <div className="card-body d-flex ps-0 pe-0 pb-0 pt-0">
                      <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                          <i className="font-xl me-1 feather-external-link" />
                        </h4>
                      </div>
                      <h2 className="fw-700 lh-3 font-xss">
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          {item.description}
                        </span>
                      </h2>
                    </div>
                  </div>
                </a>
              ))}
              {links.hasMore && links.pageState === Number(AppState.LOADED) && (
                <div
                  className="col-lg-12 mt-3 mb-5 text-center"
                  onClick={() => {
                    //   dispatch(
                    //     Actions.main.getResourceUrls(
                    //       resourceUrlsLoadedPage,
                    //       resourceUrls
                    //     )
                    //   );
                  }}
                >
                  <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                    Load More
                  </span>
                </div>
              )}
              {links.pageState === Number(AppState.LOADING_MORE) && <Load />}
            </>
          ) : (
            <NoData message="No Links" />
          )
        ) : (
          <Load message="Loading" />
        );

      default:
        return images.pageState === AppState.LOADED ? (
          images.data.length > 0 ? (
            <>
              {(images.data as Array<ResourceResponse>).map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 pe-2 ps-2">
                  <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    <div
                      className="card-image w-100"
                      onClick={() => setImgURL(item.url)}
                    >
                      <img
                        src={item.url}
                        alt="event"
                        className="w-100 rounded-3"
                      />
                    </div>
                    <div className="card-body d-flex ps-0 pe-0 pb-0">
                      <h2 className="fw-700 lh-3 font-xss">
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                          {item.description}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
              {images.hasMore &&
                images.pageState === Number(AppState.LOADED) && (
                  <div
                    className="col-lg-12 mt-3 mb-5 text-center"
                    onClick={
                      () =>
                        dispatch(
                          subMain.getPaginatedPageData({
                            stateKey: StateKeys.ANNOUNCEMENTS,
                            apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
                            previousPage: images.currentPage!,
                          })
                        )

                      // onClick={() => {
                      //   dispatch(
                      //     Actions.main.getResourceImages(
                      //       resourceImagesLoadedPage,
                      //       resourceImages
                      //     )
                      //   );
                    }
                  >
                    <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                      Load More
                    </span>
                  </div>
                )}
              {images.pageState === Number(AppState.LOADING_MORE) && <Load />}
            </>
          ) : (
            <NoData message="No Images" />
          )
        ) : (
          <Load message="Loading" />
        );
    }
  };

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
                <div className="mb-0 mt-0 d-flex align-items-center p-4">
                  <h2 className="fw-700 font-md text-grey-900">My Resources</h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                    <div className="search-form-2 ms-2">
                                        <i className="ti-search font-xss"/>
                                        <input type="text"
                                               className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0"
                                               placeholder="Search here."/>
                                    </div>
                                </form>*/}
                </div>

                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                  <ul
                    className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="active list-inline-item me-5">
                      <Link
                        onClick={() => playVideo(null!)}
                        to="resources#images"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          (location.hash === "#images" ||
                            location.hash === "") &&
                          "active"
                        }`}
                        data-toggle="tab"
                      >
                        Images
                      </Link>
                    </li>
                    <li className="active list-inline-item me-5">
                      <Link
                        onClick={() => playVideo(null!)}
                        to="resources#audios"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          location.hash === "#audios" && "active"
                        }`}
                        data-toggle="tab"
                      >
                        Audios
                      </Link>
                    </li>
                    <li className="active list-inline-item me-5">
                      <Link
                        to="resources#videos"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          location.hash === "#videos" && "active"
                        }`}
                        data-toggle="tab"
                      >
                        Videos
                      </Link>
                    </li>
                    <li className="active list-inline-item me-5">
                      <Link
                        onClick={() => playVideo(null!)}
                        to="resources#documents"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          location.hash === "#documents" && "active"
                        }`}
                        data-toggle="tab"
                      >
                        Documents
                      </Link>
                    </li>
                    <li className="active list-inline-item me-5">
                      <Link
                        onClick={() => playVideo(null!)}
                        to="resources#links"
                        className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                          location.hash === "#links" && "active"
                        }`}
                        data-toggle="tab"
                      >
                        Links
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className={`row ps-2 pe-1 ${!currentItem && "d-none"}`}
                ref={divRef}
              >
                <div className="col-md-12 pe-2 ps-2">
                  <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                    <div className="card-image w-100">
                      <video
                        src={currentItem?.url}
                        ref={videoRef}
                        className="w-100 rounded-3"
                        onPlay={(e) => setCurrentItemStatus("playing")}
                        onPause={(e) => setCurrentItemStatus("paused")}
                        controls={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ps-2 pe-1">
                {renderTabPage(location.hash)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewImage imgURL={imgURL!} setImgURL={setImgURL} />
    </div>
  );
}

export default Resources;
