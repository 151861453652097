import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { Link, Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { InterestResponse } from "../../../store/api.response.type";

function MentorProfile({ location }: RouteComponentProps) {
  const profileId = location.pathname.replace("/mentor/profile/", "");
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);
  const {
    profile,
    loaded,
    loader,
    message,
    error,
    interest: userInterests,
  } = useSelector((state: IRootState) => state.main.mentorProfile);
  const [userInterestsList, setUserInterestsList] = useState<
    Array<InterestResponse>
  >([]);
  const [userInterestsDeleteList, setUserInterestsDeleteList] = useState<
    Array<InterestResponse>
  >([]);
  const [editInterest, setEditInterest] = useState(false);

  // const categories = useSelector(((state: IRootState) => state.main.categories))

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  const interests = useSelector((state: IRootState) => state.main.interests);

  const isCurrentUser = () => {
    return user?.id === profileId;
  };

  const makeConnection = (id: string) =>
    !loaderIds.includes(id) && dispatch(Actions.main.getMentorConnection(id));

  /*useEffect(() => {
        if (categories.loadedPage === 0 && !categories.loaded) {
            dispatch(Actions.main.getCategories(categories.loadedPage, categories.categories))
        }
    }, []);*/

  useEffect(() => {
    if (profile?.id !== profileId || !loaded) {
      dispatch(Actions.main.getMentorProfile(profileId, user!));
      dispatch(Actions.main.getMentorProfileInterest(profileId));
    }
  }, [profileId]);

  useEffect(() => {
    if (!interests.loaded) {
      dispatch(Actions.main.getInterests());
    }
  }, []);

  useEffect(() => {
    if (loaderIds.includes("interestsSaved")) {
      setEditInterest(false);
      dispatch(Actions.setting.removeLoaderId("interestsSaved"));
    }
  }, [loaderIds]);

  const handleShowEditInterests = () => {
    setUserInterestsList(userInterests.interests);
    setUserInterestsDeleteList([]);
    setEditInterest(true);
  };

  const filterInterestsAdd = (item: InterestResponse) => {
    setUserInterestsList([...userInterestsList, item]);
    setUserInterestsDeleteList([
      ...userInterestsDeleteList.filter((e) => e.name !== item.name),
    ]);
  };
  const filterInterestsRemove = (item: InterestResponse) => {
    setUserInterestsList([
      ...userInterestsList.filter((e) => e.name !== item.name),
    ]);
    setUserInterestsDeleteList([
      ...userInterestsDeleteList,
      ...userInterests.interests.filter(
        (e: InterestResponse) => e.name === item.name
      ),
    ]);
  };

  const handleSaveInterests = () => {
    const add = [
      ...userInterestsList.filter(
        (item) =>
          !userInterests.interests.find(
            (e: InterestResponse) => e.name === item.name
          )
      ),
    ];
    dispatch(
      Actions.main.updateMentorProfileInterest(
        [...add.map((item: InterestResponse) => item.id)],
        [...userInterestsDeleteList.map((item) => item.id)]
      )
    );
  };

  if (!user!.mentor_profile) {
    return <Redirect to={{ pathname: `/profile/${user?.id}` }} />;
  }
  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            {!loader ? (
              loaded ? (
                <>
                  <div className="col-xl-12 mb-3">
                    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                      <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                        <img
                          src={profile?.video_url}
                          className="h250 w-100"
                          alt="background"
                        />
                      </div>
                      <div className="card-body p-0 position-relative">
                        <figure
                          className="avatar position-absolute w100 z-index-1"
                          style={{ top: "-40px", left: "30px" }}
                        >
                          <img
                            src={profile?.photo_url}
                            alt="user"
                            className="float-right p-1 bg-white rounded-circle w100 h100"
                          />
                        </figure>
                        <h4 className="fw-700 font-sm mt-2 mb-lg-4 mb-3 pl-15">
                          {`${profile?.first_name} ${profile?.last_name}`}
                          <span className="fw-500 font-xssss text-grey-500 mt-1 d-block">
                            <i className="ti-location-pin me-1" />{" "}
                            {`${profile?.city} ${profile?.country}`}
                          </span>
                          <span className="fw-500 font-xssss text-grey-500 mt-1 d-block">
                            <i className="ti-agenda me-1" />{" "}
                            {profile?.mentor_profile?.position}
                          </span>
                          <div className="w-100 mt-2">
                            <div className="d-block pt-4">
                              <h4 className="fw-700 mb-1 font-xsss text-grey-900">
                                About
                              </h4>
                              <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                                {profile?.about ?? "-"}
                              </p>
                            </div>
                          </div>
                        </h4>
                        <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                          {isCurrentUser() ? (
                            <>
                              {profile?.status !== "Verified" &&
                                profile?.status !== "Pending" && (
                                  <Link
                                    to="/verify/mentor/account"
                                    className="d-none d-lg-block bg-success p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                  >
                                    Verify Account
                                  </Link>
                                )}
                              {profile?.status === "Pending" && (
                                <div className="d-none d-lg-block bg-warning p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">
                                  Pending Verification
                                </div>
                              )}
                              <Link
                                to="/edit/mentor/profile"
                                className="d-none d-lg-block bg-greylight p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 text-grey-700 ms-2"
                              >
                                {" "}
                                Edit Profile
                              </Link>
                              {/*<a href="/home" id="dropdownMenu4" className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more font-md tetx-dark"/></a>*/}
                              <div
                                className="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg"
                                aria-labelledby="dropdownMenu4"
                              >
                                <div className="card-body p-0 d-flex">
                                  <i className="feather-bookmark text-grey-500 me-3 font-lg" />
                                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                                    Save Link{" "}
                                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                      Add this to your saved items
                                    </span>
                                  </h4>
                                </div>
                                <div className="card-body p-0 d-flex mt-2">
                                  <i className="feather-alert-circle text-grey-500 me-3 font-lg" />
                                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                                    Hide Post{" "}
                                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                      Save to your saved items
                                    </span>
                                  </h4>
                                </div>
                                <div className="card-body p-0 d-flex mt-2">
                                  <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                                    Hide all from Group{" "}
                                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                      Save to your saved items
                                    </span>
                                  </h4>
                                </div>
                                <div className="card-body p-0 d-flex mt-2">
                                  <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                                  <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">
                                    Unfollow Group{" "}
                                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                      Save to your saved items
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </>
                          ) : profile?.is_connection ? (
                            <Link
                              to={"#"}
                              className="d-none d-lg-block bg-greylight p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 text-grey-700 ms-2"
                            >
                              Send Message
                            </Link>
                          ) : loaderIds.includes(profileId) ? (
                            <Load
                              spin={true}
                              noCard={true}
                              noPadding={true}
                              noMarginTop={true}
                            />
                          ) : (
                            /*!!friendRequests.find((e:UserResponseFriendRequest) => e.user.id === profileId) ?
                                                                    <>
                                                                    <span className="position-absolute right-15 top-0 d-flex align-items-center">
                                                                        <span className={`d-none d-lg-block p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 bg-danger`}
                                                                              onClick={declineRequest}>
                                                                            Decline
                                                                        </span>
                                                                        <span className={`d-none d-lg-block p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 bg-success`}
                                                                              onClick={acceptRequest}>
                                                                            Accept
                                                                        </span>
                                                                    </span>
                                                                    </>
                                                                    :*/
                            <span
                              className={`d-none d-lg-block p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 bg-success`}
                              onClick={() => makeConnection(profileId)}
                            >
                              Connect
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-xxl-3 col-lg-4">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                      <div className="card-body d-block p-4">
                        <h4 className="fw-700 mb-3 font-xsss text-grey-900">
                          Sector
                        </h4>
                        <ul>
                          <li className="badge badge-info shadow-xss rounded-3 me-1">
                            {profile?.mentor_profile?.sector?.name}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-xxl-9 col-lg-8 pe-0">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                      <div className="card-body d-block p-4">
                        <h4 className="fw-700 mb-3 font-xsss text-grey-900">
                          Talk to me about
                        </h4>
                        {!userInterests.loader ? (
                          !editInterest ? (
                            <>
                              {userInterests.loaded &&
                                userInterests.interests.length > 0 && (
                                  <ul>
                                    {(
                                      userInterests.interests as Array<InterestResponse>
                                    ).map((item) => (
                                      <li
                                        className="badge badge-warning shadow-xss rounded-3 me-1"
                                        key={item.id}
                                      >
                                        {item.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              {isCurrentUser() && (
                                <>
                                  <span
                                    className="d-block ms-1 ls-3"
                                    onClick={handleShowEditInterests}
                                  >
                                    <i
                                      className={`${
                                        interests.loaded &&
                                        interests.interests.length ===
                                          userInterests.interests.length
                                          ? "feather-minus bg-danger"
                                          : "feather-plus bg-success"
                                      } btn-round-sm font-md text-white`}
                                    />
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {userInterestsList.length > 0 && (
                                <>
                                  {userInterestsList.length > 0 && (
                                    <small>Click item to Remove</small>
                                  )}
                                  <ul>
                                    {userInterestsList.map((item) => (
                                      <li
                                        className="badge badge-danger shadow-xss rounded-3 me-1"
                                        key={item.id}
                                        title="Click to Remove"
                                        onClick={() =>
                                          filterInterestsRemove(item)
                                        }
                                      >
                                        <i className="feather-minus-circle font-xssss text-white" />{" "}
                                        {item.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}
                              {interests.loaded &&
                                interests.interests.length > 0 && (
                                  <>
                                    {interests.interests.length !==
                                      userInterestsList.length && (
                                      <small className="pt-2">
                                        Click item to Add
                                      </small>
                                    )}
                                    <ul>
                                      {(
                                        interests.interests as Array<InterestResponse>
                                      )
                                        .filter(
                                          (item) =>
                                            !userInterestsList.find(
                                              (e: InterestResponse) =>
                                                e.name === item.name
                                            )
                                        )
                                        .map((item) => (
                                          <li
                                            className="badge badge-pill badge-success shadow-xss rounded-3 me-1"
                                            key={item.id}
                                            title="Click to Add"
                                            onClick={() =>
                                              filterInterestsAdd(item)
                                            }
                                          >
                                            <i className="feather-plus-circle font-xssss text-white" />{" "}
                                            {item.name}
                                          </li>
                                        ))}
                                    </ul>
                                  </>
                                )}
                              {loaderIds.includes(`updateInterests`) ? (
                                <Load
                                  spin={true}
                                  noCard={true}
                                  noPadding={true}
                                  noMarginTop={true}
                                />
                              ) : (
                                <div className="d-flex mt-3 justify-content-end">
                                  <span
                                    className="bg-primary p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 me-2"
                                    onClick={() => setEditInterest(false)}
                                  >
                                    Close
                                  </span>
                                  <span
                                    className="bg-success p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                    onClick={handleSaveInterests}
                                  >
                                    Save
                                  </span>
                                </div>
                              )}
                            </>
                          )
                        ) : (
                          <Load message="Loading" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-xxl-9 col-lg-8 pe-0">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                      <div className="card-body d-block p-4">
                        <h4 className="fw-700 mb-3 font-xsss text-grey-900">
                          Facts
                        </h4>
                        {profile?.mentor_profile?.facts &&
                        profile.mentor_profile.facts.length > 0 ? (
                          <ol>
                            {(
                              profile.mentor_profile.facts as Array<string>
                            ).map(
                              (item, i) =>
                                item && (
                                  <li style={{ listStyle: "decimal" }} key={i}>
                                    {item}
                                  </li>
                                )
                            )}
                          </ol>
                        ) : (
                          <NoData message="No Facts" />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <NoData />
              )
            ) : (
              <Load message="Loading" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MentorProfile;
