import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import {
  CoursesResponse,
  CourseTopicsResponse,
} from "../../services/api.response.service";
import { Toast } from "../../utils/extraFunction";

interface props {
  topic: CourseTopicsResponse;
  hasSubscribed: boolean;
}

const CourseTopic = ({ topic, hasSubscribed }: props) => {
  const state = useSelector((state: IRootState) => state.mainSub);

  const course: BaseState<CoursesResponse> = state[StateKeys.COURSE];

  const { data } = course;

  const notSubscribed = (id: string) => {
    Toast.fire({
      text: "Sorry you are not subscribe to this course!",
      icon: "error",
    });
  };

  return (
    <>
      <div className="col-sm-6 col-md-4 col-xl-3 pb-4">
        <div
          className="card"
          style={{
            // width: "200px",
            borderRadius: "19px",
          }}
        >
          <div className={`text-center py-4 ${topic.content_type}`}>
            {topic.content_type === "video" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-video"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            )}

            {topic.content_type === "text" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-align-left"
              >
                <line x1="17" y1="10" x2="3" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="17" y1="18" x2="3" y2="18"></line>
              </svg>
            )}

            {topic.content_type === "audio" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-headphones"
              >
                <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
              </svg>
            )}
          </div>
          <div className="card-body">
            <div>
              <Link
                onClick={() => !hasSubscribed && notSubscribed(data?.id)}
                to={`${hasSubscribed ? `/lms/topic/${topic.id}/` : `#`} `}
              >
                <h5 className="card-title text-truncate"> {topic?.title}</h5>
              </Link>
              <p className="card-text text-truncate">{topic?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseTopic;
