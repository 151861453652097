import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import subMain from "../../redux/actions/mainn.action";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import { TopicsResponse } from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import Load from "../Load";

const Topics = () => {
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const topic: BaseState<TopicsResponse> = state[StateKeys.TOPIC];

  const { data, pageState } = topic;

  useEffect(() => {
    if (pageState !== AppState.LOADED || data?.id !== id) {
      dispatch(
        subMain.getPageObjectData({
          stateKey: StateKeys.TOPIC,
          apiEndpoint: ApiEndpoint.TOPIC(id),
          clear: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // console.log(data);

  return (
    <>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {pageState === AppState.LOADED && data ? (
              <>
                <div className="col-xl-12 mb-3">
                  <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl d-flex flex-column gap-5 pt-3">
                    {(data.content_type === "video" ||
                      data.content_type === "audio") && (
                      <div
                        className={`card-body  ${
                          data.content_type === "video" && "h250"
                        } p-0 border rounded-xxl overflow-hidden m-3`}
                      >
                        {data.content_type === "video" && (
                          <video
                            width="100%"
                            height="100%"
                            controls
                            autoPlay
                            src={data.content_url}
                          />
                        )}

                        {data.content_type === "audio" && (
                          <audio
                            controls
                            autoPlay
                            style={{ width: "100%" }}
                            // height="100%"
                            src={data.content_url}
                          />
                        )}
                      </div>
                    )}
                    <div className="card-body p-0 position-relative">
                      <h4 className="fw-700 font-sm mb-lg-4 mb-3 pl-15">
                        {data.title}
                        <div className="w-100 mt-2">
                          <div className="d-block pt-4">
                            <h4 className="fw-700 mb-1 font-xsss text-grey-900">
                              Description
                            </h4>
                            <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                              {data.description}
                            </p>

                            {data.content_type === "text" && (
                              <div
                                className="pt-5"
                                dangerouslySetInnerHTML={{
                                  __html: data.content,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </h4>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Load message="Loading" noMarginTop={true} />
            )}{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Topics;
