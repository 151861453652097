import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { UserProfileResponse } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import { UserResponseFriendRequest } from "../../../store/api.response.type";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";

function Connections() {
  const dispatch = useDispatch();
  // const {
  //     suggestedFriendsLoaded,
  //     suggestedFriendsLoader,
  //     suggestedFriendsLoadedPage,
  //     hasMoreSuggestedFriends,
  //     loadMoreSuggestedFriends,
  //     suggestedFriendsAddRequest,
  //     friendRequestsLoadedPage,
  //     friendRequestsLoaded,
  // } = useSelector(((state: IRootState) => state.main.home))

  const tempo = useSelector((state: IRootState) => state.main.home);

  const state = useSelector((state: IRootState) => state.mainSub);
  const suggestedFriends: BaseState<Array<UserProfileResponse>> =
    state[StateKeys.SUGGESTED_CONNECTIONS];
  const friendRequests: BaseState<Array<UserResponseFriendRequest>> =
    state[StateKeys.REQUESTED_CONNECTIONS];

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  useEffect(() => {
    // if (suggestedFriendsLoadedPage === 0 && !suggestedFriendsLoaded) {
    //     dispatch(Actions.main.getHomeSuggestedFriends(suggestedFriendsLoadedPage, suggestedFriends))
    // }
    // if (friendRequestsLoadedPage === 0 && !friendRequestsLoaded) {
    //     dispatch(Actions.main.getHomeFriendRequests(friendRequestsLoadedPage, friendRequests))
    // }

    if (suggestedFriends.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.SUGGESTED_CONNECTIONS,
          apiEndpoint: ApiEndpoint.SUGGESTED_CONNECTIONS,
          previousPage: suggestedFriends.currentPage!,
        })
      );
    }
    if (friendRequests.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.REQUESTED_CONNECTIONS,
          apiEndpoint: ApiEndpoint.REQUESTED_CONNECTIONS,
          previousPage: friendRequests.currentPage!,
        })
      );
    }
  }, []);

  // const makeConnection = (id: string) =>
  //     !(suggestedFriendsAddRequest.includes(id) || loaderIds.includes(id)) && dispatch(Actions.main.postHomeSuggestedFriendsMakeRequest(id))

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <div className="mb-0 mt-0 d-flex align-items-center">
                  <h2 className="fw-700 font-md text-grey-900">
                    My Suggestions
                  </h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                    <div className="search-form-2 ms-2">
                                        <i className="ti-search font-xss"/>
                                        <input type="text"
                                               className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0"
                                               placeholder="Search here."/>
                                    </div>
                                </form>*/}
                </div>
              </div>

              <div className="row ps-2 pe-1">
                {suggestedFriends.pageState === AppState.LOADED ? (
                  suggestedFriends.data.length > 0 ? (
                    <>
                      {(suggestedFriends.data as Array<UserProfileResponse>)
                        .filter((item) => !item.is_connection)
                        .filter(
                          (item) =>
                            !(
                              friendRequests.data as Array<UserResponseFriendRequest>
                            ).find((e) => e.user.id === item.id)
                        )
                        .map((item) => (
                          <div
                            key={item.id}
                            className="col-md-6 col-sm-6 pe-2 ps-2"
                          >
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                              <div
                                className="card-body position-relative h100 bg-image-cover bg-image-center"
                                style={{
                                  backgroundImage: `url(${item.video_url})`,
                                }}
                              />
                              <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                                <figure
                                  className="avatar position-absolute w75 z-index-1 left-15"
                                  style={{ marginTop: `-40px` }}
                                >
                                  {/*<video autoPlay={true} loop={true} className="float-right w-100">
                                                                <source src={item.user.video_url}/>
                                                            </video>*/}
                                  <img
                                    src={item.photo_url}
                                    alt={item.first_name}
                                    className="float-right p-1 bg-white rounded-circle w75 h75"
                                  />
                                </figure>
                                <div className="clearfix" />
                                <h4 className="fw-700 font-xsss mt-3 mb-1">
                                  {item.first_name} {item.last_name}
                                </h4>
                                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">
                                  {item.city}
                                </p>
                                <span className="position-absolute right-15 top-0 d-flex align-items-center">
                                  <span
                                    title="Add"
                                    className="d-lg-block d-none"
                                    //   onClick={() => makeConnection(item.id)}
                                  >
                                    {loaderIds.includes(item.id) ? (
                                      <Load
                                        spin={true}
                                        noCard={true}
                                        noPadding={true}
                                        noMarginTop={true}
                                      />
                                    ) : (
                                      <i
                                      //   className={`${
                                      //     suggestedFriendsAddRequest.includes(
                                      //       item.id
                                      //     )
                                      //       ? "feather-user-check bg-dark"
                                      //       : "feather-user-plus bg-info"
                                      //   } btn-round-md font-md text-white`}
                                      />
                                    )}
                                  </span>
                                  <Link
                                    to={`/profile/${item.id}`}
                                    title="Profile"
                                    className="d-lg-block d-none ms-1 ls-3"
                                  >
                                    <i className="feather-user btn-round-md font-md bg-primary-gradiant text-white" />
                                  </Link>
                                  {/*<a href="/defaultgroup" className="text-center p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">
                                                                FOLLOW
                                                            </a>*/}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}

                      {suggestedFriends.hasMore &&
                        suggestedFriends.pageState === AppState.LOADED && (
                          <div
                            className="col-lg-12 mt-3 mb-5 text-center"
                            onClick={() =>
                              dispatch(
                                subMain.getPaginatedPageData({
                                  stateKey: StateKeys.SUGGESTED_CONNECTIONS,
                                  apiEndpoint:
                                    ApiEndpoint.SUGGESTED_CONNECTIONS,
                                  previousPage: suggestedFriends.currentPage!,
                                })
                              )
                            }
                          >
                            <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                              Load More
                            </span>
                          </div>
                        )}
                      {suggestedFriends.pageState ===
                        Number(AppState.LOADING_MORE) && <Load />}
                    </>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <Load message="Loading" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Connections;
