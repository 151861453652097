import rootState from "../states";
import {types} from "../actions/setting.action";
import contants from "../../../store/contants";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = rootState.setting, {type,payload}:any){
    switch (type) {
        case types.USER:
            localStorage.setItem(contants.USER, JSON.stringify(payload))
            return {...state,user: payload}
        case types.HEADER_OPEN:
            return {...state,header:{...state.header, open: payload}}
        case types.HEADER_ACTIVE:
            return {...state,header:{...state.header, active: payload}}
        case types.HEADER_NOTIFICATION:
            return {...state,header:{...state.header, notification: payload}}
        case types.HEADER_SHOW_RIGHT_CHAT:
            return {...state,header:{...state.header, showRightChat: payload}}
        case types.ADD_LOADER_ID:
            return {...state,loaderIds:[...state.loaderIds,payload]}
        case types.REMOVE_LOADER_ID:
            return {...state,loaderIds:[...state.loaderIds.filter(e => e !== payload)]}
        default:
            return {...state}
    }
}