import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { AnnouncementResponse } from "../services/api.response.service";
import NoData from "./NoData";
import Load from "./Load";
import { Link } from "react-router-dom";
import moment from "moment";
import AnnouncementDetails from "./AnnouncementDetails";
import { AppState } from "../services/app.service";

const Events = () => {
  //   const { announcements, loaded, loader } = useSelector(
  //     (state: IRootState) => state.main.announcements
  //   );
  const { announcements } = useSelector((state: IRootState) => state.mainSub);

  const { pageState, data } = announcements;

  console.log(data);

  const [activeId, setActiveId] = useState("");
  const handleClose = () => setActiveId("");
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Announcements</h4>
        <Link
          to="/announcements"
          className="fw-600 ms-auto font-xssss text-primary"
        >
          See all
        </Link>
      </div>
      {pageState === AppState.LOADED ? (
        data.length > 0 ? (
          (data as Array<AnnouncementResponse>).slice(0, 6).map((item) => (
            <React.Fragment key={item.id}>
              <div
                className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden"
                onClick={() => setActiveId(item.id)}
                style={{ cursor: "pointer" }}
              >
                <div className={`bg-success me-2 p-3 rounded-xxl`}>
                  <h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0">
                    <span className="ls-1 d-block font-xsss text-white fw-600">
                      {moment(item.date).format("MMM")}
                    </span>
                    {moment(item.date).date()}
                  </h4>
                </div>
                <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                  {item.title}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                    {item.body.substring(0, 60)}
                    {item.body.length > 60 && "..."}
                  </span>
                </h4>
              </div>
              <AnnouncementDetails
                key={item.id}
                item={item}
                handleClose={handleClose}
                show={activeId === item.id}
              />
            </React.Fragment>
          ))
        ) : (
          <>
            <NoData noCard={true} />
          </>
        )
      ) : (
        <Load message="Loading" />
      )}
    </div>
  );
};

export default Events;
