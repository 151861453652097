import {UserProfileResponse} from "../../services/api.response.service";

export const types = {
    USER: 'USER',
    HEADER_OPEN: 'HEADER_OPEN',
    HEADER_ACTIVE: 'HEADER_ACTIVE',
    HEADER_NOTIFICATION: 'HEADER_NOTIFICATION',
    HEADER_SHOW_RIGHT_CHAT: 'HEADER_SHOW_RIGHT_CHAT',
    ADD_LOADER_ID: 'ADD_LOADER_ID',
    REMOVE_LOADER_ID: 'REMOVE_LOADER_ID',
}

const Setting = {
    setUser: (payload:UserProfileResponse) => ({type: types.USER, payload}),
    headerToggleOpen: (payload:boolean) => ({type: types.HEADER_OPEN, payload}),
    headerToggleActive: (payload:boolean) => ({type: types.HEADER_ACTIVE, payload}),
    headerToggleNotification: (payload:boolean) => ({type: types.HEADER_NOTIFICATION, payload}),
    headerToggleShowRightChat: (payload:boolean) => ({type: types.HEADER_SHOW_RIGHT_CHAT, payload}),
    addLoaderId: (payload:string) => ({type: types.ADD_LOADER_ID, payload}),
    removeLoaderId: (payload:string) => ({type: types.REMOVE_LOADER_ID, payload}),
}

export default Setting

