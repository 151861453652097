/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import subMain from "../../redux/actions/mainn.action";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import { CoursesResponse } from "../../services/api.response.service";
import NoData from "../NoData";
import Load from "../Load";
import Courses from "./Courses";
import { AppState } from "../../services/app.service";

const CategoriesCourses = () => {
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const category_course: BaseState<Array<CoursesResponse>> =
    state[StateKeys.CATEGORY_COURSES];
  const { data, pageState } = category_course;

  useEffect(() => {
    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.CATEGORY_COURSES,
          apiEndpoint: ApiEndpoint.CATEGORY_COURSES(id),
          previousPage: 0,
          clear: true,
        })
      );
    }
  }, [id]);

  return (
    <>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0"></div>

          <div className="col-xl-12">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
              <div className="mb-0 mt-0 d-flex justify-content-between w-100 p-5">
                <h2 className="fw-700 font-md text-grey-900">courses</h2>
              </div>

              <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs*">
                <div className="p-5 ">
                  {pageState === AppState.LOADED ||
                  pageState === AppState.LOADING_MORE ? (
                    data.length > 0 ? (
                      <div className="row">
                        {data.map((course: CoursesResponse) => (
                          <Courses course={course} key={course.id} />
                        ))}
                      </div>
                    ) : (
                      <NoData message="No Courses Available" />
                    )
                  ) : (
                    <Load message="Loading" noMarginTop={true} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesCourses;
