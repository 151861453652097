export enum AppState {
  IDLE,
  SUCCESS,
  ERROR,
  ERROR_404,
  LOADING,
  LOADED,
  ERROR_INPUT,
  LOADING_MORE,
  INFO,
  WARNING,
  DATA_REFRESH,
  PLAYING,
  PLAY,
  PAUSING,
  PAUSED,
  RETRY,
}

export enum ResourceTypes {
  IDLE,
  SUCCESS,
  AUDIO,
  VIDEO,
}
