import React, {FormEvent, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states"
import Actions from "../../redux/actions";
import Load from '../../components/Load';
import {Link, Redirect} from 'react-router-dom';
import {RouteComponentProps} from "react-router";

const scheduleZone = new Date().toTimeString().slice(9,17)
function VerifyProfile({history}: RouteComponentProps) {
    const dispatch = useDispatch()
    const {user} = useSelector(((state: IRootState) => state.setting))

    const {
        loaderIds
    } = useSelector(((state: IRootState) => state.setting))

    const loadingRequest = () => loaderIds.includes(user!.id)

    const [scheduleDate, setScheduleDate] = useState('')

    const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            schedule_date: scheduleDate,
            schedule_zone: scheduleZone,
        }
        dispatch(Actions.main.setVerifyProfile(user!, data))
    }
    if(loaderIds.includes('verifyProfileSaved')){
        dispatch(Actions.setting.removeLoaderId('verifyProfileSaved'))
        history.push(`/profile/${user!.id}`)
    }
    if(!user!.profile){
        return <Redirect to={{pathname: `/verify/account`}} />
    }
    return <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
                <div className="middle-wrap">
                    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                        <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                            <Link to={`/profile/${user?.id}`} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"/></Link>
                            <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Verify Account</h4>
                        </div>
                        <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">Schedule a date</label>
                                            <input type="datetime-local" className="form-control" defaultValue={scheduleDate} required
                                                   onChange={(e)=>setScheduleDate(e.target.value)}
                                                   disabled={loadingRequest()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {loadingRequest() ? <Load message="Submitting"/> :
                                        <button type="submit" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">
                                            Submit
                                        </button>}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
}

export default VerifyProfile;