import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { UserProfileResponse } from "../services/api.response.service";
// import { UserProfileResponse } from "../../store/api.response.type";

const storySettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerMode: false,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 5000,
};

interface props {
  featuredMentors: Array<UserProfileResponse>;
}

const FeatureMentorSlider: React.FC<props> = ({ featuredMentors }) => {
  return (
    <Slider {...storySettings}>
      {(featuredMentors as Array<UserProfileResponse>)
        .slice(0, 6)
        .map((item) => (
          <div key={item.id}>
            <Link
              to={`/mentor/profile/${item.id}`}
              className="card w125 h200 d-block border-0 shadow-xss rounded-xxxl bg-gradiant-bottom overflow-hidden cursor-pointer mb-3 mt-0 me-3 bg-image-cover bg-image-center"
              style={{ backgroundImage: `url(${item.photo_url})` }}
            >
              <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center">
                <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                  <img
                    src={item.photo_url}
                    alt="avater"
                    className="float-right p-0 bg-white rounded-circle w45 h45 shadow-xss"
                  />
                </figure>
                <div className="clearfix mt-1" />
                <h4 className="fw-600 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1">
                  {item.first_name} {item.last_name}{" "}
                </h4>
              </div>
            </Link>
          </div>
        ))}
    </Slider>
  );
};

export default FeatureMentorSlider;
