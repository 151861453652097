import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PopularCourses from "../../components/Learn/PopularCourses";
import WhatToLearn from "../../components/Learn/WhatToLearn";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import {
  CategoryResponse,
  CoursesResponse,
} from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import SearchResults from "../../components/Learn/SearchResults";
const Learn = () => {
  const dispatch = useDispatch();
  //   const { user } = useSelector((state: IRootState) => state.setting);
  const state = useSelector((state: IRootState) => state.mainSub);
  const courses: BaseState<Array<CoursesResponse>> = state[StateKeys.COURSES];
  const LEARNING_CATEGORIES: BaseState<Array<CategoryResponse>> =
    state[StateKeys.COURSES];
  const searchResult: BaseState<Array<CategoryResponse>> =
    state[StateKeys.SEARCH_RESULT];

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    if (courses.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.COURSES,
          apiEndpoint: ApiEndpoint.COURSES,
          previousPage: courses.currentPage!,
        })
      );
    }

    if (LEARNING_CATEGORIES.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.LEARNING_CATEGORIES,
          apiEndpoint: ApiEndpoint.LEARNING_CATEGORIES,
          previousPage: LEARNING_CATEGORIES.currentPage!,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      subMain.getPaginatedPageData({
        stateKey: StateKeys.SEARCH_RESULT,
        apiEndpoint: ApiEndpoint.SEARCH_LEARN(search!),
        previousPage: searchResult.currentPage!,
      })
    );
  };

  return (
    <>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
                  <div className="mb-0 mt-0 d-flex flex-column   w-100 p-5">
                    <h2 className="fw-700 font-md text-grey-900">Explore</h2>
                    <form className="pt-3 pb-0 ms-auto*" onSubmit={submit}>
                      <div className="search-form-2* ms-2">
                        <input
                          type="search"
                          name="search"
                          id="search"
                          className="form-control w-100"
                          placeholder="What do you want to learn today?"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {searchResult.pageState === AppState.LOADED && <SearchResults />}
              <WhatToLearn />
              <PopularCourses />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Learn;
