import React from "react";
import { MessageResponse } from "../../services/api.response.service";
import moment from "moment";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/states";

interface Props {
  hideUser?: boolean;
  message: MessageResponse;
  setReplyMsg: (message: MessageResponse) => void;
}

function MessageItem({ hideUser, message, setReplyMsg }: Props) {
  const { user } = useSelector((state: IRootState) => state.setting);
  const outgoing = user?.id === message.sender.id;
  const info = message.type === "info";

  return (
    <div
      className={`message-item mw-60 ${outgoing ? "outgoing-message" : ""} ${
        info ? "info-message" : ""
      }`}
    >
      {!info && (
        <div className="message-user">
          {(!hideUser!! && outgoing) || !outgoing ? (
            <>
              <figure className="avatar">
                <img src={message.sender.photo_url} alt="avatar" />
              </figure>
              <div>
                <h5>
                  {outgoing
                    ? "You"
                    : `${message.sender.first_name} ${message.sender.last_name}`}
                </h5>
                <div className="time">
                  {moment(message.created_at).format("h:m a")}
                  <i className="ti-double-check text-info" />
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="time">
                {moment(message.created_at).format("hh:mm a")}
                <i className="ti-double-check text-info" />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`message-wrap ${info ? "bg-dark" : ""}`}
        onDoubleClick={() => setReplyMsg(message)}
        style={{
          cursor: "pointer",
        }}
      >
        {message.reply_to && (
          <p className="bg-grey px-2 text-dark text-truncate">
            <>
              {user?.id === message?.reply_to.sender.id ? (
                <span className="text-current">You</span>
              ) : (
                <>
                  {" "}
                  <span className="text-current">
                    {" "}
                    {message?.reply_to.sender.first_name}{" "}
                    {message?.reply_to.sender.last_name}
                  </span>
                </>
              )}{" "}
            </>{" "}
            <br />
            <span style={{ color: "black" }}>{message.reply_to.body} </span>
          </p>
        )}
        {message.body}
      </div>
    </div>
  );
}

export default MessageItem;
