import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/states";
import FriendRequests from "../../components/FriendRequests";
import CreateStory from "../../components/CreateStory";
import Storyslider from "../../components/FeatureMentorSlider";
import Postview from "../../components/Story";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import SuggestedFriends from "../../components/SuggestedFriends";
import HomeAnnouncements from "../../components/HomeAnnouncements";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import {
  StoryResponse,
  UserProfileResponse,
  AnnouncementResponse,
  UserResponseFriendRequest,
} from "../../services/api.response.service";
// import {
// StoryResponse,
// UserProfileResponse,
// AnnouncementResponse,
// UserResponseFriendRequest,
// } from "../../../store/api.response.type";

function Home() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const stories: BaseState<Array<StoryResponse>> = state[StateKeys.STORIES];
  const featuredMentors: BaseState<Array<UserProfileResponse>> =
    state[StateKeys.FEATURED_MENTORS];
  const announcements: BaseState<Array<AnnouncementResponse>> =
    state[StateKeys.ANNOUNCEMENTS];
  const friendRequests: BaseState<Array<UserResponseFriendRequest>> =
    state[StateKeys.REQUESTED_CONNECTIONS];
  const suggestedFriends: BaseState<Array<UserProfileResponse>> =
    state[StateKeys.SUGGESTED_CONNECTIONS];

  const { data, pageState, hasMore, currentPage, postPageState } = stories;

  useEffect(() => {
    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.STORIES,
          apiEndpoint: ApiEndpoint.STORIES,
          previousPage: currentPage!,
        })
      );
    }

    if (featuredMentors.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.FEATURED_MENTORS,
          apiEndpoint: ApiEndpoint.FEATURED_MENTORS,
          previousPage: currentPage!,
        })
      );
    }

    if (announcements.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.ANNOUNCEMENTS,
          apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
          previousPage: currentPage!,
        })
      );
    }

    if (friendRequests.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.REQUESTED_CONNECTIONS,
          apiEndpoint: ApiEndpoint.REQUESTED_CONNECTIONS,
          previousPage: currentPage!,
        })
      );
    }
    if (suggestedFriends.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.SUGGESTED_CONNECTIONS,
          apiEndpoint: ApiEndpoint.SUGGESTED_CONNECTIONS,
          previousPage: currentPage!,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row feed-body">
            <div className="col-xl-8 col-xxl-9 col-lg-8">
              {featuredMentors.pageState === AppState.LOADED ? (
                featuredMentors.data.length > 0 && (
                  <Storyslider featuredMentors={featuredMentors.data} />
                )
              ) : (
                <Load message="Loading Featured Mentors" />
              )}
              <CreateStory isPublic={true} />
              {pageState === AppState.LOADED ||
              pageState === AppState.LOADING_MORE ? (
                data?.length > 0 ? (
                  <>
                    {(data as Array<StoryResponse>).map((item) => (
                      <Postview
                        key={item.id}
                        story={item}
                        detailed={false}
                        postPageState={postPageState}
                        isPublic={true}
                      />
                    ))}
                    {hasMore && pageState === AppState.LOADED && (
                      <div
                        className="col-lg-12 mt-3 mb-5 text-center"
                        onClick={() =>
                          dispatch(
                            subMain.getPaginatedPageData({
                              stateKey: StateKeys.STORIES,
                              apiEndpoint: ApiEndpoint.STORIES,
                              previousPage: currentPage!,
                              params: { is_public: 1 },
                            })
                          )
                        }
                      >
                        <span
                          className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150"
                          style={{ cursor: "pointer" }}
                        >
                          Load More
                        </span>
                      </div>
                    )}
                    {pageState === AppState.LOADING_MORE && <Load />}
                  </>
                ) : (
                  <NoData message="No Stories" />
                )
              ) : (
                <Load message="Loading Stories" />
              )}
            </div>
            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0 ps-lg-2">
              <HomeAnnouncements />
              <FriendRequests />
              <SuggestedFriends />
              {/*<Group/>
                            <Events/>
                            <Profilephoto/>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
