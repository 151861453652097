import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import { Link, Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Countries, pronouns, races } from "../../utils/constants";

function EditProfile({ history }: RouteComponentProps) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);
  const { profile, loaded, loader, message, error } = useSelector(
    (state: IRootState) => state.main.profile
  );

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  const profileId = user!.id;

  const loadingRequest = () => loaderIds.includes(profileId);

  const [firstName, setFirstName] = useState(user!.first_name ?? "");
  const [lastName, setLastName] = useState(user!.last_name ?? "");
  // const [email, setEmail] = useState(user!.email ?? '')
  const [country, setCountry] = useState(user!.country ?? "");
  const [city, setCity] = useState(user!.city ?? "");
  const [phoneNumber, setPhoneNumber] = useState(user!.phone_number ?? "");
  const [about, setAbout] = useState(user!.about ?? "");
  const [guardianName, setGuardianName] = useState(
    user!.profile?.guardian_name ?? ""
  );
  const [guardianEmail, setGuardianEmail] = useState(
    user!.profile?.guardian_email ?? ""
  );
  const [guardianContact, setGuardianContact] = useState(
    user!.profile?.guardian_contact ?? ""
  );
  const [guardianAddress, setGuardianAddress] = useState(
    user!.profile?.guardian_address ?? ""
  );
  const [dateOfBirth, setDateOfBirth] = useState(user!.date_of_birth);
  const [race, setRace] = useState("");
  const [pronoun, setPronoun] = useState("");

  // const [position, setPosition] = useState(profile?.mentor_profile?.position ?? '')

  useEffect(() => {
    if (profile?.id !== profileId || !loaded) {
      dispatch(Actions.main.getProfile(profileId, user!));
    }
  }, [profileId]);
  if (loaderIds.includes("updateProfileSaved")) {
    dispatch(Actions.setting.removeLoaderId("updateProfileSaved"));
    history.push(`/profile/${profileId}`);
  }
  if (!user!.profile) {
    return <Redirect to={{ pathname: `/edit/mentor/profile` }} />;
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      country,
      city,
      about,
      phone_number: phoneNumber,
      date_of_birth: dateOfBirth,
      guardian_name: guardianName,
      guardian_email: guardianEmail,
      guardian_contact: guardianContact,
      guardian_address: guardianAddress,
      race,
      pronoun,
    };
    dispatch(Actions.main.setEditProfile(profileId, data));
  };

  return (
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link
                  to={`/profile/${user?.id}`}
                  className="d-inline-block mt-2"
                >
                  <i className="ti-arrow-left font-sm text-white" />
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                  Edit Profile
                </h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={firstName}
                          required
                          onChange={(e) => setFirstName(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={lastName}
                          required
                          onChange={(e) => setLastName(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/*<div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">Email</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>*/}

                    <div className="col-lg-6* mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={phoneNumber}
                          required
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group icon-input mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Race
                        </label>
                        <select
                          name="race"
                          id="race"
                          className="style2-input form-select text-grey-900 font-xsss fw-600"
                          style={{ lineHeight: 0 }}
                          onChange={(e) => setRace(e.target.value)}
                        >
                          {races.map((race: string) => (
                            <option value={race}>{race}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group icon-input mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Pronoun
                        </label>
                        <select
                          name="race"
                          id="race"
                          className="style2-input form-select text-grey-900 font-xsss fw-600"
                          style={{ lineHeight: 0 }}
                          onChange={(e) => setPronoun(e.target.value)}
                        >
                          {pronouns.map((pronoun: string) => (
                            <option value={pronoun}>{pronoun}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6* mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={dateOfBirth}
                          max="2010-01-01"
                          onChange={(e) => setDateOfBirth(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Country
                        </label>

                        <select
                          name="country"
                          id="country"
                          className="style2-input ps-5* form-select text-grey-900 font-xsss fw-600"
                          style={{ lineHeight: 0 }}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          {Countries.map((country: string) => (
                            <option value={country}>{country}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Town / City
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={city}
                          required
                          onChange={(e) => setCity(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Guardian name
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={guardianName}
                          onChange={(e) => setGuardianName(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Guardian email
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={guardianEmail}
                          onChange={(e) => setGuardianEmail(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Guardian contact
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={guardianContact}
                          onChange={(e) => setGuardianContact(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          Guardian address
                        </label>
                        <input
                          type="text"
                          className="style2-input form-control text-grey-900 font-xsss fw-600"
                          defaultValue={guardianAddress}
                          onChange={(e) => setGuardianAddress(e.target.value)}
                          disabled={loadingRequest()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/*<div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">Twon / City</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="mont-font fw-600 font-xsss mb-2">Postcode</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>*/}

                    {/*<div className="col-lg-12 mb-3">
                                        <div className="card mt-3 border-0">
                                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                <div className="form-group mb-0 w-100">
                                                    <input type="file" name="file" id="file" className="input-file" />
                                                    <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                        <i className="ti-cloud-down large-icon me-3 d-block"/>
                                                        <span className="js-fileName">Drag and drop or click to replace</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                    <div className="col-lg-12 mb-3">
                      <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                        About
                      </label>
                      <textarea
                        className="style2-input form-control mb-0 p-3 h100 bg-greylight lh-16"
                        rows={5}
                        placeholder="Tell us something about yourself"
                        onChange={(e) => setAbout(e.target.value)}
                        defaultValue={about}
                        disabled={loadingRequest()}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      {loadingRequest() ? (
                        <Load message="Saving changes" />
                      ) : (
                        <button
                          type="submit"
                          className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
