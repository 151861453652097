import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import { RouteComponentProps, Redirect } from "react-router";
import NoData from "../../components/NoData";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { StoryResponse } from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import subMain from "../../redux/actions/mainn.action";

function EditStory({ location }: RouteComponentProps) {
  const storyId = location.pathname.replace("/story/edit/", "");
  const dispatch = useDispatch();
  const { user, loaderIds } = useSelector((state: IRootState) => state.setting);
  const { story } = useSelector((state: IRootState) => state.mainSub);
  // const story: BaseState<Array<StoryResponse>> = state[StateKeys.STORY];

  const { data, pageState, postPageState } = story;

  //   const { story, loaded, loader, message, error } = useSelector(
  //     (state: IRootState) => state.main.story
  //   );

  const [enablePublic, setEnablePublic] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [file, setFile] = useState<File | any>();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const editedStory = {
      title,
      body,
      is_public: enablePublic ? !data!.is_public : data!.is_public,
    };

    dispatch(
      subMain.postPageData({
        stateKey: StateKeys.STORY,
        apiEndpoint: ApiEndpoint.EDIT_STORY(storyId),
        req: { data: { ...editedStory }, file },
      })
    );
    // // console.log(file)
    // dispatch(Actions.main.editStory(storyId, data, file));

    // dispatch(
    //   subMain.postPageData({
    //     stateKey: StateKeys.STORIES,
    //     apiEndpoint: ApiEndpoint.STORY(storyId),
    //     req: { data, file },
    //     append: "start",
    //   })
    // );
  };

  useEffect(() => {
    if (pageState !== AppState.LOADED && data?.id === storyId) {
      setTitle(data!.title);
      setBody(data!.body);
      setFile(null);
    }
  }, [pageState]);

  useEffect(() => {
    if (data?.id !== storyId || pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPageObjectData({
          stateKey: StateKeys.STORY,
          apiEndpoint: ApiEndpoint.STORY(storyId),
        })
      );
    }
  }, [storyId]);

  console.log(pageState, postPageState, AppState.LOADED);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            {pageState === AppState.LOADED ? (
              pageState === AppState.LOADED ? (
                data && data.by.id === user!.id ? (
                  <>
                    <div className="col-xl-12">
                      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
                        <div className="mb-0 mt-0 d-flex align-items-center p-4">
                          <h2 className="fw-700 font-md text-grey-900">
                            Edit Story
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                        <form onSubmit={handleSubmit}>
                          <div className="card-body p-0">
                            <div className="d-flex align-items-end justify-content-end">
                              {/*<button className={`d-none d-lg-block bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 me-3`} title="Save">
                                                            <i className={`feather-x font-xss me-2`}/>
                                                            Cancel
                                                        </button>*/}
                              {postPageState !== AppState.LOADED ? (
                                <Load
                                  spin={true}
                                  noCard={true}
                                  noPadding={true}
                                  noMarginTop={true}
                                />
                              ) : (
                                <button
                                  className={`d-none d-lg-block bg-${
                                    data?.is_public ? "success" : "primary"
                                  } p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3`}
                                  title="Save"
                                >
                                  <i className={`ti-save font-xss  me-2`} />
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              <input
                                type="checkbox"
                                className="form-check "
                                defaultChecked={enablePublic}
                                onChange={(e) =>
                                  setEnablePublic(e.target.checked)
                                }
                              />
                              Save as {data?.is_public ? "Private" : "Public"}
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Title
                            </label>
                            <input
                              type="text"
                              className="bor-0 w-100 rounded-xxl p-2 ps-3 font-xssss text-grey-800 fw-500 border-light-md theme-dark-bg"
                              defaultValue={data.title}
                              required
                              disabled={postPageState !== AppState.LOADED}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                          <div className="card-body p-0 mt-3 position-relative">
                            <figure className="avatar position-absolute ms-2 mt-1 top-5">
                              <img
                                src={user!.photo_url}
                                alt="icon"
                                className="shadow-sm rounded-circle w30 h30"
                              />
                            </figure>
                            <textarea
                              name="message"
                              className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-800 fw-500 border-light-md theme-dark-bg"
                              style={{ minHeight: 100, maxHeight: 500 }}
                              cols={30}
                              placeholder="What's on your mind?"
                              defaultValue={data.body}
                              disabled={postPageState !== AppState.LOADED}
                              maxLength={2500}
                              onChange={(e) => setBody(e.target.value)}
                            />
                          </div>
                          <div className="card-body d-flex p-0 mt-0">
                            <label className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 me-2">
                              <i
                                className={`font-md text-${
                                  data?.is_public ? "success" : "primary"
                                } feather-${file ? "repeat" : "image"} me-2`}
                              />
                              <span className="d-none-xs">
                                {file ? "Change" : "Add"} Photo
                              </span>
                              <input
                                type="file"
                                className="d-none"
                                accept="image/*"
                                disabled={
                                  postPageState !== AppState.LOADED
                                }
                                onChange={(e) =>
                                  setFile(
                                    e.target.files ? e.target.files[0] : null
                                  )
                                }
                              />
                            </label>
                            {/*{file && <span className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4" onClick={()=>setFile(null)}>
                                                        <i className={`font-md text-danger feather-x me-2`}/>
                                                        <span className="d-none-xs">Remove Photo</span>
                                                    </span>}*/}
                          </div>
                        </form>
                        {!file && (
                          <div className="card-body d-flex p-0 mt-3">
                            <img
                              src={data?.cover_image}
                              alt="Preview"
                              className="w-100"
                            />
                          </div>
                        )}
                        {file && (
                          <div className="card-body d-flex p-0 mt-3">
                            <img
                              src={URL.createObjectURL(file)}
                              alt="Preview"
                              className="w-100"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Redirect to={"/journal"} />
                )
              ) : (
                <NoData />
              )
            ) : (
              <Load message="Loading" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStory;
