import React from 'react';
import {Container,ButtonContainer,ButtonLink,Icon,Content} from "./styles";

const Mobile = () =>{
    return <Container>
        <Icon src="/assets/img/logo.png" style={{width: "50%"}}/>
        <Content>Get My Power App on your mobile. <br/>Visit:</Content>
        <ButtonContainer>
            <ButtonLink href={process.env.REACT_APP_PLAY_STORE_LINK}
                        target="_blank">
                <Icon src="/assets/img/playStore.png" style={{width: "109%", marginLeft: 25}}/>
            </ButtonLink>
            <ButtonLink href={process.env.REACT_APP_APP_STORE_LINK}
                        target="_blank">
                <Icon src="/assets/img/appleStore.png"/>
            </ButtonLink>
        </ButtonContainer>
    </Container>
}

export default Mobile