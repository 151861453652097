const postFix = process.env.REACT_APP_ENV  === 'production'? '': '_dev'

export const KEYS = {
    APP_STORE_LINK: 'app_store_link',
    PLAY_STORE_LINK: 'play_store_link',
    RECAPTCHA_SECRET_KEY: 'recaptcha_secret_key',
    RECAPTCHA_SITE_KEY: 'recaptcha_site_key',
    API_ENDPOINT: 'api_endpoint',
    CLUSTER: 'chat_cluster',
    SECRET: 'chat_secret',
    KEY: 'chat_key',
    API_ID: 'chat_app_id',
}

export const getKey = (key:string) => `${key}${postFix}`
