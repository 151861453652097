import React from 'react';
import ReactDOM from 'react-dom';
import './font/helvetica.ttf';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./app/redux/store"
import {Provider} from "react-redux";
import {runSagas} from "./app/redux/sagas";
import './main.scss';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {AppState} from "./app/services/app.service";

runSagas()

window.Pusher = new Pusher('10f64f406d59e99a91da',{cluster: "mt1"});

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '10f64f406d59e99a91da',
  cluster: 'mt1',
  forceTLS: true,
  client: new Pusher('10f64f406d59e99a91da',{cluster: "mt1"})
});

window.logger = (type = AppState.WARNING,...a: any[]) =>{
  switch (type){
    case AppState.ERROR:
      console.error(a)
      break
    default:
      console.log(a)
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
