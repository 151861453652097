import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { MessageResponse } from "../../services/api.response.service";

interface Props {
  replyMsg: MessageResponse;
  setReplyMsg: (message: MessageResponse) => void;
}

const MessageReply = ({ replyMsg, setReplyMsg }: Props) => {
  const { user } = useSelector((state: IRootState) => state.setting);

  return (
    <>
      {replyMsg && (
        <>
          <p className="bg-grey text-white* text-truncate px-5">
            <small>
              Replying to{" "}
              <>
                {user?.id === replyMsg?.sender.id ? (
                  "yourself"
                ) : (
                  <>
                    {" "}
                    {replyMsg?.sender.first_name} {replyMsg?.sender.last_name}
                  </>
                )}{" "}
              </>
            </small>
            <br />
            {replyMsg?.body}
          </p>{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="grey"
            className="bi bi-x-circle-fill position-absolute"
            style={{
              top: "20px",
              right: "30px",
              cursor: "pointer",
            }}
            onClick={() => setReplyMsg(null!)}
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </>
      )}
    </>
  );
};

export default MessageReply;
