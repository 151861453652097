import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import Actions from "../redux/actions";
import Load from "./Load";
import subMain from "../redux/actions/mainn.action";
import { BaseState, StateKeys } from "../redux/states/mainn.state";
import { ApiEndpoint } from "../services/api.endpoint.service";
import { AppState } from "../services/app.service";
import { StoryRequest } from "../services/api.request.service";
import { StoryResponse } from "../services/api.response.service";

interface Props {
  isPublic: boolean;
}

function CreateStory({ isPublic }: Props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);

  const state = useSelector((state: IRootState) => state.mainSub);
  const stories: BaseState<Array<StoryResponse>> = state[StateKeys.STORIES];

  const publicJournal: BaseState<Array<StoryResponse>> =
    state[StateKeys.JOURNAL];
  const privateJournal: BaseState<Array<StoryResponse>> =
    state[StateKeys.PRIVATE_JOURNAL];

  const [isOpen, setIsOpen] = useState(false);
  // const [enablePublic, setEnablePublic] = useState(isPublic)
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [file, setFile] = useState<File | any>();
  const [saving, setSaving] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const menuClass = () => `${isOpen ? " show" : ""}`;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: StoryRequest = {
      title,
      body,
      is_public: isPublic,
    };

    setSaving(true);
    dispatch(
      subMain.postPageData({
        stateKey:
          StateKeys[
            //check if path name is not home then make stateKeys
            window.location.pathname !== "/home"
              ? isPublic
                ? "JOURNAL"
                : "PRIVATE_JOURNAL"
              : "STORIES"
          ],
        apiEndpoint: ApiEndpoint.POST_STORY,
        append: "start",
        req: { data, file },
      })
    );
  };

  useEffect(() => {
    if (
      (stories.postPageState === AppState.LOADED ||
        publicJournal.postPageState === AppState.LOADED ||
        privateJournal.postPageState === AppState.LOADED) &&
      title.length > 0
    ) {
      setTitle("");
      setBody("");
      setFile(null);
      setSaving(false);
    }
  }, [
    stories.postPageState,
    publicJournal.postPageState,
    privateJournal.postPageState,
  ]);

  return (
    <>
      {saving ? (
        <Load message={`Saving Story`} noMarginTop={true} />
      ) : (
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <span className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center">
              <i
                className={`btn-round-sm font-xs text-${
                  isPublic ? "success" : "primary"
                } feather-edit-3 me-2 bg-greylight`}
              />
              Create {isPublic ? "Public" : "Private"} Story
            </span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss mb-2">Title</label>
              <input
                disabled={stories.postPageState === AppState.LOADING}
                type="text"
                className="bor-0 w-100 rounded-xxl p-2 ps-3 font-xssss text-grey-800 fw-500 border-light-md theme-dark-bg"
                defaultValue={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="card-body p-0 mt-3 position-relative">
              <figure className="avatar position-absolute ms-2 mt-1 top-5">
                <img
                  src={user!.photo_url}
                  alt="icon"
                  className="shadow-sm rounded-circle w30 h30"
                />
              </figure>
              <textarea
                disabled={stories.postPageState === AppState.LOADING}
                name="message"
                className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-800 fw-500 border-light-md theme-dark-bg"
                style={{ minHeight: 100, maxHeight: 500 }}
                cols={30}
                placeholder="What's on your mind?"
                defaultValue={body}
                maxLength={2500}
                onChange={(e) => setBody(e.target.value)}
              />
            </div>
            <div className="card-body d-flex p-0 mt-0">
              {/*<a href="#video" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Live Video</span></a>*/}
              <label className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 me-2">
                <i
                  className={`font-md text-${
                    isPublic ? "success" : "primary"
                  } feather-${file ? "repeat" : "image"} me-2`}
                />
                <span className="d-none-xs">
                  {file ? "Change" : "Add"} Photo
                </span>
                <input
                  disabled={stories.postPageState === AppState.LOADING}
                  type="file"
                  className="d-none"
                  accept="image/*"
                  onChange={(e) =>
                    setFile(e.target.files ? e.target.files[0] : null)
                  }
                />
              </label>
              {/*{file && <span className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4" onClick={()=>setFile(null)}>
                        <i className={`font-md text-danger feather-x me-2`}/>
                        <span className="d-none-xs">Remove Photo</span>
                    </span>}*/}
              {title.length > 0 && body.length > 0 && (
                <button
                  disabled={stories.postPageState === AppState.LOADING}
                  className={`ms-auto pointer border-0 bg-transparent`}
                  title="Save"
                >
                  <i
                    className={`ti-save text-white btn-round-md bg-${
                      isPublic ? "success" : "primary"
                    } font-xss`}
                  />
                </button>
              )}
              {/*<a href="#activity" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-warning feather-camera me-2"></i><span className="d-none-xs">Feeling/Activity</span></a>*/}
              {/*<div className={`ms-auto pointer ${menuClass()}`} id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}>
                    <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"/></div>*/}
              <div
                className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${menuClass()}`}
                aria-labelledby="dropdownMenu4"
              >
                <div className="card-body p-0 d-flex">
                  <i className="feather-bookmark text-grey-500 me-3 font-lg" />
                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                    Save Link{" "}
                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                      Add this to your saved items
                    </span>
                  </h4>
                </div>
                <div className="card-body p-0 d-flex mt-2">
                  <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                    Hide Post{" "}
                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                      Save to your saved items
                    </span>
                  </h4>
                </div>
                <div className="card-body p-0 d-flex mt-2">
                  <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                    Hide all from Group{" "}
                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                      Save to your saved items
                    </span>
                  </h4>
                </div>
                <div className="card-body p-0 d-flex mt-2">
                  <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">
                    Unfollow Group{" "}
                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                      Save to your saved items
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </form>
          {file && (
            <div className="card-body d-flex p-0 mt-3">
              <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                className="w-100"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CreateStory;
