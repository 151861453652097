import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { UserResponseFriendRequest } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";

function Connections() {
  const dispatch = useDispatch();
  // const {
  //     friendRequestsLoader,
  //     friendRequestsLoadedPage,
  //     friendRequests,
  //     friendRequestsLoaded,
  //     hasMoreFriendRequests,
  //     loadMoreFriendRequests,
  // } = useSelector(((state: IRootState) => state.main.home))

  const state = useSelector((state: IRootState) => state.mainSub);

  const friendRequests: BaseState<Array<UserResponseFriendRequest>> =
    state[StateKeys.REQUESTED_CONNECTIONS];

  const { data, pageState, hasMore, currentPage } = friendRequests;

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  useEffect(() => {
    // if (friendRequestsLoadedPage === 0 && !friendRequestsLoaded) {
    //     dispatch(Actions.main.getHomeFriendRequests(friendRequestsLoadedPage, friendRequests))
    // }

    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.REQUESTED_CONNECTIONS,
          apiEndpoint: ApiEndpoint.REQUESTED_CONNECTIONS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const acceptRequest = (id: string) => {
    if (!loaderIds.includes(id)) {
      dispatch(Actions.main.getHomeFriendRequestsAccept(id));
    }
  };

  const declineRequest = (id: string) => {
    if (!loaderIds.includes(id)) {
      dispatch(Actions.main.getHomeFriendRequestsDecline(id));
    }
  };

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <div className="mb-0 mt-0 d-flex align-items-center">
                  <h2 className="fw-700 font-md text-grey-900">My Requests</h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                <div className="search-form-2 ms-2">
                                    <i className="ti-search font-xss"/>
                                    <input type="text" className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                                </div>
                            </form>*/}
                </div>
              </div>

              <div className="row ps-2 pe-1">
                {pageState === AppState.LOADED ? (
                  data.length > 0 ? (
                    <>
                      {(data as Array<UserResponseFriendRequest>).map(
                        (item) => (
                          <div
                            key={item.friend_id}
                            className="col-md-6 col-sm-6 pe-2 ps-2"
                          >
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                              <div
                                className="card-body position-relative h100 bg-image-cover bg-image-center"
                                style={{
                                  backgroundImage: `url(${item.user.video_url})`,
                                }}
                              />
                              <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                                <Link
                                  to={`/profile/${item.user.id}`}
                                  title="Profile"
                                >
                                  <figure
                                    className="avatar position-absolute w75 z-index-1 left-15"
                                    style={{ marginTop: `-40px` }}
                                  >
                                    {/*<video autoPlay={true} loop={true} className="float-right w-100">
                                                                <source src={item.user.video_url}/>
                                                            </video>*/}
                                    <img
                                      src={item.user.photo_url}
                                      alt={item.user.first_name}
                                      className="float-right p-1 bg-white rounded-circle w-100 "
                                    />
                                  </figure>
                                  <div className="clearfix" />
                                  <h4 className="fw-700 font-xsss mt-3 mb-1">
                                    {item.user.first_name} {item.user.last_name}
                                  </h4>
                                  <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">
                                    <i className="ti-location-pin me-1" />{" "}
                                    {`${item.user.city} ${item.user.country}`}
                                  </p>
                                </Link>
                                <span className="position-absolute right-15 top-0 d-flex align-items-center">
                                  {loaderIds.includes(item.friend_id) ? (
                                    <Load
                                      spin={true}
                                      noCard={true}
                                      noPadding={true}
                                      noMarginTop={true}
                                    />
                                  ) : (
                                    <>
                                      <span
                                        title="Decline"
                                        className="d-lg-block d-none"
                                        onClick={() =>
                                          declineRequest(item.friend_id)
                                        }
                                      >
                                        <i className="feather-x btn-round-md font-md bg-danger text-white" />
                                      </span>
                                      <span
                                        title="Accept"
                                        className="d-lg-block d-none ms-1 ls-3"
                                        onClick={() =>
                                          acceptRequest(item.friend_id)
                                        }
                                      >
                                        <i className="feather-check btn-round-md font-md bg-primary-gradiant text-white" />
                                      </span>
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      {hasMore && pageState === AppState.LOADED && (
                        <div
                          className="col-lg-12 mt-3 mb-5 text-center"
                          onClick={() =>
                            dispatch(
                              subMain.getPaginatedPageData({
                                stateKey: StateKeys.REQUESTED_CONNECTIONS,
                                apiEndpoint: ApiEndpoint.REQUESTED_CONNECTIONS,
                                previousPage: currentPage!,
                              })
                            )
                          }
                        >
                          <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                            Load More
                          </span>
                        </div>
                      )}
                      {pageState === Number(AppState.LOADING_MORE) && <Load />}
                    </>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <Load message="Loading" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Connections;
