import { all, call, put, takeEvery } from "redux-saga/effects";
import { BaseDataResponse } from "../../services/api.response.service";
import Api from "../../services/api.service";
import { AppState } from "../../services/app.service";
import Actions from "../actions";
import {
  PageDataPayload,
  PaginatedDataResponse,
  PaginatedPageDataPayload,
  PostPageDataPayload,
  PostPaginatedPageDataPayload,
  types,
} from "../actions/mainn.action";

function* getPageArrayData({ payload }: any) {
  const { stateKey, apiEndpoint, storeKey, clear }: PageDataPayload = payload;
  yield put(
    Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING })
  );

  try {
    const {
      data: { data },
    }: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.getPageData,
      apiEndpoint,
      {}
    );
    yield put(Actions.mainSub.postPageDataPersist({ stateKey, data }));
    yield put(
      Actions.mainSub.postPageArrayDataSuccess({
        stateKey,
        data,
        clear: !!clear,
      })
    );
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.mainSub.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.mainSub.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.mainSub.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* getPageObjectData({ payload }: any) {
  const { stateKey, apiEndpoint, storeKey }: PageDataPayload = payload;
  yield put(
    Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING })
  );
  try {
    // const {data:{data}}:BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(Api.getPageData, apiEndpoint)
    let data;
    const response: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.getPageData,
      apiEndpoint,
      {}
    );

    if (response.data.data) {
      data = response.data.data;
    } else {
      data = response.data;
    }

    console.log("main saga", data);

    // yield put(Actions.mainSub.postPageDataPersist({stateKey, data}))
    yield put(Actions.mainSub.postPageObjectDataSuccess({ stateKey, data }));
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.mainSub.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.mainSub.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.mainSub.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* getPaginatedPageData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    previousPage,
    obj,
    refresh,
    storeKey,
    clear,
    params,
  }: PaginatedPageDataPayload = payload;

  console.log("paginated", params);

  if (refresh) {
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.DATA_REFRESH })
    );
  } else if (previousPage === 0) {
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING })
    );
  } else {
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING_MORE })
    );
  }
  const page = Number(previousPage + 1);
  try {
    const { data: baseData }: BaseDataResponse<any> = yield call(
      Api.getPaginatedPageData,
      apiEndpoint,
      { page, ...(params ?? {}) }
    );

    // if (obj) {
    //   const {
    //     content_class,
    //     test_class,
    //   }: PaginatedDataWithObjectResponse<Array<any>> = baseData;
    //   yield put(
    //     Actions.mainSub.postPaginatedPageObjectSuccess({
    //       stateKey,
    //       data: content_class ?? test_class,
    //     })
    //   );
    // }
    const { data, meta, links }: PaginatedDataResponse<Array<any>> = baseData;
    // yield put(Actions.mainSub.postPageDataPersist({stateKey, data: baseData}))
    yield put(
      Actions.mainSub.postPaginatedPageDataSuccess({
        stateKey,
        data,
        clear: !!clear,
      })
    );
    yield put(
      Actions.mainSub.setPaginatedPageCurrentPageNumber({
        stateKey,
        data: meta.current_page,
      })
    );
    yield put(
      Actions.mainSub.togglePaginatedPageHasMore({
        stateKey,
        data: !!links.next,
      })
    );
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.mainSub.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.mainSub.togglePageState({
        stateKey,
        data: e?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.mainSub.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* postPageObjectData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    storeKey,
    req,
    clear,
    append,
    objectID,
  }: PostPageDataPayload = payload;

  // yield put(
  //   Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING })
  // );

  yield put(
    req.data
      ? Actions.mainSub.togglePostPageState({
          stateKey,
          data: AppState.LOADING,
        })
      : objectID
      ? Actions.mainSub.togglePostPageState({
          stateKey,
          data: AppState.LOADING,
        })
      : Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING })
  );

  try {
    if (req.file) {
      const { data } = yield call(Api.uploadFile, {
        file: req.file,
        for: "story",
        type: "image",
      });
      payload = {
        ...req.data,
        resources: [{ type: "image", url: data.url }],
      };
    } else {
      payload = {
        ...req.data,
      };
    }

    const {
      data: { data },
    }: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.postPageData,
      apiEndpoint,
      req.data ? payload : req,
      {}
    );

    yield put(Actions.mainSub.postPageDataPersist({ stateKey, data }));
    yield put(
      Actions.mainSub.postPageObjectDataSuccess({
        stateKey,
        data,
        clear: !!clear,
        append,
      })
    );
    // yield put(
    //   Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADED })
    // );

    yield put(
      req.data
        ? Actions.mainSub.togglePostPageState({
            stateKey,
            data: AppState.LOADED,
          })
        : objectID
        ? Actions.mainSub.togglePostPageState({
            stateKey,
            data: AppState.LOADED,
          })
        : Actions.mainSub.togglePageState({
            stateKey,
            data: AppState.LOADED,
          })
    );

    // yield put(
    //   Actions.mainSub.togglePostPageState({
    //     stateKey,
    //     data: AppState.POST_LOADED,
    //   })
    // );

    if (storeKey) {
      yield put(
        Actions.mainSub.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    console.log(e)
    yield put(
      Actions.mainSub.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.mainSub.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* deletePageObjectData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    storeKey,
    req,
    clear,
    append,
    objectID,
  }: PostPageDataPayload = payload;

  yield put(
    Actions.mainSub.togglePostPageState({
      stateKey,
      data: AppState.LOADING,
    })
  );

  try {
    const {
      data: { data },
    }: BaseDataResponse<BaseDataResponse<Array<any>>> = yield call(
      Api.deletePageData,
      apiEndpoint,
      req,
      {}
    );

    yield put(Actions.mainSub.postPageDataPersist({ stateKey, data }));

    yield put(
      Actions.mainSub.deletePageObjectDataSuccess({
        stateKey,
        data,
        clear: !!clear,
        append,
        objectID,
      })
    );

    yield put(
      Actions.mainSub.togglePostPageState({
        stateKey,
        data: AppState.LOADED,
      })
    );

    if (storeKey) {
      yield put(
        Actions.mainSub.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.mainSub.togglePageState({
        stateKey,
        data: e.response?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.mainSub.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

function* postPaginatedPageData({ payload }: any) {
  const {
    stateKey,
    apiEndpoint,
    previousPage,
    obj,
    refresh,
    storeKey,
    clear,
    req,
  }: PostPaginatedPageDataPayload = payload;

  if (refresh) {
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.DATA_REFRESH })
    );
  } else if (previousPage === 0) {
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING })
    );
  } else {
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADING_MORE })
    );
  }

  const page = Number(previousPage + 1);
  try {
    const { data: baseData }: BaseDataResponse<any> = yield call(
      Api.postPaginatedPageData,
      apiEndpoint,
      req,
      { page }
    );
    // if (obj) {
    //   const {
    //     content_class,
    //     test_class,
    //   }: PaginatedDataWithObjectResponse<Array<any>> = baseData;
    //   yield put(
    //     Actions.mainSub.postPaginatedPageObjectSuccess({
    //       stateKey,
    //       data: content_class ?? test_class,
    //     })
    //   );
    // }
    const { data, meta, links }: PaginatedDataResponse<Array<any>> = baseData;
    // yield put(Actions.main.postPageDataPersist({stateKey, data: baseData}))
    yield put(
      Actions.mainSub.postPaginatedPageDataSuccess({
        stateKey,
        data,
        clear: !!clear,
      })
    );
    yield put(
      Actions.mainSub.setPaginatedPageCurrentPageNumber({
        stateKey,
        data: meta.current_page,
      })
    );
    yield put(
      Actions.mainSub.togglePaginatedPageHasMore({
        stateKey,
        data: !!links.next,
      })
    );
    yield put(
      Actions.mainSub.togglePageState({ stateKey, data: AppState.LOADED })
    );
    if (storeKey) {
      yield put(
        Actions.mainSub.postPageDataPersistWithKey({
          stateKey: storeKey,
          data: stateKey,
        })
      );
    }
  } catch (e: any) {
    yield put(
      Actions.mainSub.togglePageState({
        stateKey,
        data: e?.status === 404 ? AppState.ERROR_404 : AppState.ERROR,
      })
    );
    yield put(
      Actions.mainSub.setPageMessage({
        stateKey,
        data: e.response?.data?.message ?? "Something happened",
      })
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield all([
    takeEvery(types.PAGE_ARRAY_DATA, getPageArrayData),
    takeEvery(types.PAGE_OBJECT_DATA, getPageObjectData),
    takeEvery(types.POST_PAGE_DATA, postPageObjectData),
    takeEvery(types.PAGINATED_PAGE_DATA, getPaginatedPageData),
    takeEvery(types.DELETE_PAGE_DATA, deletePageObjectData),
    takeEvery(types.POST_PAGINATED_PAGE_DATA, postPaginatedPageData),

    // takeEvery(types.DELETE_OR_EDIT_PAGE_DATA, deleteOrEditPageObjectData),
  ]);
}
