import { AppState } from "../../services/app.service";

export const StateKeys = {
  STORIES: "stories",
  FEATURED_MENTORS: "featured_mentors",
  REQUESTED_CONNECTIONS: "requested_connections",
  SUGGESTED_CONNECTIONS: "suggested_connections",
  FRIENDS: "friends",
  JOURNAL: "journal",
  PRIVATE_JOURNAL: "private_journal",
  ANNOUNCEMENT: "announcement",
  ANNOUNCEMENTS: "announcements",
  RESOURCES: "resources",
  IMAGE_RESOURCE: "image_resource",
  AUDIO_RESOURCE: "audio_resource",
  VIDEOS_RESOURCE: "videos_resource",
  DOCUMENTS_RESOURCE: "documents_resource",
  LINKS_RESOURCE: "links_resource",
  MENTORS: "mentors",
  PROFILE: "profile",
  MENTOR_PROFILE: "mentor_profile",
  CATEGORIES: "categories",
  STORY: "story",
  GROUP: "group",
  INTEREST: "interest",
  GIRLS: "girls",
  HOME: "home",
  INTERESTS: "interests",
  USER_INTERESTS: "user_interests",
  DATA_PERSIST: "data_persist",
  COURSES: "courses",
  COURSE: "course",
  SUBSCRIPTION_COURSE: "subscription_course",
  COURSE_TOPICS: "course_topics",
  TOPIC: "topic",
  LEARNING_CATEGORIES: "learning_categories",
  CATEGORY_COURSES: "category_courses",
  SEARCH_RESULT: "search_result",
  HELPLINE: "helpline",
  CHAT_MESSAGES: "chat_messages",
  GROUP_CHATS: "group_chats",
  GROUP_CHAT: "group_chat",
  CONVERSATION_MARK_AS_READ: "conversation_read",
  SEND_MESSAGE: "send_message",
  GROUP_PARTICIPANTS: "group_participants",
};

export interface BaseState<T = any, S = any> {
  data: T;
  pageState: AppState;
  message: string;
  currentPage?: number;
  hasMore?: boolean;
  obj?: S;
  postPageState: AppState;
}

export interface Mainn {
  [key: string]: BaseState;
}

const mainSub: Mainn = {
  //object data
  [StateKeys.PROFILE]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.STORY]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.LOADED,
  },

  // array data
  [StateKeys.FEATURED_MENTORS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.INTERESTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.USER_INTERESTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },

  // paginated array data
  [StateKeys.STORIES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.REQUESTED_CONNECTIONS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.SUGGESTED_CONNECTIONS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.FRIENDS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.FEATURED_MENTORS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },

  [StateKeys.ANNOUNCEMENTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.MENTORS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CATEGORIES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.IMAGE_RESOURCE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.AUDIO_RESOURCE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.VIDEOS_RESOURCE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.DOCUMENTS_RESOURCE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.LINKS_RESOURCE]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.JOURNAL]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.PRIVATE_JOURNAL]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.COURSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.COURSE]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.SUBSCRIPTION_COURSE]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.LEARNING_CATEGORIES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CATEGORY_COURSES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.COURSE_TOPICS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.TOPIC]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.SEARCH_RESULT]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.DATA_PERSIST]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.HELPLINE]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.GROUP_CHATS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.GROUP_CHAT]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.GROUP_PARTICIPANTS]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.CHAT_MESSAGES]: {
    data: [],
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
    hasMore: false,
    currentPage: 0,
  },
  [StateKeys.CONVERSATION_MARK_AS_READ]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
  [StateKeys.SEND_MESSAGE]: {
    data: {},
    pageState: AppState.IDLE,
    message: "",
    postPageState: AppState.IDLE,
  },
};

export default mainSub;
