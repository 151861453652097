import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { Link } from "react-router-dom";
import NoData from "./NoData";
import Load from "./Load";
import { UserProfileResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";

const SuggestedFriends = () => {
  const { suggested_connections } = useSelector(
    (state: IRootState) => state.mainSub
  );

  const { data, pageState } = suggested_connections;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">My Suggestions</h4>
        <Link
          to="/suggestions"
          className="fw-600 ms-auto font-xssss text-primary"
        >
          See all
        </Link>
      </div>
      {pageState === AppState.LOADED ? (
        data.length > 0 ? (
          (data as Array<UserProfileResponse>).slice(0, 6).map((item) => (
            <div
              key={item.id}
              className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3"
            >
              <figure className="avatar me-2 mb-0">
                <img
                  src={item.photo_url}
                  alt={item.first_name}
                  className="shadow-sm rounded-circle w45 h45"
                />
              </figure>
              <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                {item.first_name} {item.last_name}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {item.city}, {item.country}
                </span>
              </h4>
              <Link
                to={`/profile/${item.id}`}
                title="View profile"
                className="btn-round-sm bg-white ms-auto mt-2"
              >
                <i className="feather-chevron-right font-xss text-grey-900" />
              </Link>
            </div>
          ))
        ) : (
          <>
            <NoData noCard={true} />
          </>
        )
      ) : (
        <Load message="Loading" />
      )}
    </div>
  );
};

export default SuggestedFriends;
