import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";
import { UserResponseFriendRequest } from "../../services/api.response.service";
import { Link } from "react-router-dom";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { AppState } from "../../services/app.service";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import subMain from "../../redux/actions/mainn.action";

function Connections() {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  //   const { loadedPage, friends, loaded, loader, hasMore, loadMore } =
  //     useSelector((state: IRootState) => state.main.friends);
  const state = useSelector((state: IRootState) => state.mainSub);
  const friends: BaseState<Array<UserResponseFriendRequest>> =
    state[StateKeys.FRIENDS];

  const { data, pageState, hasMore, currentPage } = friends;

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  useEffect(() => {
    // if (loadedPage === 0 && !loaded) {
    //   dispatch(Actions.main.getFriends(loadedPage, friends));
    // }

    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.FRIENDS,
          apiEndpoint: ApiEndpoint.FRIENDS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <div className="mb-0 mt-0 d-flex align-items-center">
                  <h2 className="fw-700 font-md text-grey-900">My Friends</h2>
                  {/*<form className="pt-0 pb-0 ms-auto">
                                <div className="search-form-2 ms-2">
                                    <i className="ti-search font-xss"/>
                                    <input type="text" className="form-control text-grey-900 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                                </div>
                            </form>*/}
                </div>
              </div>

              <div className="row ps-2 pe-1">
                {pageState === AppState.LOADED ||
                pageState === AppState.LOADING_MORE ? (
                  data.length > 0 ? (
                    <>
                      {(data as Array<UserResponseFriendRequest>).map(
                        (item) => (
                          <div
                            key={item.friend_id}
                            className="col-md-6 col-sm-6 pe-2 ps-2"
                          >
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                              <div
                                className="card-body position-relative h100 bg-image-cover bg-image-center"
                                style={{
                                  backgroundImage: `url(${item.user.video_url})`,
                                }}
                              />
                              <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                                <figure
                                  className="avatar position-absolute w75 z-index-1 left-15"
                                  style={{ marginTop: `-40px` }}
                                >
                                  {/*<video autoPlay={true} loop={true} className="float-right w-100">
                                                                <source src={item.user.video_url}/>
                                                            </video>*/}
                                  <img
                                    src={item.user.photo_url}
                                    alt={item.user.first_name}
                                    className="float-right p-1 bg-white rounded-circle w75 h75 "
                                  />
                                </figure>
                                <div className="clearfix" />
                                <h4 className="fw-700 font-xsss mt-3 mb-1">
                                  {item.user.first_name} {item.user.last_name}
                                </h4>
                                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">
                                  {item.user.city}
                                </p>
                                <span className="position-absolute right-15 top-0 d-flex align-items-center">
                                  {item.is_blocked ? (
                                    !showMore ? (
                                      <span
                                        className="p-2 lh-20 w100 bg-danger text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                                        onClick={() => setShowMore(!showMore)}
                                      >
                                        Unblock
                                      </span>
                                    ) : (
                                      <div className="px-3 rounded-xxl border-0 shadow-lg">
                                        <div className="card-body p-0 d-flex flex-column">
                                          {loaderIds.includes(
                                            `unblockUser|${item.user.id}`
                                          ) ? (
                                            <Load
                                              message={`Unblocking user`}
                                              noMarginTop={true}
                                            />
                                          ) : (
                                            <div className="d-flex justify-content-evenly">
                                              <span
                                                className={`pointer d-lg-block p-3 rounded-3 text-warning font-xsssss text-uppercase fw-700 ls-3 bg-transparent`}
                                                onClick={() =>
                                                  setShowMore(!showMore)
                                                }
                                              >
                                                No
                                              </span>
                                              <span
                                                className={`pointer d-lg-block p-3 rounded-3 text-danger font-xsssss text-uppercase fw-700 ls-3 bg-transparent`}
                                                onClick={() =>
                                                  dispatch(
                                                    Actions.main.unBlockUser(
                                                      item.user.id
                                                    )
                                                  )
                                                }
                                              >
                                                Yes
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    <>
                                      <Link
                                        to={`/chat?conversation_id=${item.conversation.channel_name}`}
                                        title="Chat"
                                        className="d-lg-block d-none"
                                      >
                                        <i className="feather-message-circle btn-round-md font-md bg-success text-white" />
                                      </Link>
                                      <Link
                                        to={`/profile/${item.user.id}`}
                                        title="Profile"
                                        className="d-lg-block ms-1 ls-3"
                                      >
                                        <i className="feather-user btn-round-md font-md bg-primary-gradiant text-white" />
                                      </Link>
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      {hasMore && pageState === AppState.LOADED && (
                        <div
                          className="col-lg-12 mt-3 mb-5 text-center"
                          onClick={() => {
                            // dispatch(
                            //   Actions.main.getFriends(loadedPage, friends)
                            // );
                          }}
                        >
                          <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                            Load More
                          </span>
                        </div>
                      )}
                      {pageState === AppState.LOADING_MORE && <Load />}
                    </>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <Load message="Loading" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Connections;
