export const ApiEndpoint = {
  STORIES: "stories",
  FEATURED_MENTORS: "mentors/featured",
  ANNOUNCEMENTS: "announcements",
  REQUESTED_CONNECTIONS: "requests",
  SUGGESTED_CONNECTIONS: "suggestions",
  JOURNAL: (id: string) => `${id}/stories`,
  RESOURCES: "resources",
  FRIENDS: "friends",
  MENTORS: "mentors/connection",
  INTERESTS: "interests",
  USER_INTERESTS: (id: string) => `interests/${id}`,
  PROFILE: (id: string) => `profile/${id}`,
  POST_STORY: "story",
  REACT_TO_STORY: (id: string) => `story/${id}/view`,
  STORY: (id: string) => `stories/${id}`,
  EDIT_STORY: (id: string) => `story/${id}`,
  DELETE_ACCOUNT: "report/delete",
  COURSES: "lms/courses",
  COURSE: (id: string) => `lms/courses/${id}`,
  COURSE_TOPICS: (id: string) => `lms/courses/${id}/topics`,
  TOPIC: (id: string) => `lms/topics/${id}`,
  LEARNING_CATEGORIES: "lms/categories",
  SUBSCRIBE_COURSE: (id: string) => `lms/courses/${id}/subscribe`,
  UNSUBSCRIBE_COURSE: (id: string) => `lms/courses/${id}/subscribe`,
  CATEGORY_COURSES: (category_id: string) =>
    `lms/categories/${category_id}/courses`,
  SEARCH_LEARN: (search_text: string) =>
    `lms/search?search_text=${search_text}`,
  HELPLINE: "helpline",
  CHAT_MESSAGES: (id: string | number, limit?: string | number) =>
    `chat/message/${id}${limit ? "?limit=" + limit : ""}`,
  CONVERSATION_MARK_AS_READ: (id: string | number) =>
    `chat/messages/conversation/${id}/mark-read`,
  GET_GROUPS: (page: number, limit: number) =>
    `/chat/groups?page=${page}&limit=${limit}`,
  GET_GROUP: (group_id: string) => `/chat/groups/${group_id}`,
  GET_GROUP_PARTICIPANTS: (
    conversation_id: number,
    page: number,
    limit?: string | number
  ) => `/chat/${conversation_id}/participants?page=${page}&limit=${limit}`,
};
