import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { Link } from "react-router-dom";
import NoData from "./NoData";
import Load from "./Load";
import { UserResponseFriendRequest } from "../services/api.response.service";
import Actions from "../redux/actions";
import { AppState } from "../services/app.service";

const FriendRequests = () => {
  const dispatch = useDispatch();
  const { requested_connections } = useSelector(
    (state: IRootState) => state.mainSub
  );

  const { data, pageState } = requested_connections;

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  const acceptRequest = (id: string) => {
    if (!loaderIds.includes(id)) {
      dispatch(Actions.main.getHomeFriendRequestsAccept(id));
    }
  };

  const declineRequest = (id: string) => {
    if (!loaderIds.includes(id)) {
      dispatch(Actions.main.getHomeFriendRequestsDecline(id));
    }
  };
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">My Requests</h4>
        <Link
          to="/requested-connections"
          className="fw-600 ms-auto font-xssss text-primary"
        >
          See all
        </Link>
      </div>
      {pageState === AppState.LOADED ? (
        data.length > 0 ? (
          (data as Array<UserResponseFriendRequest>).slice(0, 6).map((item) => (
            <div className="wrap" key={item.user.id}>
              <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                <figure className="avatar me-3">
                  <img
                    src={item.user.photo_url}
                    alt="avatar"
                    className="shadow-sm rounded-circle w45 h45"
                  />
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                  {item.user.first_name} {item.user.last_name}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {item.user.city}, {item.user.country}
                  </span>
                </h4>
              </div>
              <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                {loaderIds.includes(item.friend_id) ? (
                  <Load
                    spin={true}
                    noCard={true}
                    noPadding={true}
                    noMarginTop={true}
                  />
                ) : (
                  <>
                    <span
                      className="p-2 lh-20 w100 bg-grey me-2 text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl"
                      onClick={() => declineRequest(item.friend_id)}
                    >
                      Delete
                    </span>
                    <span
                      className="p-2 lh-20 w100 bg-primary-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                      onClick={() => acceptRequest(item.friend_id)}
                    >
                      Confirm
                    </span>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <>
            <NoData noCard={true} />
          </>
        )
      ) : (
        <Load message="Loading" />
      )}
    </div>
  );
};

export default FriendRequests;
