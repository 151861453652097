import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import {IRootState} from "../../redux/states";
import Actions from "../../redux/actions";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";
import {remoteConfig} from "../../config/firebase";
import {KEYS,getKey} from "../../config/keys";
import { AppState } from "../../services/app.service";

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const recaptchaRef = useRef(null)
    const [token, setToken] = useState('')
    const dispatch = useDispatch()
    const {loader,error,message,state} = useSelector(((state: IRootState) => state.auth.login))

    const [showPassword, setShowPassword] = useState(false)

    const recaptchaSiteKey = remoteConfig.getValue(getKey(KEYS.RECAPTCHA_SITE_KEY)).asString()??""
    const [loadCaptcha, setLoadCaptcha] = useState(-3)
    const [retries, setRetries] = useState(3);

    /*useEffect(() => {
        try {
            if(!loadCaptcha && recaptchaSiteKey.length > 0){
                // @ts-ignore
                loadReCaptcha(recaptchaSiteKey, () => {
                    console.log('captcha ready')
                    setLoadCaptcha(true)
                })
            }
        } catch (e) {
            console.error('captcha', e)
            setLoadCaptcha(false)
        }
    }, [recaptchaSiteKey])*/

    useEffect(() => {
        try {
            loadRC();
        } catch (e) {
            console.error("captcha", e);
            setLoadCaptcha(loadCaptcha + 1);
        }
    }, [recaptchaSiteKey, loadCaptcha]);

    useEffect(() => {
        if(state === AppState.RETRY && retries > 0){
            console.log(state,retries)
            setRetries(prevState => prevState-1)
            dispatch(Actions.auth.login({email, password, token}))
            console.log(state)
        }
    }, [state]);

    const loadRC = () => {
        if (loadCaptcha < 0 && recaptchaSiteKey.length > 0) {
            // @ts-ignore
            loadReCaptcha(recaptchaSiteKey, () => {
                console.log("captcha ready");
                setLoadCaptcha(1);
            });
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        loadRC();
        e.preventDefault();
        dispatch(Actions.auth.login({ email, password, token: token ?? 'yes' }));
    };

    return (<>
                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                    <div className="card-body rounded-0 text-left">
                        <h2 className="fw-700 display1-size display2-md-size mb-3">Login into <br />your account</h2>
                        {loader &&
                        <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
                            Logging in
                            <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                                <div className="stage">
                                    <div className="dot-typing"/>
                                </div>
                            </div>
                        </div>
                        }
                        {error &&
                        <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3 bg-danger opacity-5">
                            <span className="text-white">{message}</span>
                        </div>
                        }
                        <form onSubmit={handleSubmit}>

                            <div className="form-group icon-input mb-3">
                                <i className="font-sm ti-email text-grey-500 pe-0"/>
                                <input type="text" className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${error && 'border-danger'}`} placeholder="Your Email Address"
                                       defaultValue={email} onChange={(e)=> setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-1 icon-right-input">
                                <span className="custom-icon-input">
                                    <i className="font-sm ti-lock text-grey-500 pe-0 custom-icon-input mt-2"/>
                                </span>
                                <input type={showPassword ? 'text':"password"} className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${error && 'border-danger'}`} placeholder="Password"
                                       defaultValue={password} onChange={(e)=> setPassword(e.target.value)}
                                />
                                <i className={`font-sm ti-eye ${showPassword?'text-warning':'text-grey-500'} pe-0 mt-2`} onClick={()=> setShowPassword(!showPassword)}/>

                            </div>
                            <div className="form-check text-left mb-3">
                                {/*<input type="checkbox" className="form-check-input mt-2" id="exampleCheck5" />*/}
                                {/*<label className="form-check-label font-xsss text-grey-500">Remember me</label>*/}
                                <Link to="/forgot-password" className="fw-600 font-xsss text-grey-700 mt-1 float-right">Forgot your Password?</Link>
                            </div>
                            <div className="col-sm-12 p-0 text-left">
                                <div className="form-group mb-1">
                                    <button className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " type="submit"
                                      // onClick={() => dispatch(Actions.auth.login({email, password, token}))}
                                    >Login</button>
                                </div>
                                <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                    Dont have account Register as <Link to="/register" className="fw-700 ms-1">Girl</Link> or <Link to="/register#mentor" className="fw-700 ms-1">Mentor</Link></h6>
                            </div>
                        </form>

                        {/*<div className="col-sm-12 p-0 text-left">
                            <div className="form-group mb-1">
                                <button className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " type="submit"
                                        // onClick={() => dispatch(Actions.auth.login({email, password, token}))}
                                >Login</button>
                            </div>
                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                Dont have account Register as <Link to="/register" className="fw-700 ms-1">Girl</Link> or <Link to="/register#mentor" className="fw-700 ms-1">Mentor</Link></h6>
                        </div>*/}
                        {/*<div className="col-sm-12 p-0 text-center mt-2">

                                <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign in with your social account </h6>
                                <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"><img src="assets/images/icon-1.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> Sign in with Google</a></div>
                                <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0 "><img src="assets/images/icon-3.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> Sign in with Facebook</a></div>
                            </div>*/}
                    </div>
                </div>
            {loadCaptcha &&
                <ReCaptcha ref={recaptchaRef} action="submit"
                           sitekey={recaptchaSiteKey} verifyCallback={(e) => setToken(e)} />}
            </>
    );
}

export default Login;