import React, { useEffect, useState } from "react";
import Groups from "../../components/Chat/Groups";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { GroupResponse } from "../../services/api.response.service";
import { IRootState } from "../../redux/states";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../services/app.service";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import Chats from "../../components/Chat/Chats";
import GroupProfile from "../../components/Chat/GroupProfile";

const GroupChat = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.mainSub);
  const {
    pageState,
    data: groupChats,
    message,
  }: BaseState<Array<GroupResponse>> = state[StateKeys.GROUP_CHATS];
  const [getGroup, setGetGroup] = useState<GroupResponse>();
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [getGroupProfile, setGetGroupProfile] = useState<GroupResponse>();

  useEffect(() => {
    if (pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPageArrayData({
          stateKey: StateKeys.GROUP_CHATS,
          apiEndpoint: ApiEndpoint.GET_GROUPS(1, 10),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section id="GroupChat">
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0"></div>
            <div className="col-xl-12">
              <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
                <div className="mb-0 mt-0 d-flex justify-content-start align-items-center w-100 px-5* p-4">
                  <h2 className="fw-700 font-md text-grey-900">Group Chats</h2>
                </div>
              </div>
              <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
                <div className="groupChats" style={{ height: "100vh" }}>
                  <div className="row pe-5*">
                    <div className="col-3 pe-0">
                      <ul
                        className="list-group list-group-flush border-end"
                        style={{ height: "100vh" }}
                      >
                        {pageState === AppState.LOADED ? (
                          <>
                            {groupChats.map((groupChat: GroupResponse) => (
                              <Groups
                                key={groupChat?.group_id}
                                groupChats={groupChat}
                                setGetGroup={setGetGroup}
                              />
                            ))}
                          </>
                        ) : (
                          <div
                            className="snippet mt-5 ms-auto me-auto"
                            data-title=".dot-typing"
                          >
                            <div className="stage">
                              <div className="dot-typing" />
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>

                    <div
                      className={` ${openGroup ? "col-6" : "col-9"} ps-0 pe-0`}
                    >
                      <Chats getGroup={getGroup!} setOpenGroup={setOpenGroup} />
                    </div>

                    {openGroup && (
                      <div className="col-3 ps-0">
                        <div className="border-start">
                          <GroupProfile
                            group={getGroup!}
                            setOpenGroup={setOpenGroup}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GroupChat;
