import React from "react";

interface props {
  imgURL: string;
  setImgURL: (imgURL: string) => void;
}

const ViewImage = ({ imgURL, setImgURL }: props) => {
  return (
    <>
      {" "}
      <div
        className={`modal fade show ${imgURL && "d-block"}`}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setImgURL(null!)}
              ></button>
            </div>
            <div className="modal-body">
              {" "}
              <img src={imgURL} alt="event" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewImage;
