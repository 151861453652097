import {
  DeleteAccountRequest,
  ForgotPasswordRequest,
  ILoginRequest,
  RegisterRequest,
} from "../../services/api.request.service";

export const types = {
  LOGIN: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_LOADER: "LOGIN_LOADER",
  LOGIN_MESSAGE: "LOGIN_MESSAGE",
  REGISTER: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",
  REGISTER_ERROR_INPUT: "REGISTER_ERROR_INPUT",
  REGISTER_LOADER: "REGISTER_LOADER",
  REGISTER_MESSAGE: "REGISTER_MESSAGE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  FORGOT_PASSWORD_LOADER: "FORGOT_PASSWORD_LOADER",
  FORGOT_PASSWORD_MESSAGE: "FORGOT_PASSWORD_MESSAGE",

  DELETE_ACCOUNT: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_ERROR: "DELETE_ACCOUNT_ERROR",
  DELETE_ACCOUNT_STATE: "DELETE_ACCOUNT_STATE",
  DELETE_ACCOUNT_MESSAGE: "DELETE_ACCOUNT_MESSAGE",
  LOGOUT: "LOGOUT_REQUEST",
};

const Auth = {
  login: (payload: ILoginRequest) => ({ type: types.LOGIN, payload }),
  setLoginLoading: (payload: boolean) => ({
    type: types.LOGIN_LOADER,
    payload,
  }),
  setLoginError: (payload: boolean) => ({ type: types.LOGIN_ERROR, payload }),
  setLoginMessage: (payload: string) => ({
    type: types.LOGIN_MESSAGE,
    payload,
  }),
  register: (payload: RegisterRequest) => ({ type: types.REGISTER, payload }),
  setRegisterLoading: (payload: boolean) => ({
    type: types.REGISTER_LOADER,
    payload,
  }),
  setRegisterError: (payload: boolean) => ({
    type: types.REGISTER_ERROR,
    payload,
  }),
  setRegisterErrorInput: (payload: boolean) => ({
    type: types.REGISTER_ERROR_INPUT,
    payload,
  }),
  setRegisterMessage: (payload: string) => ({
    type: types.REGISTER_MESSAGE,
    payload,
  }),
  forgotPassword: (payload: ForgotPasswordRequest) => ({
    type: types.FORGOT_PASSWORD,
    payload,
  }),
  setForgotPasswordLoading: (payload: boolean) => ({
    type: types.FORGOT_PASSWORD_LOADER,
    payload,
  }),
  setForgotPasswordError: (payload: boolean) => ({
    type: types.FORGOT_PASSWORD_ERROR,
    payload,
  }),
  setForgotPasswordMessage: (payload: string) => ({
    type: types.FORGOT_PASSWORD_MESSAGE,
    payload,
  }),

  deleteAccount: (payload: DeleteAccountRequest) => ({
    type: types.DELETE_ACCOUNT,
    payload,
  }),
  setDeleteAccountState: (payload: number) => ({
    type: types.DELETE_ACCOUNT_STATE,
    payload,
  }),
  setDeleteAccountError: (payload: boolean) => ({
    type: types.DELETE_ACCOUNT_ERROR,
    payload,
  }),
  setDeleteAccountMessage: (payload: string) => ({
    type: types.DELETE_ACCOUNT_MESSAGE,
    payload,
  }),
  logout: () => ({ type: types.LOGOUT }),
};

export default Auth;
