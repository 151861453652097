import React from 'react';

interface props {
    message?: string;
    error?: boolean;
    success?: boolean
    warning?: boolean
    image?: string
    noMarginTop?: boolean
    noCard?: boolean
}

function NoData({message, error, success, image,noMarginTop,warning,noCard}:props){
    return (
        <div className={`${!noCard && 'card'} w-100 text-center shadow-xss rounded-xxl border-0 p-4 
        ${!noMarginTop &&"mt-3"} mb-3 ${!!error && 'text-white bg-danger opacity-5'} ${!!success && 'text-white bg-success opacity-5'} ${!!warning && 'text-white bg-warning opacity-5'}`}>
            {image && <>
                <img src={image} alt="image" className="w-50 mx-auto"/>
                <br/>
            </>}
            <p dangerouslySetInnerHTML={{__html:message ?? 'No data'}}/>
        </div>
    );
}

export default NoData;