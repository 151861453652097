import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import { RouteComponentProps, Redirect } from "react-router";
import NoData from "../../components/NoData";
import Story from "../../components/Story";

function EditStory({ location }: RouteComponentProps) {
  const storyId = location.pathname.replace("/story/view/", "");
  const dispatch = useDispatch();
  const { story, loaded, loader, message, error } = useSelector(
    (state: IRootState) => state.main.story
  );
  const { user } = useSelector((state: IRootState) => state.setting);

  useEffect(() => {
    if (story?.id !== storyId || !loaded) {
      dispatch(Actions.main.getStory(storyId));
    }
  }, [storyId]);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            {!loader ? (
              loaded ? (
                story!.is_public ||
                (!story!.is_public && story!.by.id === user!.id) ? (
                  <Story story={story!} detailed={true} isPublic={true} />
                ) : (
                  <NoData message="This story is a private story and can only be view by the owner" />
                )
              ) : (
                <NoData message={message} />
              )
            ) : (
              <Load message="Loading" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStory;
