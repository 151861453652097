import React from "react";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { CategoryResponse } from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import { useSelector } from "react-redux";
import Load from "../Load";
import NoData from "../NoData";
import { IRootState } from "../../redux/states";
import Category from "./Category";

const WhatToLearn = () => {
  const state = useSelector((state: IRootState) => state.mainSub);
  const LEARNING_CATEGORIES: BaseState<Array<CategoryResponse>> =
    state[StateKeys.LEARNING_CATEGORIES];

  const { data, pageState } = LEARNING_CATEGORIES;

  return (
    <>
      <div className="col-xl-12">
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
          <div className="mb-0 mt-0 d-flex justify-content-between w-100 p-5">
            <h2 className="fw-700 font-md text-grey-900">What To Lean</h2>

            <a href="#">See All </a>
          </div>

          <div className="p-5 pt-0">
            {pageState === AppState.LOADED ||
            pageState === AppState.LOADING_MORE ? (
              data.length > 0 ? (
                <div>
                  <div className="d-flex  align-items-center gap-2">
                    {data.map((category: CategoryResponse) => (
                      <Category category={category} key={category.id} />
                    ))}{" "}
                  </div>
                </div>
              ) : (
                <NoData message="No Category Available" />
              )
            ) : (
              <Load message="Loading" noMarginTop={true} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatToLearn;
