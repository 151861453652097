import React from "react";
import {RouteComponentProps} from "react-router";
import Mobile from "../../pages/mobile";
import logo from "../../img/logo.png";
import {ButtonContainer, ButtonLink, Icon} from "../../pages/mobile/styles";

const Auth:React.FC<RouteComponentProps> = (props) => {
    if(window.innerWidth < 770){
        return <Mobile/>
    }
    return <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
            <div className="nav-top w-100">
                {/*<div>
                    <img src={logo} className="w-5 rounded-3" alt="logo"/>
                    <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">
                        My power
                    </span>
                </div>
                <button className="nav-menu me-0 ms-auto"/>*/}

                {/*<a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>*/}
            </div>
        </div>
        <div className="row">
            <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                 style={{backgroundImage: `url("/assets/img/login-bg.png")`}}/>
            <div className="col-xl-7 vh-100 align-items-center d-flex rounded-3 flex-column justify-content-center">
            {/*<div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 flex-column">*/}
                {props.children}
                {/*<div className="row">*/}
                    <ButtonContainer style={{width: "40%"}}>
                        <ButtonLink href={process.env.REACT_APP_PLAY_STORE_LINK}
                                    target="_blank">
                            <Icon src="/assets/img/playStore.png" style={{width: "109%", marginLeft: 25}}/>
                        </ButtonLink>
                        <ButtonLink href={process.env.REACT_APP_APP_STORE_LINK}
                                    target="_blank">
                            <Icon src="/assets/img/appleStore.png"/>
                        </ButtonLink>
                    </ButtonContainer>
                {/*</div>*/}
            {/*</div>*/}
            </div>
        </div>
    </div>
}

export default Auth;