import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../redux/states";
import Actions from "../redux/actions";
import {GroupResponse, UserResponseFriendRequest} from "../services/api.response.service";
import {Link} from "react-router-dom";

const RightChat = () => {
    const [width, setWidth] = useState(800)
    const [height, setHeight] = useState(182)
    const {showRightChat} = useSelector(((state: IRootState) => state.setting.header))
    const dispatch = useDispatch()

      const updateDimensions = ()=> {
        if(window.innerWidth < 500) {
            setWidth(450)
            setHeight(102)
        } else {
          let update_width  = window.innerWidth-100;
          let update_height = Math.round(update_width/4.4);
            setWidth(update_width)
            setHeight(update_height)
        }
      }

    const {
        loadedPage,
        friends,
        loaded,
    } = useSelector(((state: IRootState) => state.main.friends))

    const {
        loadedPage: groupsLoadedPage,
        groups,
        loaded: groupsLoaded,
    } = useSelector(((state: IRootState) => state.main.groups))

    useEffect(()=>{
        updateDimensions()
        window.addEventListener("resize",updateDimensions);
        return ()=> window.removeEventListener("resize",updateDimensions);
    })

    useEffect(() => {
        if (loadedPage === 0 && !loaded) {
            dispatch(Actions.main.getFriends(loadedPage, friends))
        }
        if (groupsLoadedPage === 0 && !groupsLoaded) {
            dispatch(Actions.main.getGroups(groupsLoadedPage, groups))
        }
    }, []);

    const toggleOpen = () => dispatch(Actions.setting.headerToggleShowRightChat(!showRightChat));

    // const menuClass = () => !showRightChat ? " d-block" : ""
    const menuClass = () => ""

    return (

        <div id="main-content-wrap" className={`right-chat nav-wrap mt-2 right-scroll-bar ${(width > 1500 || showRightChat) && "active-sidebar"}`}>
            <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl">

                {(loaded && friends.length > 0) &&
                <div className="section full pe-3 ps-4 pt-4 position-relative feed-body">
                    <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">FRIENDS</h4>
                    <ul className="list-group list-group-flush">

                        {(friends as Array<UserResponseFriendRequest>).filter(item => !item.is_blocked).map(item => (
                            // Start Single Demo
                            <li  key={item.friend_id} className="bg-transparent list-group-item no-icon border-0 pe-0 ps-0">
                                <Link to={`/profile/${item.user.id}`} className="pe-0 ps-0 pt-2 pb-2 d-flex align-items-center">
                                    <figure className="avatar float-left mb-0 me-2">
                                        <img src={item.user.photo_url} alt="avater" className="p-0-5 bg-white rounded-circle w35 h35"/>
                                    </figure>
                                    <h3 className="fw-700 mb-0 mt-0">
                                        <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer" onClick={toggleOpen}>{item.user.first_name} {item.user.last_name}</span>
                                    </h3>
                                </Link>
                                {/*<span className={`${value.status} ms-auto btn-round-xss`}></span>*/}
                            </li>
                            // End Single Demo
                        ))}

                    </ul>
                </div>}
                {(groupsLoaded && groups.length > 0) &&
                <div className="section full pe-3 ps-4 pt-4 pb-4 position-relative feed-body">
                    <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">GROUPS</h4>
                    <ul className="list-group list-group-flush">
                        {(groups as Array<GroupResponse>).map(item => (
                        <li key={item.group_id} className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">
                            <figure className="avatar float-left mb-0 me-2">
                                <img src={item.image_url} alt={item.title} className="p-0-5 bg-white rounded-circle w35 h35"/>
                            </figure>
                            {/*<span className="btn-round-sm bg-primary-gradiant me-3 ls-3 text-white font-xssss fw-700">UD</span>*/}
                            <h3 className="fw-700 mb-0 mt-0">
                                <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer" onClick={toggleOpen}>{item.title}</span>
                            </h3>
                            {/*<span className="badge mt-0 text-grey-500 badge-pill pe-0 font-xsssss">2 min</span>*/}
                        </li>
                        ))}
                    </ul>
                </div>
                    }
                {/*<div className="section full pe-3 ps-4 pt-0 pb-4 position-relative feed-body">
                    <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">Pages</h4>
                    <ul className="list-group list-group-flush">
                        <li className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">

                            <span className="btn-round-sm bg-primary-gradiant me-3 ls-3 text-white font-xssss fw-700">AB</span>
                            <h3 className="fw-700 mb-0 mt-0">
                                <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer" onClick={toggleOpen}>Armany Seary</span>
                            </h3>
                            <span className="bg-success ms-auto btn-round-xss"></span>
                        </li>
                        <li className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">

                            <span className="btn-round-sm bg-gold-gradiant me-3 ls-3 text-white font-xssss fw-700">SD</span>
                            <h3 className="fw-700 mb-0 mt-0">
                                <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer" onClick={toggleOpen}>Entropio Inc</span>
                            </h3>
                            <span className="bg-success ms-auto btn-round-xss"></span>
                        </li>

                    </ul>
                </div>*/}



            </div>

            <div className={`modal-popup-chat ${menuClass()}`}>
                <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
                    <div className="modal-popup-header w-100 border-bottom">
                        <div className="card p-3 d-block border-0 d-block">
                            <figure className="avatar mb-0 float-left me-2">
                                <img src="assets/images/user-12.png" alt="avater" className="w35 me-1" />
                            </figure>
                            <h5 className="fw-700 text-primary font-xssss mt-1 mb-1">Hendrix Stamp</h5>
                            <h4 className="text-grey-500 font-xsssss mt-0 mb-0"><span className="d-inline-block bg-success btn-round-xss m-0"></span> Available</h4>
                            <div className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer" onClick={toggleOpen}><i className="ti-close text-grey-900 mt-2 d-inline-block"></i></div>
                        </div>
                    </div>
                    <div className="modal-popup-body w-100 p-3 h-auto">
                        <div className="message"><div className="message-content font-xssss lh-24 fw-500">Hi, how can I help you?</div></div>
                        <div className="date-break font-xsssss lh-24 fw-500 text-grey-500 mt-2 mb-2">Mon 10:20am</div>
                        <div className="message self text-right mt-2"><div className="message-content font-xssss lh-24 fw-500">I want those files for you. I want you to send 1 PDF and 1 image file.</div></div>
                        <div className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 bg-grey rounded-xl float-right" data-title=".dot-typing"><div className="stage"><div className="dot-typing"></div></div></div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="modal-popup-footer w-100 border-top">
                        <div className="card p-3 d-block border-0 d-block">
                            <div className="form-group icon-right-input style1-input mb-0"><input type="text" placeholder="Start typing.." className="form-control rounded-xl bg-greylight border-0 font-xssss fw-500 ps-3" /><i className="feather-send text-grey-500 font-md"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RightChat;