import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import routes, {IRoute} from "./app/stores/routes"
import './main.scss';
import {useSelector} from "react-redux";
import {IRootState} from "./app/redux/states";
function App() {
    const {auth,user} = useSelector(((state: IRootState) => state.setting))
  return (
      <Router>
        <Switch>
          {
            // eslint-disable-next-line array-callback-return
            routes.map((route: IRoute, i) => {
              return <Route key={i} exact={route.exact} path={route.path} render={props =>
                route.private ? (
                    auth ?
                      <>
                        {route.girl || route.mentor?
                            <>
                                {(user?.profile && route.girl) ?
                                    <>
                                        {(route.verify && user!.status !== 'Verified') &&
                                            <Redirect to={{pathname: `/profile/${user!.id}`, state: {from: props.location}}}/>
                                        }
                                        <route.layout {...props}>
                                            <route.component {...props}/>
                                        </route.layout>
                                    </>
                                    :
                                    (user?.mentor_profile && route.mentor) ?
                                    <route.layout {...props}>
                                        <route.component {...props}/>
                                    </route.layout>
                                        :
                                        <Redirect to={{pathname: '/not_found', state: {from: props.location, message: 'This page is not accessible'}}}/>
                                }
                            </>
                                :
                          <Redirect to={{pathname: '/not_found', state: {from: props.location,message: 'This page is not accessible'}}}/>
                        }
                      </>
                       :
                      <Redirect to={{pathname: route.redirect, state: {from: props.location}}}/>
                  ):
                  (
                    auth ?
                      <Redirect to={{pathname: route.redirect, state: {from: props.location}}}/> :
                      <route.layout {...props}>
                        <route.component{...props}/>
                      </route.layout>
                  )
              }/>
            })
          }
          <Redirect to={{pathname: '/not_found'}} />
        </Switch>
      </Router>

  );
}

export default App;
