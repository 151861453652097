import Dashboard from "../layouts/Dashboard";
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Home from "../pages/main/Home";
import Settings from "../pages/main/Settings";
import FeaturedMentors from "../pages/main/FeaturedMentors";
import Connections from "../pages/main/Connections";
import RequestedConnections from "../pages/main/RequestedConnections";
import SuggestedConnections from "../pages/main/SuggestedConnections";
import Journal from "../pages/main/Journal";
import Announcements from "../pages/main/Announcements";
import Resources from "../pages/main/Resources";
import Mentors from "../pages/main/Mentors";
import Helpline from "../pages/main/Helpline";
import Messages from "../pages/main/Messages";
import Profile from "../pages/main/Profile";
import MentorProfile from "../pages/main/MentorProfile";
import EditProfile from "../pages/main/EditProfile";
import VerifyProfile from "../pages/main/VerifyProfile";
import EditStory from "../pages/main/EditStory";
import ViewStory from "../pages/main/ViewStory";
import Auth from "../layouts/Auth";
import ReportUser from "../pages/main/ReportUser";
import DeleteProfile from "../pages/main/DeleteProfile";
import GirlsConnections from "../pages/main/GirlsConnections";
import PageNotFound from "../pages/main/PageNotFound";
import EditMentorProfile from "../pages/main/EditMentorProfile";
import VerifyMentorProfile from "../pages/main/VerifyMentorProfile";
import Learn from "../pages/main/Learn";
import Course from "../components/Learn/Course";
import Categories from "../components/Learn/CategoryCourses";
import Topic from "../components/Learn/Topic";
import GroupChat from "../pages/main/GroupChat";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    layout: Auth,
    component: Login,
    exact: false,
    path: "/login",
    redirect: "/",
    private: false,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Auth,
    component: Register,
    exact: false,
    path: "/register",
    redirect: "/",
    private: false,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Auth,
    component: ForgotPassword,
    exact: false,
    path: "/forgot-password",
    redirect: "/",
    private: false,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: Home,
    exact: false,
    path: "/home",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Home,
    exact: true,
    path: "/",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Settings,
    exact: false,
    path: "/settings",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: FeaturedMentors,
    exact: false,
    path: "/featured-mentors",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Connections,
    exact: false,
    path: "/friends",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: GirlsConnections,
    exact: false,
    path: "/connection",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: false,
    verify: false,
  },
  {
    layout: Dashboard,
    component: RequestedConnections,
    exact: false,
    path: "/requests",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: SuggestedConnections,
    exact: false,
    path: "/suggestions",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Journal,
    exact: false,
    path: "/journal",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Announcements,
    exact: false,
    path: "/announcements",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Resources,
    exact: false,
    path: "/resources",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Mentors,
    exact: false,
    path: "/mentors",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Helpline,
    exact: false,
    path: "/helpline",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: GroupChat,
    exact: false,
    path: "/group-chats",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: Profile,
    exact: false,
    path: "/profile/:id",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: ReportUser,
    exact: false,
    path: "/report/user/:id",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: Learn,
    exact: false,
    path: "/learn",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: Course,
    exact: false,
    path: "/lms/courses/:id",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: Categories,
    exact: false,
    path: "/lms/categories/:id/courses",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: Topic,
    exact: false,
    path: "/lms/topic/:id/",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: MentorProfile,
    exact: false,
    path: "/mentor/profile/:id",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: EditMentorProfile,
    exact: false,
    path: "/edit/mentor/profile",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: false,
    verify: false,
  },
  {
    layout: Dashboard,
    component: EditProfile,
    exact: false,
    path: "/edit/profile",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: VerifyMentorProfile,
    exact: false,
    path: "/verify/mentor/account",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: false,
    verify: false,
  },
  {
    layout: Dashboard,
    component: VerifyProfile,
    exact: false,
    path: "/verify/account",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: DeleteProfile,
    exact: false,
    path: "/delete/account",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
  {
    layout: Dashboard,
    component: EditStory,
    exact: false,
    path: "/story/edit/:id",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: ViewStory,
    exact: false,
    path: "/story/view/:id",
    redirect: "/login",
    private: true,
    mentor: false,
    girl: true,
    verify: true,
  },
  {
    layout: Dashboard,
    component: PageNotFound,
    exact: false,
    path: "/not_found",
    redirect: "/login",
    private: true,
    mentor: true,
    girl: true,
    verify: false,
  },
];

export interface IRoute {
  layout: Function;
  component: Function;
  exact: boolean;
  path: string;
  redirect: string;
  private: boolean;
  mentor: boolean;
  girl: boolean;
  verify: boolean;
}
