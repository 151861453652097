import {UserProfileResponse} from "../../services/api.response.service";
import contants from "../../stores/contants";

export interface HeaderState{
    open: boolean
    active: boolean
    notification: boolean
    showRightChat: boolean
}

export interface SettingState{
    auth: boolean
    user: UserProfileResponse | null
    header: HeaderState
    loaderIds: Array<string>
}

const setting: SettingState = {
    auth: !!(localStorage.getItem(contants.USER) && localStorage.getItem(contants.TOKEN)),
    user: {...JSON.parse(localStorage.getItem(contants.USER) as UserProfileResponse | any)},
    header: {
        open: false,
        active: false,
        notification: false,
        showRightChat: false,
    },
    loaderIds: []
}

export default setting