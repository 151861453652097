import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {IRootState} from "../redux/states";
import Actions from "../redux/actions";
import Darkbutton from './Darkbutton';
import logo from "../../img/logo.png";
import Leftnav from "./LeftNav";

const Header = () => {
    const dispatch = useDispatch()
    const {open, active, notification,showRightChat} = useSelector(((state: IRootState) => state.setting.header))
    const {user} = useSelector(((state: IRootState) => state.setting))

    const toggleOpen = () => dispatch(Actions.setting.headerToggleOpen(!open));
    const toggleActive = () => dispatch(Actions.setting.headerToggleActive(!active));
    const toggleNotification = () => dispatch(Actions.setting.headerToggleNotification(!notification));
    const toggleShowRightChat = () => dispatch(Actions.setting.headerToggleShowRightChat(!showRightChat));

    const navClass = () => `${open ? " nav-active" : ""}`;
    const buttonClass = () => `${open ? " active" : ""}`;
    const searchClass = () => `${active ? " show" : ""}`;
    const notificationClass = () => `${notification ? " show" : ""}`;

    const profileVerified = () => (user!.profile && user!.status === 'Verified')
    const mentorProfileVerified = () => user!.mentor_profile

    return (
        <div className={`nav-header bg-white shadow-xs border-0 ${!(mentorProfileVerified() || profileVerified()) && 'd-flex'}`}>
            <div className="nav-top">
                <Link to="/home">
                    <img src={logo} className="w-25 h-25 rounded-3" alt="logo"/>
                    {/*<i className="feather-zap text-success display2-size me-3 ms-0"/>*/}
                    <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">My power</span>
                </Link>
                {(mentorProfileVerified() || profileVerified()) && <>
                <Link to="/defaultmessage" className="mob-menu ms-auto me-2 chat-active-btn"><i
                    className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"/></Link>
                <Link to="/defaultvideo" className="mob-menu me-2"><i
                    className="feather-video text-grey-900 font-sm btn-round-md bg-greylight"/></Link>
                <span onClick={toggleActive} className="me-2 menu-search-icon mob-menu"><i
                    className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"/></span>
                <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}/>
                </>}
            </div>

            {(profileVerified()) && <>
                <form className="float-left header-search ms-3">
                    <div className="form-group mb-0 icon-input">
                        <i className="feather-search font-sm text-grey-400"/>
                        <input type="text" placeholder="Start typing to search.."
                               className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"/>
                    </div>
                </form>
            <NavLink activeClassName="active" to="/home" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i
                className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "/></NavLink>
            <NavLink activeClassName="active" to="/featured-mentors"
                     className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                className="feather-user-check font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "/></NavLink>
            <NavLink activeClassName="active" to="/friends"
                     className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                className="feather-link font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "/></NavLink>
            </>}
            {/*<NavLink activeClassName="active" to="/defaultvideo"
                     className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "/></NavLink>*/}
            {/*<NavLink activeClassName="active" to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "/></NavLink>*/}

            {(mentorProfileVerified() || profileVerified()) && <>
            <span className={`p-2 pointer text-center ms-auto menu-icon ${notificationClass()}`} id="dropdownMenu3"
                  data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleNotification}>
                {/*<span className="dot-count bg-warning"/>
                <i className="feather-bell font-xl text-current"/>*/}
            </span>
            {/*<div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notificationClass()}`}
                 aria-labelledby="dropdownMenu3">
                <h4 className="fw-700 font-xss mb-4">Notification</h4>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span
                        className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span
                        className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>

                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span
                        className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span
                        className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
            </div>*/}
            <span className="p-2 text-center ms-3 menu-icon chat-active-btn" onClick={()=> toggleShowRightChat()}>
                <i className="feather-message-square font-xl text-current"/>
            </span>
                <Darkbutton/>
            </>}
            <Link to={`${mentorProfileVerified() ? '/mentor' : ''}/profile/${user?.id}`} className={`p-0 ms-3 menu-icon ${!(mentorProfileVerified() || profileVerified()) && 'ms-auto'}`}>
                <img src={user?.photo_url} alt="user" className="w40 h40 rounded-circle mt--1"/></Link>

            <nav className={`navigation scroll-bar ${navClass()}`}>
                <Leftnav/>
            </nav>

            {/*<div className={`app-header-search ${searchClass()}`}>
                <form className="search-form">
                    <div className="form-group searchbox mb-0 border-0 p-1">
                        <input type="text" className="form-control border-0" placeholder="Search..."/>
                        <i className="input-icon"></i>
                        <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                            <i className="ti-close font-xs" onClick={toggleActive}/>
                        </span>
                    </div>
                </form>
            </div>*/}

        </div>
    );
}

export default Header;