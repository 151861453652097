import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import Load from "../../components/Load";
import NoData from "../../components/NoData";

import { Link, Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import CreateStory from "../../components/CreateStory";
import Story from "../../components/Story";
// import {
// InterestResponse,
// UserResponseFriendRequest,
// UserProfileResponse,
// StoryResponse,
// } from "../../../store/api.response.type";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import subMain from "../../redux/actions/mainn.action";
import { ApiEndpoint } from "../../services/api.endpoint.service";
import { AppState } from "../../services/app.service";
import {
  InterestResponse,
  UserResponseFriendRequest,
  UserProfileResponse,
  StoryResponse,
} from "../../services/api.response.service";

function Profile({ location }: RouteComponentProps) {
  const profileId = location.pathname.replace("/profile/", "");
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.setting);

  const [showMore, setShowMore] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [userInterestsList, setUserInterestsList] = useState<
    Array<InterestResponse>
  >([]);
  const [userInterestsDeleteList, setUserInterestsDeleteList] = useState<
    Array<InterestResponse>
  >([]);
  const [editInterest, setEditInterest] = useState(false);

  const {
    suggestedFriendsAddRequest,
    friendRequestsLoadedPage,
    friendRequests,
    friendRequestsLoaded,
  } = useSelector((state: IRootState) => state.main.home);

  const state = useSelector((state: IRootState) => state.mainSub);

  const interests: BaseState<Array<InterestResponse>> =
    state[StateKeys.INTERESTS];

  const profile: BaseState<UserProfileResponse> = state[StateKeys.PROFILE];
  const userInterests: BaseState<Array<InterestResponse>> =
    state[StateKeys.USER_INTERESTS];

  const privateJournal: BaseState<Array<StoryResponse>> =
    state[StateKeys.PRIVATE_JOURNAL];
  const publicJournal: BaseState<Array<StoryResponse>> =
    state[StateKeys.JOURNAL];
  const requested_connections: BaseState<Array<UserResponseFriendRequest>> =
    state[StateKeys.JOURNAL];

  const { loaderIds } = useSelector((state: IRootState) => state.setting);

  const isCurrentUser = () => {
    return user?.id === profileId;
  };

  const makeConnection = (id: string) =>
    !(suggestedFriendsAddRequest.includes(id) || loaderIds.includes(id)) &&
    dispatch(Actions.main.postHomeSuggestedFriendsMakeRequest(id));

  const acceptRequest = () => {
    const friend = (friendRequests as Array<UserResponseFriendRequest>).find(
      (e) => e.user.id === profileId
    );
    // @ts-ignore
    if (!loaderIds.includes(friend.friend_id)) {
      // @ts-ignore
      dispatch(Actions.main.getHomeFriendRequestsAccept(friend.friend_id));
    }
  };

  // @ts-ignore
  const declineRequest = () => {
    const friend = (friendRequests as Array<UserResponseFriendRequest>).find(
      (e) => e.user.id === profileId
    );
    // @ts-ignore
    if (!loaderIds.includes(friend.friend_id)) {
      // @ts-ignore
      dispatch(Actions.main.getHomeFriendRequestsDecline(friend.friend_id));
    }
  };

  const loadingRequest = () => {
    const friend = (friendRequests as Array<UserResponseFriendRequest>).find(
      (e) => e.user.id === profileId
    );
    // @ts-ignore
    return friend ? loaderIds.includes(friend.friend_id) : false;
  };

  const profileVerified = () => user!.profile && user!.status === "Verified";

  useEffect(() => {
    if (
      profile.data?.id !== profileId ||
      profile.pageState !== AppState.LOADED
    ) {
      dispatch(
        subMain.getPageObjectData({
          stateKey: StateKeys.PROFILE,
          apiEndpoint: ApiEndpoint.PROFILE(profileId),
        })
      );

      dispatch(
        subMain.getPageArrayData({
          stateKey: StateKeys.USER_INTERESTS,
          apiEndpoint: ApiEndpoint.USER_INTERESTS(profileId),
        })
      );
      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.JOURNAL,
          apiEndpoint: ApiEndpoint.JOURNAL(profileId),
          previousPage: publicJournal.currentPage!,
          params: { is_public: 1 },
        })
      );
      if (isCurrentUser() && privateJournal.pageState !== AppState.LOADED) {
        dispatch(
          subMain.getPaginatedPageData({
            stateKey: StateKeys.PRIVATE_JOURNAL,
            apiEndpoint: ApiEndpoint.JOURNAL(profileId),
            previousPage: privateJournal.currentPage!,
            params: { is_public: 0 },
          })
        );
      }
    }

    // else if (profile.pageState === AppState.LOADED) {
    //   if (isCurrentUser() && privateJournal.pageState !== AppState.LOADED) {
    //     dispatch(
    //       subMain.getPaginatedPageData({
    //         stateKey: StateKeys.PRIVATE_JOURNAL,
    //         apiEndpoint: ApiEndpoint.JOURNAL(profileId),
    //         previousPage: privateJournal.currentPage!,
    //         params: { is_public: 0 },
    //       })
    //     );
    //   }
    //   if (isCurrentUser() && publicJournal.pageState !== AppState.LOADED) {
    //     dispatch(
    //       subMain.getPaginatedPageData({
    //         stateKey: StateKeys.JOURNAL,
    //         apiEndpoint: ApiEndpoint.JOURNAL(profileId),
    //         previousPage: publicJournal.currentPage!,
    //         params: { is_public: 1 },
    //       })
    //     );
    //   }
    // }

    if (interests.pageState !== AppState.LOADED) {
      dispatch(
        subMain.getPageArrayData({
          stateKey: StateKeys.INTERESTS,
          apiEndpoint: ApiEndpoint.INTERESTS,
        })
      );
    }
  }, [profileId]);

  useEffect(() => {
    if (friendRequestsLoadedPage === 0 && !friendRequestsLoaded) {
      //   dispatch(
      //     Actions.main.getHomeFriendRequests(
      //       friendRequestsLoadedPage,
      //       friendRequests
      //     )
      //   );

      dispatch(
        subMain.getPaginatedPageData({
          stateKey: StateKeys.REQUESTED_CONNECTIONS,
          apiEndpoint: ApiEndpoint.REQUESTED_CONNECTIONS,
          previousPage: requested_connections.currentPage!,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (loaderIds.includes("interestsSaved")) {
      setEditInterest(false);
      dispatch(Actions.setting.removeLoaderId("interestsSaved"));
    }
  }, [loaderIds]);

  const handleShowEditInterests = () => {
    setUserInterestsList(userInterests.data);
    setUserInterestsDeleteList([]);
    setEditInterest(true);
  };

  const filterInterestsAdd = (item: InterestResponse) => {
    setUserInterestsList([...userInterestsList, item]);
    setUserInterestsDeleteList([
      ...userInterestsDeleteList.filter((e) => e.name !== item.name),
    ]);
  };
  const filterInterestsRemove = (item: InterestResponse) => {
    setUserInterestsList([
      ...userInterestsList.filter((e) => e.name !== item.name),
    ]);
    setUserInterestsDeleteList([
      ...userInterestsDeleteList,
      ...userInterests.data?.filter(
        (e: InterestResponse) => e.name === item.name
      ),
    ]);
  };

  const handleSaveInterests = () => {
    const add = [
      ...userInterestsList.filter(
        (item) =>
          !userInterests.data?.find(
            (e: InterestResponse) => e.name === item.name
          )
      ),
    ];
    dispatch(
      Actions.main.updateProfileInterest(
        [...add.map((item: InterestResponse) => item.id)],
        [...userInterestsDeleteList.map((item) => item.id)]
      )
    );
  };

  if (!user!.profile) {
    return <Redirect to={{ pathname: `/mentor/profile/${user?.id}` }} />;
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            {
              //   !loader
              profile.pageState === AppState.LOADED ? (
                //   loaded && !error
                profile.pageState === AppState.LOADED ? (
                  <>
                    <div className="col-xl-12 mb-3">
                      <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                        {!!profile.data?.video_url && (
                          <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                            {profile.data?.video_url.split(".").pop() ===
                            "mp4" ? (
                              <video
                                src={profile.data?.video_url}
                                className="w-100 rounded-3"
                                style={{ width: "100%" }}
                                autoPlay={false}
                                controls={false}
                              />
                            ) : (
                              <img
                                src={profile.data?.video_url}
                                className="h250 w-100"
                                alt="background"
                              />
                            )}
                          </div>
                        )}
                        <div className="card-body p-0 position-relative">
                          <figure
                            className="avatar position-absolute w100 z-index-1"
                            style={{ top: "-40px", left: "30px" }}
                          >
                            <img
                              src={profile.data?.photo_url}
                              alt="user"
                              className="float-right p-1 bg-white rounded-circle  w100 h100"
                            />
                          </figure>
                          <h4 className="fw-700 font-sm mt-2 mb-lg-4 mb-3 pl-15">
                            {`${profile.data?.first_name} ${profile.data?.last_name}`}
                            <span className="fw-500 font-xssss text-grey-500 mt-1 d-block">
                              <i className="ti-user me-1" /> {profile.data?.age}
                            </span>
                            <span className="fw-500 font-xssss text-grey-500 mt-1 d-block">
                              <i className="ti-location-pin me-1" />{" "}
                              {`${profile.data?.city} ${profile.data?.country}`}
                            </span>
                            <div className="w-100 mt-2">
                              <div className="d-block pt-4">
                                <h4 className="fw-700 mb-1 font-xsss text-grey-900">
                                  About
                                </h4>
                                <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                                  {profile.data?.about ?? "-"}
                                </p>
                              </div>
                            </div>
                          </h4>
                          <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                            {isCurrentUser() ? (
                              <>
                                {profile.data?.status !== "Verified" &&
                                  profile.data?.status !== "Pending" && (
                                    <Link
                                      to="/verify/account"
                                      className="d-none d-lg-block bg-success p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                    >
                                      Verify Account
                                    </Link>
                                  )}
                                {profile.data?.status === "Pending" && (
                                  <div className="d-none d-lg-block bg-warning p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">
                                    Pending Verification
                                  </div>
                                )}
                                <Link
                                  to="/edit/profile"
                                  className="d-none d-lg-block bg-greylight p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 text-grey-700 ms-2"
                                >
                                  {" "}
                                  Edit Profile
                                </Link>
                                <div
                                  id="dropdownMenu4"
                                  className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={() => setShowMore(!showMore)}
                                >
                                  <i className="ti-more font-md text-dark" />
                                </div>
                                <div
                                  className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg ${
                                    showMore && "show"
                                  }`}
                                  aria-labelledby="dropdownMenu4"
                                >
                                  <Link
                                    to={`/delete/account`}
                                    className="card-body p-0 d-flex"
                                  >
                                    <i className="feather-user-x text-danger me-3 font-lg" />
                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                                      Delete Account
                                      <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-red">
                                        Danger zone
                                      </span>
                                    </h4>
                                  </Link>
                                </div>
                              </>
                            ) : profile.data?.is_connection ? (
                              <>
                                <Link
                                  to={"#"}
                                  className="d-none d-lg-block bg-greylight p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 text-grey-700 ms-2"
                                >
                                  Send Message
                                </Link>
                                <div
                                  id="dropdownMenu4"
                                  className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={() => setShowMore(!showMore)}
                                >
                                  <i className="ti-more font-md tetx-dark" />
                                </div>
                                <div
                                  className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg ${
                                    showMore && "show"
                                  }`}
                                  aria-labelledby="dropdownMenu4"
                                >
                                  <Link
                                    to={`/report/user/${profileId}`}
                                    className="card-body p-0 d-flex"
                                  >
                                    <i className="feather-info text-info me-3 font-lg" />
                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                                      Report User
                                      <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                        For any suspicious reasons
                                      </span>
                                    </h4>
                                  </Link>
                                  <div
                                    className="card-body p-0 d-flex mt-2"
                                    onClick={() => setIsBlock(!isBlock)}
                                  >
                                    <i className="feather-x text-danger me-3 font-lg" />
                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                                      {/*{profile?}*/} Block
                                      <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                        For any suspicious reasons
                                      </span>
                                    </h4>
                                  </div>
                                  {isBlock && (
                                    <div className="card-body p-0 d-flex flex-column mt-2">
                                      {loaderIds.includes(
                                        `blockUser|${profileId}`
                                      ) ? (
                                        <Load
                                          message={`Blocking user`}
                                          noMarginTop={true}
                                        />
                                      ) : (
                                        <>
                                          <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                                            Are you sure?
                                          </h4>
                                          <div className="d-flex justify-content-evenly">
                                            <span
                                              className={`d-none pointer d-lg-block p-3 z-index-1 rounded-3 text-warning font-xsssss text-uppercase fw-700 ls-3 bg-transparent`}
                                              onClick={() =>
                                                setIsBlock(!isBlock)
                                              }
                                            >
                                              No
                                            </span>
                                            <span
                                              className={`d-none pointer d-lg-block p-3 z-index-1 rounded-3 text-danger font-xsssss text-uppercase fw-700 ls-3 bg-transparent`}
                                              onClick={() =>
                                                dispatch(
                                                  Actions.main.blockUser(
                                                    profileId
                                                  )
                                                )
                                              }
                                            >
                                              Yes
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : loaderIds.includes(profileId) ||
                              loadingRequest() ? (
                              <Load
                                spin={true}
                                noCard={true}
                                noPadding={true}
                                noMarginTop={true}
                              />
                            ) : !!(
                                friendRequests as Array<UserResponseFriendRequest>
                              ).find((e) => e.user.id === profileId) ? (
                              <>
                                <span className="position-absolute right-15 top-0 d-flex align-items-center">
                                  <span
                                    className={`d-none d-lg-block p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 bg-danger`}
                                    onClick={declineRequest}
                                  >
                                    Decline
                                  </span>
                                  <span
                                    className={`d-none d-lg-block p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 bg-success`}
                                    onClick={acceptRequest}
                                  >
                                    Accept
                                  </span>
                                </span>
                              </>
                            ) : (
                              <span
                                className={`d-none d-lg-block p-3 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3
                                                                        ${
                                                                          suggestedFriendsAddRequest.includes(
                                                                            profileId
                                                                          )
                                                                            ? "bg-info"
                                                                            : "bg-success"
                                                                        }`}
                                onClick={() => makeConnection(profileId)}
                              >
                                {suggestedFriendsAddRequest.includes(profileId)
                                  ? "Connection Sent"
                                  : "Connect"}
                              </span>
                            )}
                          </div>
                        </div>
                        {isCurrentUser() && (
                          <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                            <ul
                              className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="active list-inline-item me-5">
                                <Link
                                  to={`/profile/${profile.data?.id}#details`}
                                  className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                                    (location.hash === "#details" ||
                                      location.hash === "") &&
                                    "active"
                                  }`}
                                  data-toggle="tab"
                                >
                                  Detail
                                </Link>
                              </li>
                              {profileVerified() && (
                                <li className="active list-inline-item me-5">
                                  <Link
                                    to={`/profile/${profile.data?.id}#private`}
                                    className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                                      location.hash === "#private" && "active"
                                    }`}
                                    data-toggle="tab"
                                  >
                                    Private Journal
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    {location.hash === "#private" &&
                    isCurrentUser() &&
                    profileVerified() ? (
                      <>
                        <div className="col-xl-5 col-xxl-4 col-lg-5 pe-0 pull-right">
                          <CreateStory
                            isPublic={location.hash !== "#private"}
                          />
                        </div>
                        <div className="col-xl-7 col-xxl-8 col-lg-7">
                          {privateJournal.pageState === AppState.LOADED ? (
                            privateJournal.data.length > 0 ? (
                              <>
                                {(
                                  privateJournal.data as Array<StoryResponse>
                                ).map((item) => (
                                  <Story
                                    key={item.id}
                                    story={item}
                                    detailed={false}
                                    postPageState={privateJournal.postPageState}
                                    isPublic={false}
                                  />
                                ))}
                                {privateJournal.hasMore &&
                                  privateJournal.pageState ===
                                    Number(AppState.LOADED) && (
                                    <div
                                      className="col-lg-12 mt-3 mb-5 text-center"
                                      onClick={() =>
                                        dispatch(
                                          subMain.getPaginatedPageData({
                                            stateKey: StateKeys.PRIVATE_JOURNAL,
                                            apiEndpoint: ApiEndpoint.JOURNAL(
                                              user!.id
                                            ),
                                            previousPage:
                                              privateJournal.currentPage!,
                                            params: { is_public: 0 },
                                          })
                                        )
                                      }
                                    >
                                      <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                                        Load More
                                      </span>
                                    </div>
                                  )}
                                {privateJournal.pageState ===
                                  Number(AppState.LOADING_MORE) && (
                                  <Load />
                                )}{" "}
                              </>
                            ) : (
                              <NoData
                                image="/assets/img/private_stories.png"
                                message="A lot on mind? <br/> Share them in your private journal."
                                noMarginTop={true}
                              />
                            )
                          ) : (
                            <Load message="Loading" />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                          <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                            <div className="card-body d-block p-4">
                              <h4 className="fw-700 mb-3 font-xsss text-grey-900">
                                Interests
                              </h4>
                              {userInterests.pageState === AppState.LOADED ? (
                                !editInterest ? (
                                  <>
                                    {userInterests.pageState ===
                                      AppState.LOADED &&
                                      userInterests.data?.length > 0 && (
                                        <ul>
                                          {(
                                            userInterests.data as Array<InterestResponse>
                                          ).map((item) => (
                                            <li
                                              className="badge badge-warning shadow-xss rounded-3 me-1"
                                              key={item.id}
                                            >
                                              {item.name}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    {isCurrentUser() && (
                                      <>
                                        <span
                                          className="d-block ms-1 ls-3"
                                          onClick={handleShowEditInterests}
                                        >
                                          <i
                                            className={`${
                                              interests.pageState ===
                                                AppState.LOADED &&
                                              interests.data.length ===
                                                userInterests.data?.length
                                                ? "feather-minus bg-danger"
                                                : "feather-plus bg-success"
                                            } btn-round-sm font-md text-white`}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {userInterestsList.length > 0 && (
                                      <>
                                        {userInterestsList.length > 0 && (
                                          <small>Click item to Remove</small>
                                        )}
                                        <ul>
                                          {userInterestsList.map((item) => (
                                            <li
                                              className="badge badge-danger shadow-xss rounded-3 me-1"
                                              key={item.id}
                                              title="Click to Remove"
                                              onClick={() =>
                                                filterInterestsRemove(item)
                                              }
                                            >
                                              <i className="feather-minus-circle font-xssss text-white" />{" "}
                                              {item.name}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                    {interests.pageState === AppState.LOADED &&
                                      interests.data.length > 0 && (
                                        <>
                                          {interests.data.length !==
                                            userInterestsList.length && (
                                            <small className="pt-2">
                                              Click item to Add
                                            </small>
                                          )}
                                          <ul>
                                            {(
                                              interests.data as Array<InterestResponse>
                                            )
                                              .filter(
                                                (item) =>
                                                  !userInterestsList.find(
                                                    (e: InterestResponse) =>
                                                      e.name === item.name
                                                  )
                                              )
                                              .map((item) => (
                                                <li
                                                  className="badge badge-pill badge-success shadow-xss rounded-3 me-1"
                                                  key={item.id}
                                                  title="Click to Add"
                                                  onClick={() =>
                                                    filterInterestsAdd(item)
                                                  }
                                                >
                                                  <i className="feather-plus-circle font-xssss text-white" />{" "}
                                                  {item.name}
                                                </li>
                                              ))}
                                          </ul>
                                        </>
                                      )}
                                    {loaderIds.includes(`updateInterests`) ? (
                                      <Load
                                        spin={true}
                                        noCard={true}
                                        noPadding={true}
                                        noMarginTop={true}
                                      />
                                    ) : (
                                      <div className="d-flex mt-3 justify-content-end">
                                        <span
                                          className="bg-primary p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 me-2"
                                          onClick={() => setEditInterest(false)}
                                        >
                                          Close
                                        </span>
                                        <span
                                          className="bg-success p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                          onClick={handleSaveInterests}
                                        >
                                          Save
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )
                              ) : (
                                <Load message="Loading" />
                              )}
                            </div>
                            {/*<div className="card-body border-top-xs d-flex">
                                                    <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                                                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Private <span
                                                        className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">What's up, how are you?</span>
                                                    </h4>
                                                </div>

                                                <div className="card-body d-flex pt-0">
                                                    <i className="feather-eye text-grey-500 me-3 font-lg"></i>
                                                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Visble <span
                                                        className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Anyone can find you</span>
                                                    </h4>
                                                </div>
                                                <div className="card-body d-flex pt-0">
                                                    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
                                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Flodia,
                                                        Austia </h4>
                                                </div>
                                                <div className="card-body d-flex pt-0">
                                                    <i className="feather-users text-grey-500 me-3 font-lg"></i>
                                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Genarel
                                                        Group</h4>
                                                </div>*/}
                          </div>
                          {/*<Profilephoto/>
                                            <Events/>*/}
                        </div>
                        {!profileVerified() ? (
                          <div className="col-xl-8 col-xxl-9 col-lg-8">
                            <NoData
                              warning={true}
                              message="Your account has not been verified, please verify now"
                              noMarginTop={true}
                            />
                          </div>
                        ) : (
                          <div className="col-xl-8 col-xxl-9 col-lg-8">
                            {isCurrentUser() && (
                              <CreateStory
                                isPublic={location.hash !== "#private"}
                              />
                            )}
                            {publicJournal.pageState === AppState.LOADED ? (
                              publicJournal.data.length > 0 ? (
                                <>
                                  {(
                                    publicJournal.data as Array<StoryResponse>
                                  ).map((item) => (
                                    <Story
                                      key={item.id}
                                      story={item}
                                      detailed={false}
                                      postPageState={
                                        publicJournal.postPageState
                                      }
                                      isPublic={true}
                                    />
                                  ))}
                                  {publicJournal.hasMore &&
                                    publicJournal.pageState ===
                                      Number(AppState.LOADED) && (
                                      <div
                                        className="col-lg-12 mt-3 mb-5 text-center"
                                        onClick={() =>
                                          dispatch(
                                            subMain.getPaginatedPageData({
                                              stateKey: StateKeys.JOURNAL,
                                              apiEndpoint: ApiEndpoint.JOURNAL(
                                                user!.id
                                              ),
                                              previousPage:
                                                privateJournal.currentPage!,
                                              params: { is_public: 1 },
                                            })
                                          )
                                        }
                                      >
                                        <span className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">
                                          Load More
                                        </span>
                                      </div>
                                    )}
                                  {publicJournal.pageState ===
                                    Number(AppState.LOADING_MORE) && (
                                    <Load />
                                  )}{" "}
                                </>
                              ) : (
                                <NoData
                                  image="/assets/img/public_stories.png"
                                  message="Share your thoughts with your first public story."
                                  noMarginTop={true}
                                />
                              )
                            ) : (
                              <Load message="Loading" />
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <NoData message={profile.message} />
                )
              ) : (
                <Load message="Loading" />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
