import React from "react";
import Courses from "./Courses";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/mainn.state";
import { CoursesResponse } from "../../services/api.response.service";
import { AppState } from "../../services/app.service";
import { useSelector } from "react-redux";
import Load from "../Load";
import NoData from "../NoData";

const SearchResults = () => {
  const state = useSelector((state: IRootState) => state.mainSub);
  const searchResults: BaseState<Array<CoursesResponse>> =
    state[StateKeys.SEARCH_RESULT];

  const { data, pageState } = searchResults;

  return (
    <>
      {" "}
      <div className="col-xl-12">
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
          <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs*">
            <div className="p-5 ">
              {pageState === AppState.LOADED ||
              pageState === AppState.LOADING_MORE ? (
                data.length > 0 ? (
                  <div className="row">
                    {data.map((course: CoursesResponse) => (
                      <Courses course={course} key={course.id} />
                    ))}{" "}
                  </div>
                ) : (
                  <NoData message="No Courses Available" />
                )
              ) : (
                <Load message="Loading" noMarginTop={true} />
              )}

              {/* <Courses course={data} key={data.id} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
